import axios from "axios";
import create from "zustand";
import {
  addVoucherUrl,
  deleteVoucherUrl,
  getAllVoucherUrl,
  getVoucherDetailsUrl,
  searchVoucherUrl,
  toggleVoucherUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useVoucherStore = create((set) => ({
  voucherLastPageAdd: 0,
  setVoucherLastPageAdd: (value) => set({ voucherLastPageAdd: value }),
 
  voucherDetails: {},
  setVoucherDetails: (value) => set({ voucherDetails: value }),

  voucherList: [],
  setVoucherList: (value) => set({ voucherList: value }),

  voucherListTemp: [],
  setVoucherListTemp: (value) => set({ voucherListTemp: value }),

  voucherListPagination: [],
  setVoucherListPagination: (value) => set({ voucherListPagination: value }),

  addVoucherFormData: {
    title: "",
    type: "",
    user_id: "",
    shop_id: "",
    voucher_code: "",
    start_date: "",
    end_date: "",
    amount: "",
    limit: "",
  },
  setAddVoucherFormData: (e) =>
    set((state) => (state.addVoucherFormData[e.target.name] = e.target.value)),
  resetAddVoucherFormData: () =>
    set(
      (state) =>
      (state.addVoucherFormData = {
        title: "",
        type: "",
        user_id: "",
        shop_id: "",
        voucher_code: "",
        start_date: "",
        end_date: "",
        amount: "",
        limit: "",
      })
    ),

  showEditVoucher: false,
  setShowEditVoucher: (value) => set({ showEditVoucher: value }),

  showVoucherLimit: false,
  setShowVoucherLimit: (value) => set({ showVoucherLimit: value }),

  showAddVoucher: false,
  setShowAddVoucher: (value) => set({ showAddVoucher: value }),

  showDeleteVoucher: false,
  setShowDeleteVoucher: (value) => set({ showDeleteVoucher: value }),

  showDetailsVoucher: false,
  setShowDetailsVoucher: (value) => set({ showDetailsVoucher: value }),

  showDeactivateVoucher: false,
  setShowDeactivateVoucher: (value) => set({ showDeactivateVoucher: value }),

  searchVoucherKey: "",
  setSearchVoucherKey: (value) => set({ searchVoucherKey: value }),

  voucherDeactivateID: {},
  setVoucherDeactivateID: (value) => set({ voucherDeactivateID: value }),
}));

export default useVoucherStore;

/* 
    API Functions
 */

// green: get all voucher list
export const getAllVoucher = async (paginationUrl = "") => {
  try {
    setLoading(true);

    let targetUrl = "";

    if (paginationUrl !== "") {
      // targetUrl = paginationUrl.indexOf("?");
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getAllVoucherUrl + targetUrl;
    } else {
      targetUrl = getAllVoucherUrl;
    }
    console.log("targetUrl:::", targetUrl);

    // const res = await axios.get(getAllVoucherUrl);
    const res = await axios.get(targetUrl, {
      params: {
        take: 10
      }
    });

    console.log("getALlVoucher res.data:::: ", res.data.data);

    if (res.data.success) {
      useVoucherStore.getState().setVoucherList(res.data.data.data);
      useVoucherStore.getState().setVoucherListTemp(res.data.data.data);
      useVoucherStore.getState().setVoucherListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllVoucher: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// blue: add new voucher
export const addNewVoucher = async (paginationUrl="") => {
  console.log(
    "addVoucherFormData::::",
    useVoucherStore.getState().addVoucherFormData
  );
  try {
    setLoading(true);

    const res = await axios.post(
      addVoucherUrl,
      useVoucherStore.getState().addVoucherFormData
    );

    console.log("addNewVoucher::: res.data", res.data);

    if (res.data.success) {
      // useVoucherStore.getState().resetShopFormData();
      useVoucherStore.getState().resetAddVoucherFormData();
      getAllVoucher(paginationUrl);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("addNewVoucher: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("addNewVoucher: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// green: toggle voucher
export const toggleVoucher = async (urlToLoad = "url...") => {
  console.log(
    "voucherDeactivateID::::",
    useVoucherStore.getState().voucherDeactivateID
  );
  try {
    setLoading(true);

    const res = await axios.post(
      toggleVoucherUrl,
      useVoucherStore.getState().voucherDeactivateID
    );

    console.log("toggleVoucher::: res.data", res.data);

    if (res.data.success) {
      getAllVoucher(urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("toggleVoucher: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("toggleVoucher: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// blue: get voucher details
export const getVoucherDetails = async (id) => {
  console.log("getVoucherDetails id::::", id);
  try {
    setLoading(true);

    const res = await axios.post(getVoucherDetailsUrl, {
      id: id,
    });

    console.log("getVoucherDetails::: res.data", res.data);

    if (res.data.success) {
      useVoucherStore.getState().setVoucherDetails(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("getVoucherDetails: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("getVoucherDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// orange: delete voucher
export const deleteVoucher = async (id, paginationUrl) => {
  console.log(id);
  try {
    setLoading(true);

    const res = await axios.post(deleteVoucherUrl, {
      id: id,
    });

    console.log("deleteVoucher::: res.data", res.data);

    if (res.data.success) {
      getAllVoucher(paginationUrl);
      setLoading(false);
      useVoucherStore.getState().setShowDetailsVoucher(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("deleteVoucher: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteVoucher: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// yellow: search voucher list
export const searchVoucherData = async (searchValue,url="") => {
  try {
    setLoading(true);
    console.log("searchValue::", searchValue);
    const res = await axios.post(url===""?searchVoucherUrl:url, {
      take: 10,
      search: searchValue,
    });

    console.log("searchVoucherData::: res.data", res.data);

    if (res.data.success) {
      useVoucherStore.getState().setVoucherList(res.data.data.data);
      useVoucherStore.getState().setVoucherListTemp(res.data.data.data);
      useVoucherStore.getState().setVoucherListPagination(res.data.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchVoucherData: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("searchVoucherData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
