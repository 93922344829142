
import React from 'react'
import useVerifyIdentityStore from '../../../App/Stores/VerifyIdentityStore';

const VerificationHistoryTableItem = ({ data, index }) => {

    const { setShowVerificationDetailsModal } = useVerifyIdentityStore()

    return (
        <>
            <tr onClick={() => { setShowVerificationDetailsModal(true) }}
                className={`border-b cursor-pointer border-collapse border-cNmSelect hover:bg-cBandHover`} >
                <th className='font-normal py-s10 border-r text-center px-s20'>
                    1
                </th>

                <td className='border-r-[1px] py-s10  px-s10 max-w-[100px] min-w-[120px]  text-center'>
                    <div className=' font-fw600 ml-s8 justify-center text-cMainBlack text-fs12 capitalize'>
                        DUMMY DATA
                    </div>
                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody min-w-[150px]'>
                    <span className='font-fw400 text-cMainBlack text-fs12'>12 November 2022</span>
                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody min-w-[150px]'>
                    <div>
                        <div className='font-fw600 text-cMainBlack text-fs14 capitalize'>Admin</div>
                        <span>12 November 2022</span>
                    </div>

                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs12 font-fw400 text-center  text-cTextBody min-w-[100px]'>
                    <span className='font-fw400 text-cMainBlack text-fs14 capitalize'>Reject</span>
                </td>
            </tr>
        </>
    )
}


export default VerificationHistoryTableItem;