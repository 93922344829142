import { useEffect } from "react";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import Image from "../../Components/Image/Image";
import CommonTable from "../../Components/Table/CommonTable";
import { ShopSelected } from "../../Components/Utility/ImageImports";
import Barcode from 'react-barcode';
import InvoiceSticker from "../Order/InvoiceSticker";


const Test = () => {

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle("Test Playground !");
    PageTitle("Test Playground !");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <BackLink linksArray={[
        { label: "one", linkTo: "/test/one" },
        { label: "two", linkTo: "/test/two" },
        { label: "three", linkTo: "/test/three" },
        { label: "four", linkTo: "/test/four" }
      ]} /> */}

      <div className="w-full bg-gray-700 text-cTextBlack min-h-[70vh] rounded-br10 pb-10">
        <div className="text-4xl font-thin text-center text-cyan-300 py-s10">Test Page</div>
        <div className="w-full bg-cyan-300 h-s2" ></div>

        <div className="py-5"></div>
        <InvoiceSticker />

      </div>
    </>
  );
};

export default Test;

function InvoiceReceipt({
  invoice_id = "1651162115615",
  order_id = "1651162115615",
  purchase_id = "1651616111651",

  date = "20 November 2022, 17:30",
  shop_image = "123",
  shop_name = "Amazing Shop",
  phone = "123654987",
  address = "99, somewhere in, Japan",

  from_name = "Cervelo",
  from_email = "crv@email.com",
  from_address = "105, can not find, ME",
  from_phone = "9955447788",

  to_name = "Trek",
  to_email = "trk@email.com",
  to_address = "25/A, hidden place not to, FIND",
  to_phone = "1122334455",

}) {
  return (
    <div className="p-5 bg-white">

      {/*l title */}
      <div className="mb-5 text-2xl font-semibold text-center">Invoice Details: ID {invoice_id}</div>

      {/*e top bar */}
      <div className="flex justify-between items-center text-fs12">
        <div>Gifty Purchase Summary: {purchase_id}</div>
        <div>{date}</div>
      </div>

      {/* e   bar top */}
      <div className="mt-5 w-full h-[35px] bg-cInvoiceBar text-fs16 font-semibold flex items-center pl-s10">
        Seller Center
      </div>

      {/*e shop name and details */}
      <div className="flex items-center mt-5">
        <div className="overflow-hidden rounded-full bg-cInvoiceBar w-s80 h-s80">
          <Image
            src={shop_image}
            dummyImage={ShopSelected}
            className="object-cover w-full h-full p-s15"
          />
        </div>

        <div className="flex flex-col pl-5 space-y-s5">
          <div className="font-semibold text-fs14">{shop_name}</div>
          <div className="font-light text-fs12">Tel: {phone}</div>
          <div className="font-light text-fs12">Address: {address}</div>
        </div>
      </div>

      <div className="w-full bg-cInvoiceBar h-s2 my-s15"></div>

      {/*e  from ... to */}
      <div className="flex justify-between">
        <div className="w-full">
          <div className="font-semibold text-fs14">From:</div>
          <div className="text-fs12">{from_name}</div>
          <div className="text-fs12">{from_email}</div>
          <div className="text-fs12">{from_address}</div>
          <div className="text-fs12">{from_phone}</div>
        </div>

        <div className="w-full">
          <div className="font-semibold text-fs14">To:</div>
          <div className="text-fs12">{to_name}</div>
          <div className="text-fs12">{to_email}</div>
          <div className="text-fs12">{to_address}</div>
          <div className="text-fs12">{to_phone}</div>
        </div>
      </div>

      <div className="w-full bg-cInvoiceBar h-s2 my-s15"></div>

      {/*e   ordered items ... table */}
      <div className="flex justify-between">
        <div className="font-semibold text-fs16">Ordered Items</div>

        <div className="font-semibold text-fs16">Order ID: {order_id}</div>
      </div>
      <CommonTable
        headers={[
          { index: 1, name: "#" },
          { index: 2, name: "Product Name" },
          { index: 3, name: "Shop Name" },
          { index: 4, name: "Size" },
          { index: 5, name: "Qty" },
          { index: 6, name: "Price" },
          { index: 7, name: "Total Price" },
        ]}
        showPagination={false}
        shoSearchBox={false}
        pagination={false}
        showPageCountText={false}
        outerPadding="p-0"
        items={
          <>
            <InvoiceReceiptRow />
          </>
        }
      />

      <div className="flex flex-row-reverse mt-5 text-fs14">
        <div className="border border-collapse">

          <div className="flex max-w-[300px] text-right border-b text-cTextSecondaryBody">
            <div className="px-s10 py-s5 w-[220px] border-r">Sub Total</div>
            <div className="px-s10 py-s5 w-[220px]">¥ 535</div>
          </div>

          <div className="flex max-w-[300px] text-right border-b text-cTextSecondaryBody">
            <div className="px-s10 py-s5 w-[220px] border-r">Shipping Cost</div>
            <div className="px-s10 py-s5 w-[220px]">¥ 45</div>
          </div>

          <div className="flex max-w-[300px] text-right ">
            <div className="px-s10 py-s5 w-[220px] border-r">Total</div>
            <div className="px-s10 py-s5 w-[220px]">¥ 535</div>
          </div>

        </div>
      </div>

    </div>
  )
}

function InvoiceReceiptRow() {
  return (
    <tr className="text-cTextSecondaryBody text-fs14">
      <td className="text-center border">1</td>
      <td className="text-center border">Gift Card new version</td>
      <td className="text-center border">Gifty Shop</td>
      <td className="text-center border">2 x 4</td>
      <td className="text-center border">05</td>
      <td className="text-center border">¥ 107</td>
      <td className="text-center border">¥ 535</td>
    </tr>
  )
}
