import React, { useState } from "react";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import useShopStore, { updateShop } from "../../../App/Stores/ShopStore";
import ImageUpload from "../../../Components/Input/ImageUpload";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import { useTranslation } from "react-i18next";

const EditShop = ({ shopID }) => {
  const { t } = useTranslation();

  const {
    showEditModal,
    setShowEditModal,
    shopDetails,
    shopUpdateFormData,
    setShopUpdateFormData,
    selectedShopID
  } = useShopStore();

  const [updateShopData, setUpdateShopData] = useState({ user_id: shopUpdateFormData.user_id });
  const [passMatch, setPassMatch] = useState(false);

  const HandleSubmit = () => {

    if (shopUpdateFormData.password && shopUpdateFormData.password !== shopUpdateFormData.password_confirmation) {
      setPassMatch(true);
      return Toastr({ message: t("Password and Confirm Password should be Same!"), type: "error" });
    }

    if (
      shopUpdateFormData?.name &&
      shopUpdateFormData?.email &&
      shopUpdateFormData?.phone &&
      selectedShopID &&
      shopUpdateFormData?.address
    ) {
      // console.log("OK!!");
      setUpdateShopData({
        ...updateShopData,
        "user_id": selectedShopID,
      });
      updateShop(updateShopData);
      setShowEditModal(false);
    }
  };

  console.log("selectedShopID", selectedShopID);

  return (
    <>
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        widthClass="w-w40vw"
        modalTitle={t("Edit Shop")}
        mainContent={
          <form onSubmit={(e) => e.preventDefault()} className="pt-5 bg-white">
            <div className="flex justify-center items-center pb-[25px]">
              {/* image */}
              <div className="w-full">
                <ImageUpload
                  btnLabel={t("change image")}
                  imgValue={shopDetails?.shop?.image_url}
                  CustomStyles={"mx-s30"}
                  onChangeFile={(e) => {
                    // console.log("image", e);
                    setUpdateShopData({
                      ...updateShopData,
                      "image": e,
                    });
                  }}
                />
              </div>
            </div>

            {/* green: inputs */}
            <div>
              <div className="flex items-center">
                <div className="w-full">
                  <CommonInput
                    label={t("Name")}
                    name={"name"}
                    placeholder={t("Enter Name")}
                    required={true}
                    value={
                      shopUpdateFormData?.name ? shopUpdateFormData?.name : ""
                    }
                    onChange={(e) => {
                      setShopUpdateFormData({
                        name: "name",
                        value: e.target.value,
                      });

                      setUpdateShopData({
                        ...updateShopData,
                        "name": e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-full">
                  <CommonInput
                    label={t("Email")}
                    name={"email"}
                    type="email"
                    required={true}
                    placeholder={t("Enter email address")}
                    value={
                      shopUpdateFormData?.email ? shopUpdateFormData?.email : ""
                    }
                    onChange={(e) => {
                      setShopUpdateFormData({
                        name: "email",
                        value: e.target.value,
                      });
                      setUpdateShopData({
                        ...updateShopData,
                        "email": e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="p-s10"></div>

                <div className="w-full">
                  <CommonInput
                    label={t("Phone")}
                    name={"phone"}
                    required={true}
                    value={
                      shopUpdateFormData?.phone ? shopUpdateFormData?.phone : ""
                    }
                    onChange={(e) => {
                      setShopUpdateFormData({
                        name: "phone",
                        value: e.target.value,
                      });
                      setUpdateShopData({
                        ...updateShopData,
                        "phone": e.target.value,
                      });
                    }}
                    placeholder={t("Enter phone number")}
                    type="tel"
                  />
                </div>
              </div>



              <div className="flex items-center">
                <div className="w-full">
                  <CommonInput
                    togglePasswordBtn={true}
                    warningBorder={passMatch}
                    min_input={6}
                    name="password"
                    value={shopUpdateFormData?.password}                    
                    onChange={(e) => {
                      setShopUpdateFormData({
                        name: "password",
                        value: e.target.value,
                      });
                      setUpdateShopData({
                        ...updateShopData,
                        "password": e.target.value,
                      });
                    }}
                    label={t("Password")}
                    placeholder={t("Enter New Password")}
                    type="password"
                  />
                </div>

                <div className="p-s10"></div>

                <div className="w-full">
                  <CommonInput
                    togglePasswordBtn={true}
                    warningBorder={passMatch}
                    min_input={6}
                    name="password_confirmation"
                    value={shopUpdateFormData?.password_confirmation}                
                    onChange={(e) => {
                      setShopUpdateFormData({
                        name: "password_confirmation",
                        value: e.target.value,
                      });
                      setUpdateShopData({
                        ...updateShopData,
                        "password_confirmation": e.target.value,
                      });
                    }}
                    label={t("Confirm Password")}
                    placeholder={t("Confirm New Password")}
                    type="password"
                  />
                </div>
              </div>


              <CommonInput
                label={t("Address")}
                name={"address"}
                required={true}
                placeholder={t("Enter Shop Address")}
                value={
                  shopUpdateFormData?.address ? shopUpdateFormData?.address : ""
                }
                onChange={(e) => {
                  setShopUpdateFormData({
                    name: "address",
                    value: e.target.value,
                  });
                  setUpdateShopData({
                    ...updateShopData,
                    "address": e.target.value,
                  });
                }}
              />
              
              <CommonInput
                label={t("details")}
                name={"details"}
                textarea={true}
                placeholder={t("Write in details about this Shop")}
                value={
                  shopUpdateFormData?.details ? shopUpdateFormData?.details : ""
                }
                onChange={(e) => {
                  setShopUpdateFormData({
                    name: "details",
                    value: e.target.value,
                  });
                  setUpdateShopData({
                    ...updateShopData,
                    "details": e.target.value,
                  });
                }}
              />
            </div>
            <div className="flex justify-center">
              <CommonButton
                type="submit"
                btnLabel={t("Save")}
                colorType="primary"
                onClick={() => {
                  HandleSubmit();
                }}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default EditShop;
