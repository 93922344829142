import { useEffect } from "react";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import { Outlet } from "react-router-dom";
import useCustomerStore, {
  getAllCustomer,
} from "../../App/Stores/CustomerStore";
import DeactivateCustomer from "./Popup/DeactivateCustomer";
import { useTranslation } from "react-i18next";

const Customers = () => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { customerListPagination} = useCustomerStore();

  useEffect(() => {
    
      setBarTitle(t("Customers"));
      PageTitle(t("Customers"));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerListPagination.current_page) {
      let urlToLoad = customerListPagination.path + "?page=" + customerListPagination.current_page;
      // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
      getAllCustomer(urlToLoad);
    } else {
      getAllCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("customerListPagination", customerListPagination);
    // console.log("shopListPagination:::", shopListPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflow-y-auto">
      {/* green: outputs from nested route*/}
      <Outlet />
      <DeactivateCustomer />
    </div>
  );
};

export default Customers;
