import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCustomerStore, { getAllCustomer } from "../../App/Stores/CustomerStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useShopStore, { getAllShop } from "../../App/Stores/ShopStore";
import useVoucherStore, { addNewVoucher } from "../../App/Stores/VoucherStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import ComboBox from "../../Components/Input/ComboBox";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import BackLink from "../../Components/Pagination/BackLink";

export default function AddVoucher() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { shopListAll } = useShopStore();
  const { customerListAll } = useCustomerStore();
  const { addVoucherFormData, setAddVoucherFormData, voucherListPagination, setVoucherLastPageAdd } = useVoucherStore();

  const [tempDataArray, setTempDataArray] = useState([]);
  const [tempCustomerArray, setTempCustomerArray] = useState([]);
  const [onState, setOnState] = useState(false);

  const navigateTo = useNavigate();

  const HandleSubmit = async () => {
    // console.log("HandleSubmit is starting...!!");
    // console.log("~ NON-VALIDATED ~ addVoucherFormData:::", addVoucherFormData);
    if (!addVoucherFormData?.type) {
      setOnState(true);
    } else {
      setOnState(false);
    }
    if (
      addVoucherFormData?.title &&
      addVoucherFormData?.type &&
      addVoucherFormData?.voucher_code &&
      addVoucherFormData?.start_date &&
      addVoucherFormData?.end_date &&
      addVoucherFormData?.amount &&
      addVoucherFormData?.limit
    ) {
      // console.log("All Form Data OK!!");
      // console.log("addVoucherFormData:::", addVoucherFormData);
      let loadUrl =
        voucherListPagination.path +
        "?page=" +
        voucherListPagination.last_page;

      setVoucherLastPageAdd(loadUrl);

      let tempSuccess = await addNewVoucher(loadUrl);
      if (tempSuccess) {
        navigateTo("/voucher");
      }
    } else {
      // console.log("HandleSubmit: Failed !");
    }
  };

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Add Voucher"));
    PageTitle(t("Add Voucher"));

    getAllShop("", 500);
    getAllCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("shopListAll::", shopListAll);
    // console.log("customerListAll::", customerListAll);
    let temp = [];
    shopListAll.map((item) =>
      temp.push({ id: item?.user_id, name: item?.user_data?.name })
    );
    // console.log("temp:::", temp);

    let tempCustomer = [];
    customerListAll.map((item) =>
      tempCustomer.push({ id: item?.id, name: item?.name })
    );
    // console.log("tempCustomer:::", tempCustomer);

    setTempCustomerArray(tempCustomer);
    setTempDataArray(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopListAll, customerListAll]);

  return (
    <>
      <BackLink linksArray={[
        { label: t("Voucher"), linkTo: "/voucher" },
        { label: t("Add Voucher"), linkTo: "/voucher/add-voucher" },
      ]} />
      <form onSubmit={(e) => e.preventDefault()} className="bg-white p-5">
        <CommonInput
          className="mb-0"
          label={t("title")}
          name="title"
          required={true}
          value={addVoucherFormData?.title}
          onChange={setAddVoucherFormData}
          placeholder={t("Enter Voucher title")}
        />

        <div className="flex items-center pt-5">
          <div className="w-full">
            <CommonLabel required={true} labelText={t("Voucher type")} />
            <div className="pt-s16"></div>
            <CommonDropDown
              toggleTitle={t("select Voucher type")}
              data={["global", "customer", "shop"]} //"type" => "required|in:global,customer,shop",
              itemOnClick={(e) => {
                let tmp = {
                  target: {
                    name: "type",
                    value: e,
                  },
                };
                console.log(tmp);
                setAddVoucherFormData(tmp);
                console.log(tmp.target.name);
                console.log(tmp.target.value);
              }}
              name="type"
              warningBorder={onState}
              value={addVoucherFormData?.type}
              placeholderTextShow={true}
              basicColor={true}
              fullWidth={true}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonLabel labelText={t("select shop")} required={addVoucherFormData?.type === "shop" ? true : false} />
            <div className="pt-s16"></div>
            <ComboBox
              noDataMessage={t("No shop available to select.")}
              required={addVoucherFormData?.type === "shop" ? true : false}
              placeholder={t("Start typing Shop name")}
              data={tempDataArray ?? tempDataArray}
              onSelectItem={(e) => {
                console.log(e);
                let tmp = {
                  target: {
                    name: "user_id",
                    value: e.id,
                  },
                };
                if (addVoucherFormData?.type === "shop") {
                  setAddVoucherFormData(tmp);
                }
              }}
            />
          </div>
        </div>

        <div className="flex items-center py-5">
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("voucher code")}
              name="voucher_code"
              max_input={30}
              min_input={2}
              required={true}
              value={addVoucherFormData?.voucher_code}
              onChange={setAddVoucherFormData}
              placeholder={t("Enter Voucher code to be set")}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("voucher limit")}
              required={true}
              type="number"
              min_number={1}
              name="limit"
              value={addVoucherFormData?.limit}
              onChange={setAddVoucherFormData}
              placeholder={t("Set Usage Limit of a Voucher for same user")}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Start Date")}
              required={true}
              name="start_date"
              type="date"
              value={addVoucherFormData?.start_date}
              onChange={(e) => {
                if (e.target.value > addVoucherFormData?.end_date) {
                  let tmp = {
                    target: {
                      name: "end_date",
                      value: "",
                    },
                  };
                  setAddVoucherFormData(tmp);
                }
                let startDate = {
                  target: {
                    name: "start_date",
                    value: e.target.value,
                  },
                };
                setAddVoucherFormData(startDate);
              }}
              placeholder={t("Select start date")}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Expire date")}
              required={true}
              disabled={addVoucherFormData?.start_date ? false : true}
              startDate={addVoucherFormData?.start_date}
              name="end_date"
              type="date"
              value={addVoucherFormData?.end_date}
              onChange={setAddVoucherFormData}
              placeholder={t("Select expiry date")}
            />
          </div>
        </div>

        <div className="flex items-center py-5">
          <div className="w-full">
            <CommonLabel labelText={t("select user")} required={addVoucherFormData?.type === "customer" ? true : false} />
            <div className="pt-s16"></div>
            <ComboBox
              noDataMessage={t("No customer available to select.")}
              required={addVoucherFormData?.type === "customer" ? true : false}
              placeholder={t("Start typing Customer name")}
              data={tempCustomerArray ?? tempCustomerArray}
              onSelectItem={(e) => {
                console.log(e);
                let tmp = {
                  target: {
                    name: "user_id",
                    value: e.id,
                  },
                };
                if (addVoucherFormData?.type === "customer") {
                  setAddVoucherFormData(tmp);
                }
              }}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("discount amount")}
              min_number={1}
              name="amount"
              type="number"
              required={true}
              value={addVoucherFormData?.amount}
              onChange={setAddVoucherFormData}
              placeholder={t("Set discount amount")}
            />
          </div>
        </div>

        <div className="pt-10 flex justify-center">
          <CommonButton onClick={() => HandleSubmit()} type="submit" btnLabel={t("add voucher")} width="w-[180px]" />
        </div>
      </form>
    </>
  );
}
