import axios from "axios";
import create from "zustand";
import {
  addGiftCardUrl,
  deleteGiftCardUrl,
  getAllGiftCardsUrl,
  getAllOrdersUrl,
  getGiftCardsDetailsUrl,
  getGiftCardsOrderUrl,
  updateGiftCardUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import { getAllCategory } from "./CategoryStore";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useGiftCardStore = create((set, get) => ({
  giftCardAddForm: {
    shop_id: "",
    category_id: "",
    name: "",
    price: "",
    image: ""
  },
  setGiftCardAddForm: (e) =>
    set((state) => (state.giftCardAddForm[e.target.name] = e.target.value)),
  resetGiftCardAddForm: () =>
    set(
      (state) =>
      (state.giftCardAddForm = {
        shop_id: "",
        category_id: "",
        name: "",
        price: "",
        image: ""
      })
    ),

  giftCardUpdateForm: {
    id: "",
    shop_id: "",
    category_id: "",
    name: "",
    code: "",
    price: "",
    image: "",
  },
  setGiftCardUpdateForm: (e) =>
    set(
      (state) => (state.giftCardUpdateForm[e?.target?.name] = e?.target?.value)
    ),
  resetGiftCardUpdateForm: () =>
    set(
      (state) =>
      (state.giftCardUpdateForm = {
        shop_id: "",
        category_id: "",
        name: "",
        code: "",
        price: "",
        image: "",
      })
    ),



  finalUpdateCardData: {},
  setFinalUpdateCardData: (value) => set({ finalUpdateCardData: value }),

  paginationLinks: [],
  setPaginationLinks: (value) => set({ paginationLinks: value }),


  gCategoryList: [],
  setGCategoryList: (value) => set({ gCategoryList: value }),


  currentAddCardForm: 0,
  setCurrentAddCardForm: (value) => set({ currentAddCardForm: value }),

  giftCardArray: [
    {
      shop_id: 0,
      category_id: 0,
      name: "",
      price: "",
      image: "",
    }
  ],
  setDataToForm: (name, value, index) =>
    set(
      (state) => {
        state.giftCardArray[index][name] = value;
        console.log("FORM VALUE UPDATE::: ", state.giftCardArray[index]);
      }
    ),
  addFormToGiftCardArray: (value) => set(
    (state) => { console.log("BEFORE OK", state.giftCardArray); state.giftCardArray.push(value); console.log("OK", state.giftCardArray); }
  ),
  resetGiftCardArray: () => set({ giftCardArray: [] }),

  giftCardDetails: {},
  setGiftCardDetails: (value) => set({ giftCardDetails: value }),

  giftCardOrderList: {},
  setGiftCardOrderList: (value) => set({ giftCardOrderList: value }),

  giftCardOrderPagination: {},
  setGiftCardOrderPagination: (value) =>
    set({ giftCardOrderPagination: value }),

  giftCardListAll: {},
  setGiftCardListAll: (value) => set({ giftCardListAll: value }),

  giftCardListAllTemp: {},
  setGiftCardListAllTemp: (value) => set({ giftCardListAllTemp: value }),

  giftCardListPagination: {},
  setGiftCardListPagination: (value) => set({ giftCardListPagination: value }),

  giftCardDelBody: {},
  setGiftCardDelBody: (value) => set({ giftCardDelBody: value }),

  giftCardFilterCategory: "",
  setGiftCardFilterCategory: (value) => set({ giftCardFilterCategory: value }),

  giftCardFilter: false,
  setGiftCardFilter: (value) => set({ giftCardFilter: value }),

  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  addingNewGiftCard: false,
  setAddingNewGiftCard: (value) => set({ addingNewGiftCard: value }),

  searchGiftCardsKey: "",
  setSearchGiftCardsKey: (value) => set({ searchGiftCardsKey: value }),

  selectedCardID: "",
  setSelectedCardID: (value) => set({ selectedCardID: value }),

  selectedFilterOrder: "",
  setSelectedFilterOrder: (value) => set({ selectedFilterOrder: value }),

  selectedCategoryIDs: "",
  setSelectedCategoryIDs: (value) => set({ selectedCategoryIDs: value }),
}));

export default useGiftCardStore;

/* 
    API Functions
 */

// green: get all gift card list
export const getAllGiftCard = async (categoriesIDs, paginationUrl = "") => {
  try {
    console.log(categoriesIDs);
    setLoading(true);
    let res;
    let targetUrl = "";

    if (paginationUrl !== "") {
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getAllGiftCardsUrl + targetUrl;
      console.log("targetUrl:::", targetUrl);
    } else {
      targetUrl = getAllGiftCardsUrl;
    }

    console.log("PARAMS ::: ",
     { params: {
      take: 15,
      categories: [categoriesIDs]
    }}
    );

    if (categoriesIDs) {
      res = await axios.get(targetUrl, {
        params: {
          take: 15,
          categories: [categoriesIDs]
        },
      });
    } else {
      res = await axios.get(targetUrl, {
        params: { take: 15 },
      });
    }

    console.log("getAllGiftCard res.data:::: ", res.data.data);    

    if (res.data.success) {
      // await getAllCategory();
      useGiftCardStore.getState().setPaginationLinks(res.data.data.links);
      useGiftCardStore.getState().setGiftCardListAll(res.data.data.data);
      useGiftCardStore.getState().setGiftCardListAllTemp(res.data.data.data);
      useGiftCardStore.getState().setGiftCardListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllGiftCard: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// blue: get gift card details
export const getGiftCardsDetails = async (id) => {
  try {
    if (!id) {
      id = localStorage.getItem("cardID");
    }
    setLoading(true);
    let res;

    res = await axios.get(getGiftCardsDetailsUrl, {
      params: { card_id: id },
    });

    console.log("getGiftCardsDetails res.data:::: ", res.data.data);

    if (res.data.success) {
      useGiftCardStore.getState().setGiftCardDetails(res.data.data);

      useGiftCardStore.getState().setGiftCardUpdateForm({
        name: "user_id",
        value: id,
      });

      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getGiftCardsDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// green: get card-orders list
export const getCardOrderList = async (
  filterStatus = "all orders",
  paginationUrl = "",
  cardID = "",
) => {
  try {
    if (!cardID) {
      cardID = localStorage.getItem("cardID");
    }
    console.log("filterStatus:::", filterStatus);
    console.log("paginationUrl:::", paginationUrl);
    console.log("cardID:::", cardID);

    setLoading(true);

    let targetUrl = "";

    if (paginationUrl !== "") {
      // targetUrl = paginationUrl.indexOf("?");
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getGiftCardsOrderUrl + targetUrl;
      console.log("targetUrl:::", targetUrl);
    } else {
      targetUrl = getGiftCardsOrderUrl;
    }

    console.log("targetUrl:::", targetUrl);

    let res = {};

    if (filterStatus && filterStatus !== "all orders") {
      if (cardID) {
        res = await axios.post(targetUrl, {
          take: 5,
          status: filterStatus,
          card_id: cardID,
        });
      } else {
        res = await axios.post(targetUrl, {
          take: 5,
          status: filterStatus,
        });
      }
    } else {
      if (cardID) {
        res = await axios.post(targetUrl, {
          take: 5,
          card_id: cardID,
        });
      } else {
        res = await axios.post(targetUrl, { take: 5 });
      }
    }

    console.log("getCardOrderList res.data:::: ", res.data.data);

    if (res.data.success) {
      useGiftCardStore.getState().setGiftCardOrderList(res.data.data.data);
      useGiftCardStore.getState().setGiftCardOrderPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("getCardOrderList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// blue: save / add new shop
export const addGiftCard = async (urlToLoad = "url...") => {
  const { giftCardArray } = useGiftCardStore.getState();
  try {
    console.log(urlToLoad);
    setLoading(true);

    let bodyArray = [];
    giftCardArray.map((item, index) => {
      if (item.category_id !== "") {
        return bodyArray.push(item)
      } else return null
    })

    // return
    console.log("ARRAY-BODY::", bodyArray);

    // bodyArray.push(useGiftCardStore.getState().giftCardAddForm);

    const res = await axios.post(addGiftCardUrl, {
      post_cards: bodyArray,
    });

    console.log("addGiftCard:::", res.data);

    if (res.data.success) {
      useGiftCardStore.getState().resetGiftCardAddForm();
      // Toastr({ message: res.data.message, type: "success" });
      getAllGiftCard("", urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("saveNewShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// orange: delete gift card
export const deleteGiftCard = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(
      deleteGiftCardUrl,
      useGiftCardStore.getState().giftCardDelBody
    );
    if (res.data.success) {
      // Toastr({ message: res.data.message, type: "success" });
      getAllGiftCard("", urlToLoad);
      console.log("Success Delete");
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// green: update gift card
export const updateGiftCard = async (urlToLoad = "url...") => {
  try {
    console.log("UPDATE BODY ::: ", useGiftCardStore.getState().finalUpdateCardData);
    // return console.log("UPDATE BODY ::: ", useGiftCardStore.getState().giftCardUpdateForm);
    setLoading(true);

    const res = await axios.post(
      updateGiftCardUrl,
      useGiftCardStore.getState().finalUpdateCardData
    );

    console.log("updateGiftCard::: res.data", res.data);

    if (res.data.success) {
      getAllGiftCard("", urlToLoad);
      useGiftCardStore.getState().resetGiftCardUpdateForm();
      // Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateGiftCard: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// yellow: search gift cards list
export const searchGiftCards = async (searchValue,url="") => {
  try {
    setLoading(true);
    console.log("searchValue::", searchValue);
    const res = await axios.get(url===""?getAllGiftCardsUrl:url, {
      params: {
        take: 15,
        search: searchValue,
      },
    });

    console.log("searchGiftCards::: res.data", res.data);

    if (res.data.success) {
      useGiftCardStore.getState().setGiftCardListAll(res.data.data.data);
      useGiftCardStore.getState().setGiftCardListAllTemp(res.data.data.data);
      useGiftCardStore.getState().setGiftCardListPagination(res.data.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchGiftCards: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("searchGiftCards: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// green: search order list
export const searchCardOrdersList = async (searchValue) => {
  try {
    setLoading(true);
    let cardID = localStorage.getItem("cardID")
    console.log("searchValue::", searchValue);
    const res = await axios.post(getAllOrdersUrl, {
      search: searchValue,
      card_id: cardID,
      take: 5,
    });

    console.log("searchOrdersList::: res.data", res.data);

    if (res.data.success) {
      useGiftCardStore.getState().setGiftCardOrderList(res.data.data.data);
      useGiftCardStore.getState().setGiftCardOrderPagination(res.data.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchOrdersList: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("searchOrdersList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
