import React from 'react'
import { Link } from 'react-router-dom'
import { RightArrowPlaceholder } from '../Utility/ImageImports';

export default function BackLink({
  linksArray = [
    { label: "one", linkTo: "/test/one" },
    { label: "two", linkTo: "/test/two" }
  ]
}) {
  // console.log(linksArray);
  return (
    <div className='text-cIconColor flex items-center pb-5 text-fs16'>
      {
        linksArray.map((item, index) =>
          <Link key={index} to={item.linkTo} className={`capitalize flex items-center font-fw500 ${linksArray.length === index+1 ? "text-cBrand" : "text-cIconColor hover:text-cBrand"}`} >
            {item.label}{linksArray.length !== index+1 ? 
            <img src={RightArrowPlaceholder} alt="arrow-right" className='mx-4 w-s7 h-auto rotate-60' />
            : ""}
          </Link>          
        )
      }
    </div>
  )
}
