import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomerStore, { deleteCustomer, getAllOrderListOfCustomer, getCustomerDetails } from "../../../App/Stores/CustomerStore";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import { BaseUrlSrc } from "../../../App/Utility/Url";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";
import CommonDetailsCard from "../../../Components/Card/CommonDetailsCard";
import CommonDeleteConfirmModal from "../../../Components/Modal/CommonDeleteConfirmModal";
import BackLink from "../../../Components/Pagination/BackLink";
import { NoUser } from "../../../Components/Utility/ImageImports";
import ChipArray from "../../VerifyIdentity/chipArray";
import EditCustomer from "../Popup/EditCustomer";

const CustomerDetails = (props) => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { customerDetails, showDeleteModal, setShowDeleteModal, setShowEditModal, setCustomerUpdateData, customerUpdateData } = useCustomerStore();

  const navigateTo = useNavigate();

  const location = useLocation()

  const HandleEditDataFillUp = () => {

    let updateName = {
      target: {
        name: "name",
        value: customerDetails?.name
      }
    }
    setCustomerUpdateData(updateName)
    let updateImg = {
      target: {
        name: "image_url",
        value: customerDetails?.image_url
      }
    }
    setCustomerUpdateData(updateImg)

    let updateEmail = {
      target: {
        name: "email",
        value: customerDetails?.email
      }
    }
    setCustomerUpdateData(updateEmail)

    let updatePhone = {
      target: {
        name: "phone",
        value: customerDetails?.phone
      }
    }
    setCustomerUpdateData(updatePhone)

    let updateAddress = {
      target: {
        name: "address",
        value: customerDetails?.address
      }
    }
    setCustomerUpdateData(updateAddress)

    let updateID = {
      target: {
        name: "user_id",
        value: customerDetails?.id
      }
    }
    setCustomerUpdateData(updateID)


    console.table(customerUpdateData);

  }

  useEffect(() => {
    setBarTitle(t("Customer Details"));
    PageTitle(t("Customer Details"));

    if (!customerDetails?.name) {
      getCustomerDetails(localStorage.getItem("customerID"))
    }
    getAllOrderListOfCustomer("", "", localStorage.getItem("customerID"))

    HandleEditDataFillUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);


  return (
    <>
      <BackLink linksArray={[
        { label: t("customers"), linkTo: "/customers" },
        { label: t("customer details"), linkTo: "/customers/customer-details" },
      ]} />

      <EditCustomer />
      <CommonDeleteConfirmModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        messageToShow={t("Are you sure, you want to Delete this Customer ?")}
        onConfirm={() => {
          let delSuccess = deleteCustomer(customerDetails?.id)
          if (delSuccess) {
            navigateTo("/customers")
          }
        }}
      />

      <CommonDetailsCard
        isVerified={customerDetails?.is_verified === 1 ? true : false}
        // isCustomer={true}  //using this will remove icons from entities, thus deprecated
        onDelete={() => setShowDeleteModal(true)}
        onEdit={() => setShowEditModal(true)}
        mainImage={customerDetails?.image_url ? BaseUrlSrc + "/" + customerDetails?.image_url : NoUser}
        name={customerDetails?.name ? customerDetails?.name : "NA"}
        email={customerDetails?.email ? customerDetails?.email : "NA"}
        phone={customerDetails?.phone ? customerDetails?.phone : "NA"}
        address={customerDetails?.address ? customerDetails?.address : "NA"}
        styleClass={'mb-s20 bg-cWhite p-s20'}
      />

      <div className="flex items-center mt-s40 pt-s20 bg-white pl-s20 w-full overflow-x-auto">
        <ChipArray
          onClick={() => { navigateTo(`/customers/customer-details`) }}
          item={location.pathname === `/customers/customer-details`
            && true} name="Order History"
        />
        <ChipArray
          onClick={() => { navigateTo(`/customers/verification-history`) }}
          item={location.pathname === `/customers/verification-history` && true}
          name="Verification History" />
      </div>
      {props.children}
      {/* <OrderTable headers={headers} tableBtnTitle="Create Order" /> */}
    </>
  );
};

export default CustomerDetails;

