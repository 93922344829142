import React, { useEffect } from 'react';
import useLayoutStore from '../../App/Stores/LayoutStore';
import UseNotificationStore, { getNotificationIndex } from '../../App/Stores/NotificationStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonTable from '../../Components/Table/CommonTable';
import TakeItem from '../../Components/Table/TakeItem';
import NotificationTableRow from './NotificationTableRow';

const NotificationManagement = () => {
    const { setBarTitle } = useLayoutStore();
    const { notificationTakeAmount, setNotificationTakeAmount, notificationIndex } = UseNotificationStore();

    const notificationHeaders = [
        { index: 1, name: "#" },
        { index: 2, name: "Title" },
        { index: 3, name: "Details" },
        { index: 4, name: "Date" },
    ]

    useEffect(() => {
        // set the title of page and top bar
        setBarTitle("Notifications");
        PageTitle("Notifications");

        fetchNotification();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchNotification = async () => {
        await getNotificationIndex();
    }

    return (
        <div>
            <CommonTable
                takeComponent={
                    <TakeItem
                        value={notificationTakeAmount}
                        onChange={async (value) => {
                            await setNotificationTakeAmount(value);
                            await getNotificationIndex();
                        }}
                    />
                }
                headers={notificationHeaders}
                items={
                    notificationIndex?.data?.map((item, index) =>
                        <NotificationTableRow key={index} data={item} index={index} startPoint={notificationIndex?.from} />
                    )
                }

                shoSearchBox={false}
                showPagination={
                    notificationIndex?.last_page !== 1 ? true : false
                }
                prevPageUrl={notificationIndex?.prev_page_url}
                paginationObject={notificationIndex}
                nextPageUrl={notificationIndex?.next_page_url}
                pagesArray={notificationIndex?.links}
                paginationOnClick={async (url) => {
                    console.log("url::::", url);
                    await getNotificationIndex(url);
                }}


                showingFrom={notificationIndex?.from}
                showingTo={notificationIndex?.to}
                totalResult={notificationIndex?.total}
            />
        </div>
    )
}

export default NotificationManagement