import React from 'react';
import ContactReply from '../../Pages/ContactMessage/ContactReply';
import NotificationDetailsModal from '../../Pages/Notification/NotificationDetailsModal';
import DownloadInvoiceModal from '../../Pages/Order/DownloadInvoiceModal';
import RejectRequestModal from '../../Pages/VerifyIdentity/Modal/RejectRequestModal';
import VerificationDetailsModal from '../../Pages/VerifyIdentity/Modal/VerificationDetailsModal';
import VerificationModal from '../../Pages/VerifyIdentity/Modal/VerificationModal';

const CommonModalArea = () => {
    return (
        <div>
            <VerificationDetailsModal />
            <VerificationModal />
            <RejectRequestModal />

            {/* order ui modals */}
            <DownloadInvoiceModal />

            {/* contact message reply */}
            <ContactReply />

            {/* notification details */}
            <NotificationDetailsModal />

        </div>
    );
};

export default CommonModalArea;