import React from "react";
import useRoleStore from "../../App/Stores/RoleStore";
import CommonButton from "../../Components/Button/CommonButton";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import CommonModal from "../../Components/Modal/CommonModal";
import { CheckItems } from "./CheckItems";

export function EditRole() {
  const { showEditModal, setShowEditModal } = useRoleStore();
  return (
    <CommonModal
      showModal={showEditModal}
      setShowModal={setShowEditModal}
      modalTitle="Edit Role"
      singleButton={
        <>
          <CommonButton btnLabel="Save" colorType="primary" />
        </>
      }
      mainContent={<EditForm />}
    />
  );
}

function EditForm() {
  return (
    <div className="pb-5">
      <CommonInput label={"Name"} placeholder="Give a name to the role" />
      <div className="py5">
        <CommonLabel labelText="Access to" />
      </div>
      <div className="text-left flex items-center justify-between text-fs16 px-s5">
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems isEditing={true} title={"dashboard"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"customers"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"shops"} />
          </div>
        </div>
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems isEditing={true} title={"category"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"gift cards"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"orders"} />
          </div>
        </div>
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems isEditing={true} title={"coupon"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"FAQ"} />
          </div>
          <div className="py-5">
            <CheckItems isEditing={true} title={"language"} />
          </div>
        </div>
      </div>
    </div>
  );
}
