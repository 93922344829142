import React from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore, { saveNewCategory } from "../../../App/Stores/CategoryStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import ImageUpload from "../../../Components/Input/ImageUpload";
import CommonModal from "../../../Components/Modal/CommonModal";

const AddCategory = () => {
  const { t } = useTranslation();

  const { addingNewCategory, setAddingNewCategory } = useCategoryStore();

  return (
    <>
      <CommonModal
        showModal={addingNewCategory}
        setShowModal={setAddingNewCategory}
        widthClass="w-w40vw"
        modalTitle={t("Add Category")}
        mainContent={<AddForm />}
      />
    </>
  );
};

function AddForm() {
  const { t } = useTranslation();

  const { setCategoryFormData, categoryFormData, setAddingNewCategory } = useCategoryStore();
  const handleFormSubmit = async (e) => {    
    e.preventDefault();
    await saveNewCategory();
    setAddingNewCategory(false);
  }

  const handleFormChange = (result) => {    
    setCategoryFormData("image", result);
  }

  return (
    <div className="pt-5 bg-white">
      <form onSubmit={handleFormSubmit}>

        <CommonInput
          onChange={(e) => setCategoryFormData('name', e.target.value)}
          label={t("Name")}
          name={"name"}
          value={categoryFormData.name}
          required={true}
          placeholder={t("Enter Category Name")}
        />

        <ImageUpload CustomStyles={'mx-s30'} onChangeFile={handleFormChange} />

        <div className="flex flex-row justify-center mt-5">
          <CommonButton
            btnLabel={t("Add")}
            colorType="primary"
            type="submit"
          />
        </div>

      </form>
    </div>
  );
}

export default AddCategory;
