import React from 'react';
import useVerifyIdentityStore from '../../../App/Stores/VerifyIdentityStore';
import { formatDate } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import Image from '../../../Components/Image/Image';
import { BackSideLicense, FrontSideLicense } from '../../../Components/Utility/ImageImports';
import IdentityVerifyDetails from './IdentityVerifyDetails';

const CurrentRequest = () => {

    const { setVerificationConfirmationModal, setShowRejectRequestModal } = useVerifyIdentityStore()

    const verificationDetails = JSON.parse(localStorage.getItem('verificationDetails'))

    function VerifyIdentityType(state) {
        switch (state) {
            case 'mncard':
                return " My Number Card"
            case 'driving':
                return "Driving License"
            case 'rcard':
                return "Residence Card"
            case 'passport':
                return "Passport"
            default:
                return 'NA'
        }
    }

    return (
        <IdentityVerifyDetails>
            <div className='flex justify-between border-y-2 border-cNmSelect mb-10 py-s10'>
                <span className='text-cTextBlack text-fs20 font-fw600 capitalize'>Verifying with : {verificationDetails?.type ? VerifyIdentityType(verificationDetails?.type) : ''}</span>
                <span className='text-cTextBlack text-fs16 font-fw500'>Application date : {formatDate(verificationDetails?.created_at)}</span>
            </div>

            <div className='flex justify-around space-x-10 px-10'>

                <div className="flex flex-col  border-2 max-w-[450px] w-full min-w-[360px] h-[275px] border-cNmSelect px-5 pb-5 pt-s10 rounded-br10">
                    <span className='text-cTextBlack text-fs20 font-fw600 mb-s10 flex justify-center'>Front Side</span>
                    <Image className='w-full h-[201px] rounded-br10 object-cover' src={verificationDetails?.vid_front} alt="" dummyImage={FrontSideLicense} />
                </div>

                <div className="flex flex-col  border-2 max-w-[450px] w-full min-w-[360px] h-[275px] border-cNmSelect px-5 pb-5 pt-s10 rounded-br10">
                    <span className='text-center text-cTextBlack text-fs20 font-fw600 mb-s10 flex justify-center'>Back Side</span>
                    <Image className='w-full h-[201px] rounded-br10 object-cover' src={verificationDetails?.vid_back} alt="" dummyImage={BackSideLicense} />
                </div>

            </div>

            <div className={`flex mt-s40 ${verificationDetails?.status === 'verified' ? "justify-center" : "justify-between"}`}>
                <CommonButtonOutlined btnLabel="Reject" colorType="primary" onClick={() => { setShowRejectRequestModal(true) }} />
                {verificationDetails?.status === 'verified' ? "" :
                    <CommonButton width="w-[200px]" onClick={() => { setVerificationConfirmationModal(true) }} btnLabel="Information matched" />
                }
            </div>
        </IdentityVerifyDetails>
    );
};

export default CurrentRequest;