import { saveAs } from 'file-saver';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import ReactToPdf from 'react-to-pdf';
import useOrderStore from '../../App/Stores/OrderStore';
import { BaseUrlSrc } from '../../App/Utility/Url';
import { formatDate } from '../../App/Utility/UtilityFunctions';
import CommonModal from '../../Components/Modal/CommonModal';
import { DownloadIcon, DownloadIconSecondary, NoImageLogo, PrintIcon } from '../../Components/Utility/ImageImports';
import InvoiceReceipt from './InvoiceReceipt';
import InvoiceSticker from './InvoiceSticker';
import Image from '../../Components/Image/Image';

function DownloadInvoiceModal() {
    // const [isLoadedImage, setIsLoadedImage] = useState(true);

    const {
        showOrderDownloadUI,
        setShowOrderDownloadUI,
        orderDetails
    } = useOrderStore();

    const invoiceRef = useRef();
    const stickerRef = useRef();
    const imageRef = useRef();

    const downloadRef = useRef();

    const invoicePrint = useReactToPrint({
        content: () => invoiceRef.current,
        copyStyles: true,
        pageStyle: `@media print {
      @page {
        size: size: A4 portrait;
        margin: 0;
      }
    }`,
    });

    const stickerPrint = useReactToPrint({
        content: () => stickerRef.current,
        copyStyles: true,
        pageStyle: `@media print {
      @page {
        size: size: A4 portrait;
        margin: 0;
      }
    }`,
    });

    const imagePrint = useReactToPrint({
        content: () => imageRef.current,
        copyStyles: true,
        pageStyle: `@media print {
      @page {
        size: size: A4 landscape;
        margin: 0;
      }
    }`,
    });

    const downloadImage = async () => {
        saveAs(orderDetails?.text_image_base64, `invoice-text-image_${orderDetails?.code}`);
    };

    // const AllDownload = () => {
    //     console.log("Download All at once");
    // }

    return (
        <CommonModal
            // widthClass='w-[1280px]'
            showModal={showOrderDownloadUI}
            setShowModal={setShowOrderDownloadUI}
            subTitle={
                <div className='text-cInputPlaceholder w-full pt-s15 text-left text-fs14 font-semibold'>* * * For better quality , please print directly.</div>
            }
            modalTitle={
                <div className='relative'>
                    <div>Download Items</div>
                    <div className='absolute top-0 left-0'>

                        {/* l       download all at one click ! */}
                        <ReactToPdf targetRef={downloadRef} filename={`invoice_receipt_${orderDetails?.code}.pdf`}>
                            {({ toPdf }) => (
                                <div onClick={toPdf} >
                                    <ReactToPdf targetRef={stickerRef} filename={`invoice_sticker_${orderDetails?.code}.pdf`}>
                                        {({ toPdf }) => (
                                            <div onClick={toPdf} >

                                                < div
                                                    onClick={downloadImage}
                                                    className='flex justify-between cursor-pointer items-center rounded-full border text-fs14 text-cRed font-semibold border-cRed px-s10 py-s5 w-[140px]'
                                                >
                                                    <div className=''>Download All</div>
                                                    <img src={DownloadIconSecondary} alt="" />
                                                </div>
                                            </div>
                                        )}
                                    </ReactToPdf>
                                </div>
                            )}
                        </ReactToPdf >
                    </ div >
                </ div >
            }
            mainContent={
                < div className={` ${orderDetails?.text_image ? "h-[216px]" : "h-[144px]"} w-full overflow-hidden`} >
                    <div className="w-full bg-black h-s1 my-s20"></div>
                    <div className="flex flex-col space-y-5">
                        {orderDetails?.text_image ?
                            <DLoadRow
                                title='Get Image'
                                imgUrl={BaseUrlSrc + "/" + orderDetails?.text_image}
                                dl_onClick={downloadImage}
                                pr_onClick={() => { imagePrint(); }}
                            /> : ""}

                        <div>
                            <ReactToPdf targetRef={downloadRef} filename={`invoice_receipt_${orderDetails?.code}.pdf`}>
                                {({ toPdf }) => (
                                    <DLoadRow
                                        title='Get Invoice'
                                        pr_onClick={invoicePrint}
                                        dl_onClick={toPdf}
                                    />
                                )}
                            </ReactToPdf>
                            <div style={{ width: 1290, height: '100%', background: 'white' }} ref={downloadRef} />
                        </div>

                        <div>
                            <ReactToPdf targetRef={stickerRef} filename={`invoice-sticker_${orderDetails?.code}.pdf`}>
                                {({ toPdf }) => (
                                    <DLoadRow
                                        title='Get Sticker'
                                        pr_onClick={stickerPrint}
                                        dl_onClick={toPdf}
                                    />
                                )}
                            </ReactToPdf>
                            <div style={{ width: '100%', height: '100%', background: 'white' }} ref={stickerRef} />
                        </div>
                    </div>


                    {/*r            data to print pdf */}
                    {/* e       invoice */}
                    <div
                        ref={downloadRef}
                        className="w-[793px]">
                        <InvoiceReceipt
                            refToPrint={invoiceRef}

                            invoice_id={orderDetails?.code ?? "NA"}
                            order_id={orderDetails?.code ?? "NA"}
                            purchase_id={orderDetails?.code ?? "NA"}

                            date={orderDetails?.order_date ? formatDate(orderDetails?.order_date) : "NA"}

                            shop_image={orderDetails?.shop_image_base64 ?? "NA"}
                            shop_name={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                            phone={orderDetails?.post_card_with_shop?.shop?.phone ?? "NA"}
                            address={orderDetails?.post_card_with_shop?.shop?.address ?? "NA"}

                            from_name={orderDetails?.customer?.name ?? "NA"}
                            from_email={orderDetails?.customer?.email ?? "NA"}
                            from_address={orderDetails?.customer?.address ?? "NA"}
                            from_phone={orderDetails?.customer?.phone ?? "NA"}

                            to_name={orderDetails?.ship_name ?? "NA"}
                            to_email={orderDetails?.ship_address_type ?? "NA"}
                            to_address={orderDetails?.ship_address ?? "NA"}
                            to_phone={orderDetails?.ship_phone ?? "NA"}


                            order_item_name={orderDetails?.post_card_with_shop?.name ?? "NA"}
                            order_shop={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                            order_qty={orderDetails?.quantity ?? "0"}
                            order_price={orderDetails?.price ?? "0"}
                            order_price_total={
                                orderDetails?.quantity * orderDetails?.price > 0 ? orderDetails?.quantity * orderDetails?.price : "0"
                            }

                            order_price_auth_fee={orderDetails?.authority_fee ?? "0"}
                            order_price_discount={orderDetails?.discount ?? "0"}
                            order_price_shipping={orderDetails?.shipping_fee ?? "0"}
                            order_grand_total={orderDetails?.order_total ?? "0"}
                        />
                    </div>

                    {/* e       image  */}
                    <div className="w-full">
                        <img
                            ref={imageRef}
                            src={BaseUrlSrc + "/" + orderDetails?.text_image}
                            alt="Attachment"
                            className='w-full'
                        />

                    </div>

                    {/* e       sticker  */}
                    <div className="w-[790px]">
                        <InvoiceSticker
                            stickerRef={stickerRef}
                            deliveryFrom={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                            deliveryDate={orderDetails?.order_date ? formatDate(orderDetails?.order_date) : "NA"}
                            deliveryAddress={orderDetails?.ship_address ?? "NA"}
                            deliveryUserPhone={orderDetails?.ship_phone ?? "NA"}
                            deliveryUserName={orderDetails?.ship_name ?? "NA"}
                            deliveryInvoiceNumber={orderDetails?.code ?? "NA"}
                        />
                    </div>
                </ div>
            }

        />
    )
}

export default DownloadInvoiceModal

function DLoadRow({
    title = "",
    imgUrl = "",
    pr_onClick = () => { },
    dl_onClick = () => { },
}) {
    return (
        <div className='flex justify-between items-center font-semibold border rounded-br10 border-cDloadBorder px-s10 py-s5 text-fs16'>
            <div>{title}</div>
            <div className='flex items-center space-x-5'>
                <img
                    className="cursor-pointer"
                    onClick={() => {
                        pr_onClick();
                    }}
                    src={PrintIcon}
                    alt=""
                />

                <img
                    className="cursor-pointer"
                    onClick={() => {
                        dl_onClick();
                    }}
                    src={DownloadIcon}
                    alt=""
                />
            </div>
        </div>
    )
}