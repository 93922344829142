import React from "react";
import { Link } from "react-router-dom";
import AddBtnOutlined from "../../../../Components/Button/AddBtnOutlined";
import { ShopLogo } from "../../../../Components/Utility/ImageImports";

export default function NoBoostedShops() {
  return (
    <div className="w-full relative ">
      <div className="text-fs20 font-fw600 pb-5">Boosted Shop</div>

      <div className="flex flex-col justify-center items-center h-v35vw z-20">
        
        <div className="text-fs24 font-fw600 text-cTextSecondaryBody z-50">
          No Boosted Shop added yet
        </div>

        <Link to="/boosted-shop/add-shop" className="pt-5 z-50">
          <AddBtnOutlined btnLabel="Add Boosted Shop" />
        </Link>
      </div>
      <img src={ShopLogo} alt="" className="absolute right-0 top-1/2 -translate-y-1/2 z-0" />
    </div>
  );
}
