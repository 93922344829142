import { Tooltip } from "@mui/material";
import React from "react";
import useCategoryStore, { toggleCategoryActivation } from "../../../App/Stores/CategoryStore";
import GiftCardImage from "../../../Images/dummy-img/giftcard1.jpeg";
import DeleteIcon from "../../../Images/icon/delete-red.svg";
import EditIcon from "../../../Images/icon/edit-icon.svg";

import { MuiCustomSwitch } from "../../../App/Stores/UtilityStore";
import Image from "../../../Components/Image/Image";

export default function CategoryTableItem({ tableData = {}, barIndex }) {
  // const [categoryId, setCategoryID] = useState(null);

  const {
    setShowDeleteModal,
    setShowEditModal,
    setShowDeactivateModal,
    changeEditForm,
    setDeleteCategoryForm,
    setDeactivateCategoryForm,
    setShowEditID,
  } = useCategoryStore();

  const handleActiveShop = (e) => {
    // console.log(e.target.checked);
  };

  const onEditHandle = (tableData) => {
    setShowEditModal(true);
    // setCategoryID(tableData.id);
    setShowEditID(tableData.id);
    // console.log("id: ", categoryId);
    changeEditForm("name", tableData.name);
    changeEditForm("image", tableData.image);
    // console.log("on edit id ", tableData.id);
  };

  return (
    <>
      <tr className={`hover:bg-cBandHover bg-white shadow-cShopItem select-none`}>
        <th className="pl-s10 text-center font-fw400 py-s10 border-collapse border min-w-[220px]">
          <div className="flex items-center">
            <Image
              className="w-s24 h-s24 object-cover rounded-full"
              alt=""
              src={tableData?.image}
              dummyImage={GiftCardImage}
            />
            <Tooltip title={tableData?.name ? tableData?.name : "NA"} placement="right">
              <td className="px-s10 max-w-[180px] truncate">
                {tableData?.name ? tableData?.name : "NA"}
              </td>
            </Tooltip>
          </div>

        </th>

        <CommonTD
          showData={tableData?.total_cards ? tableData?.total_cards : "0"}
        />
        <CommonTD
          showData={tableData?.total_orders ? tableData?.total_orders : "0"}
        />
        <CommonTD
          showData={
            tableData?.total_pending_orders
              ? tableData?.total_pending_orders
              : "0"
          }
        />

        <td className="py-s10 border-collapse border min-w-[200px]">
          <tr className="flex space-x-3 items-center justify-center">
            <td
              onClick={() => {
                onEditHandle(tableData);
                console.log("Clicked Id", tableData?.id);
              }}
            >
              <img src={EditIcon} alt="" className="w-s20 h-s20 cursor-pointer" />
            </td>

            <td
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteCategoryForm(tableData?.id);
                console.log("Delete Clicked Id", tableData?.id);
              }}
            >
              <img
                src={DeleteIcon}
                alt=""
                className="w-s20 h-s20 ml-5 mr-2 cursor-pointer"
              />
            </td>

            <td className="" >
              <MuiCustomSwitch
                color="secondary"
                size="small"
                checked={tableData?.is_active}
                onClick={() => {
                  setDeactivateCategoryForm(tableData?.id);
                  if (tableData?.is_active) {
                    setShowDeactivateModal(true);
                  } else {
                    toggleCategoryActivation(1);
                  }
                }}
                onChange={(e) => handleActiveShop(e)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}

const CommonTD = ({ showData }) => {
  return (
    <td className=" text-center py-s10 border-collapse border min-w-[150px]">
      <span className="">{showData ? showData : "NA"}</span>
    </td>
  );
};
