import { useTranslation } from "react-i18next";
import useBoostedStore, {
  updateBoostedShop,
} from "../../../../App/Stores/BoostedStore";
import CommonButton from "../../../../Components/Button/CommonButton";
import CommonDropDown from "../../../../Components/DropDown/CommonDropDown";
import CommonInput from "../../../../Components/Input/CommonInput";
import CommonLabel from "../../../../Components/Label/CommonLabel";
import CommonModal from "../../../../Components/Modal/CommonModal";

export default function EditBoostedShop() {
  const { t } = useTranslation();

  const {
    showEditBoostedShop,
    setShowEditBoostedShop,
    setUpdateBoostedShopForm,
    updateBoostedShopForm,
    boostedShopListPagination,
  } = useBoostedStore();
  //   const [tempDataArray, setTempDataArray] = useState([]);

  const HandleSubmit = () => {
    // console.log("updateBoostedShopForm:::", updateBoostedShopForm);
    let updateSuccess = updateBoostedShop(
      boostedShopListPagination.path +
      "?page=" +
      boostedShopListPagination.current_page
    );
    if (updateSuccess) {
      setShowEditBoostedShop(false);
    }
  };
  return (
    <>
      <CommonModal
        showModal={showEditBoostedShop}
        setShowModal={setShowEditBoostedShop}
        modalTitle={t("Edit Boosted shop")}
        mainContent={
          <>
            <div className="w-full">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="bg-white rounded-br5 w-full"
              >
                <CommonInput
                  label={t("Shop Name")}
                  value={updateBoostedShopForm?.shop_name}
                  onChange={() => {
                    //
                  }}
                  className3="cursor-default"
                  disabled={true}
                />
                <CommonLabel labelText={t("Select Status")} />
                <div className="pt-5 w-full">
                  <CommonDropDown
                    fullWidth={true}
                    basicColor={true}
                    data={["active", "not started", "expired"]}
                    toggleTitle="status"
                    value={
                      updateBoostedShopForm?.status === "init"
                        ? "not started"
                        : updateBoostedShopForm?.status
                    }
                    itemOnClick={(e) => {
                      console.log(e);
                      if (e === "not started") {
                        e = "init";
                      }
                      let tmp = {
                        target: {
                          name: "status",
                          value: e,
                        },
                      };
                      setUpdateBoostedShopForm(tmp);
                    }}
                  />
                </div>

                <div className="flex items-center justify-between pt-5">
                  <div className="w-full">
                    <CommonInput
                      className="mb-0"
                      label={t("Boost Start Date")}
                      required={true}
                      name="start_date"
                      type="date"
                      value={updateBoostedShopForm?.start_date?.slice(0, 10)}
                      onChange={(e) => {
                        if (e.target.value > updateBoostedShopForm?.end_date) {
                          let tmp = {
                            target: {
                              name: "end_date",
                              value: "",
                            },
                          };
                          setUpdateBoostedShopForm(tmp);
                        }

                        let startDate = {
                          target: {
                            name: "start_date",
                            value: e.target.value,
                          },
                        };
                        setUpdateBoostedShopForm(startDate);
                      }}
                      placeholder={t("Select start date")}
                    />
                  </div>
                  <div className="pr-s20"></div>
                  <div className="w-full">
                    <CommonInput
                      className="mb-0"
                      label={t("Boost End Date")}
                      required={true}
                      disabled={
                        updateBoostedShopForm?.start_date ? false : true
                      }
                      startDate={updateBoostedShopForm?.start_date?.slice(
                        0,
                        10
                      )}
                      name="end_date"
                      type="date"
                      value={updateBoostedShopForm?.end_date?.slice(0, 10)}
                      onChange={setUpdateBoostedShopForm}
                      placeholder={t("Select expiry date")}
                    />
                  </div>
                </div>

                <div className="pt-10 flex justify-center">
                  <CommonButton
                    width={"w-s200"}
                    btnLabel={t("save changes")}
                    colorType="primary"
                    onClick={() => HandleSubmit()}
                  />
                </div>
              </form>
            </div>
          </>
        }
      />
    </>
  );
}
