import React from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { updateAuthFee } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const AuthorityFee = () => {
  const { t } = useTranslation();

  const { authFee, setAuthFee } = useSettingsStore();

  const handleSubmit = () => {
    if (authFee) {
      updateAuthFee();
    }
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}  className="w-full">
      <div className="text-fs20 font-fw600 pb-5">{t("Set Authority Fee")}</div>

      <div className="bg-white p-5 rounded-br5 shadow-md">
        <CommonInput
          className="mb-0"
          no_label={true}
          placeholder={t("Enter amount..")}
          type="number"
          required={true}
          value={authFee ?? 0}
          min_number={1}
          onChange={(e) => setAuthFee(e.target.value)}
        />

        <div className="pt-5">
          <CommonButton type="submit" onClick={handleSubmit} btnLabel="Save Changes" colorType="primary" />
        </div>
      </div>
    </form>
  );
};

export default AuthorityFee;
