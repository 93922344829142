import React from "react";
import { useTranslation } from "react-i18next";
import useCustomerStore, {
  toggleCustomerActivation,
} from "../../../App/Stores/CustomerStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeactivateCustomer = () => {
  const { t } = useTranslation();

  const { showDeactivateModal, setShowDeactivateModal,customerListPagination } = useCustomerStore();
  return (
    <div>
      <CommonModal
        showModal={showDeactivateModal}
        setShowModal={setShowDeactivateModal}
        widthClass="w-w40vw"
        modalTitle={t("Deactivate Customer")}
        mainContent={
          <div className="pt-5 pb-[30px] flex justify-center items-center">
            <div>{t('Do you want to Deactivate this customer?')}</div>
          </div>
        }
        primaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Deactivate")}
              colorType="danger"
              onClick={() => {
                let loadUrl =
                  customerListPagination.path +
                  "?page=" +
                  customerListPagination.current_page;
                let toggleSuccess = toggleCustomerActivation(0, loadUrl);
                if (toggleSuccess) setShowDeactivateModal(false);
              }}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Cancel")}
              onClick={() => setShowDeactivateModal(false)}
            />
          </>
        }
      />
    </div>
  );
};

export default DeactivateCustomer;
