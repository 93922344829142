import React, { useEffect } from "react";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import useRoleStore from "../../../App/Stores/RoleStore";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";
import AddBtnOutlined from "../../../Components/Button/AddBtnOutlined";
import { RoleLogo } from "../../../Components/Utility/ImageImports";
import AddAdmin from "./AddAdmin";

export default function NoAdmin() {
  const { setBarTitle } = useLayoutStore();
  const { setShowAddAdmin } = useRoleStore();

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle("Admins");
    PageTitle("Admins");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AddAdmin />
      <div className="w-full h-h80vh bg-white rounded-br5 flex flex-col justify-center items-center relative">
        <img src={RoleLogo} alt="" className="absolute bottom-20 right-0" />
        <div className="text-fs24 font-fw600 text-cTextSecondaryBody">
          You haven’t created any admin
        </div>
        <div className="pt-s10">
          <AddBtnOutlined
            onClick={() => setShowAddAdmin(true)}
            btnLabel="create new admin"
          />
        </div>
      </div>
    </>
  );
}
