import React, { useEffect } from "react";
import useBoostedStore, {
  deleteBoostedShop,
  getAllBoostedShop,
  searchBoostedShops,
} from "../../../../App/Stores/BoostedStore";
import useLayoutStore from "../../../../App/Stores/LayoutStore";
import { PageTitle } from "../../../../App/Utility/UtilityFunctions";
import CommonDeleteConfirmModal from "../../../../Components/Modal/CommonDeleteConfirmModal";
import CommonTable from "../../../../Components/Table/CommonTable";
import AddBoostShop from "./AddBoostShop";
import BoostedShopTableItem from "./BoostedShopTableItem";
import EditBoostedShop from "./EditBoostedShop";
import { useDebounce } from "use-debounce";
import { getAllShop } from "../../../../App/Stores/ShopStore";
import { useTranslation } from "react-i18next";

export default function BoostedShop() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    setShowAddBoostedShop,
    boostedShopListPagination,
    boostedShopList,
    searchShopBoost,
    setSearchShopBoost,
  } = useBoostedStore();

  const [searchValue] = useDebounce(searchShopBoost, 500);

  const BoostedShopHeaders = [
    { index: 1, name: t("shop name") },
    { index: 2, name: t("start date") },
    { index: 3, name: t("expire date") },
    { index: 4, name: t("status") },
    { index: 5, name: t("action") },
  ];

  useEffect(() => {
    setBarTitle(t("Boosted Shop"));
    PageTitle(t("Boosted Shop"));

    getAllShop("", 500, true);
    getAllBoostedShop();
    console.log("boostedShopListPagination:::", boostedShopListPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchBoostedShops(searchValue);
  }, [searchValue]);

  return (
    <>
      <AddBoostShop />
      <EditBoostedShop />
      <ConfirmDelete />
      <div className="w-full">
        <div className="">
          <CommonTable
            addItemTitle={t("add boosted shop")}
            addItemFunction={() => {
              getAllShop("", 500, true);
              setShowAddBoostedShop(true);
            }}
            headers={BoostedShopHeaders}
            items={
              boostedShopList?.length
                ? boostedShopList.map((item, index) => (
                  <BoostedShopTableItem key={index} tableData={item} />
                ))
                : ""
            }
            showingFrom={boostedShopListPagination?.from}
            showingTo={boostedShopListPagination?.to}
            totalResult={boostedShopListPagination?.total}
            searchValue={searchShopBoost}
            searchOnChange={(e) => setSearchShopBoost(e.target.value)}
            showPagination={
              boostedShopListPagination?.last_page !== 1 ? true : false
            }
            paginationObject={boostedShopListPagination}
            pagesArray={boostedShopListPagination.links}
            currentPageNumber={boostedShopListPagination.current_page}
            prevPageUrl={boostedShopListPagination.prev_page_url}
            nextPageUrl={boostedShopListPagination.next_page_url}
            paginationOnClick={(url) => {
              getAllBoostedShop(url);
            }}
          />
        </div>
      </div>
    </>
  );
}

const ConfirmDelete = ({ id }) => {
  const { t } = useTranslation();

  const { showDeleteShopBoost, setShowDeleteShopBoost, boostedShopListPagination } = useBoostedStore();
  return (
    <>
      <CommonDeleteConfirmModal
        showDeleteModal={showDeleteShopBoost}
        setShowDeleteModal={setShowDeleteShopBoost}
        messageToShow={t("Are you sure you want to delete this shop?")}
        onConfirm={() => {
          deleteBoostedShop(boostedShopListPagination.from !== boostedShopListPagination.to ? boostedShopListPagination.path + "?page=" + boostedShopListPagination.current_page : "");
        }}
      />
    </>
  );
};
