import React from "react";

export function CheckItems({ title, isEditing = false }) {
  return (
    <div className="flex items-center">
      <input id={isEditing === true ? title+"2" : title } type="checkbox" className="scale-150 accent-cBrand" />
      <label htmlFor={isEditing === true ? title+"2" : title } className="pl-5 capitalize cursor-pointer">
        {title}
      </label>
    </div>
  );
}
