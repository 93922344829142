/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../App/Stores/authStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import AxiosHeader from "../../App/Utility/AxiosHeader";
import CommonModalArea from "../Modal/CommonModalArea";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Layout = (props) => {
  const { isLoggedIn } = useAuthStore();
  const { setLoggedUser, loggedUser } = useUtilityStore();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    // console.log(width);

    if (width <= 875) setIsSidebarOpen(false);
    else setIsSidebarOpen(true);

    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, [width]);

  useEffect(() => {
    if (!loggedUser.name) {
      if (localStorage.user) {
        setLoggedUser(JSON.parse(localStorage.user));
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (localStorage.postcard_token) {
        // AxiosHeader({ token: localStorage.postcard_token });
      } else {
        AxiosHeader({ token: null });
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
    <CommonModalArea/>
      <div className="flex flex-row min-h-screen bg-cLayoutBg text-gray-800 z-50">
        <Sidebar
          isOpenSidebar={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <main className="main flex items-start justify-start flex-col flex-grow transition-all duration-150 ease-in w-full">
          <Topbar
            isOpen={isSidebarOpen}
          // setIsSidebarOpen={setIsSidebarOpen}
          />
          <div
            className={`w-full ${isSidebarOpen ? "pl-72" : "pl-20"} pt-[89px]`}
          >
            <div className="m-2 sm:m-4 md:m-8 lg:m-10 xl:m-12">
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
