import React from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { updateShippingFee } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const ShippingFee = () => {
  const { t } = useTranslation();

  const { shippingFee, setShippingFee } = useSettingsStore();

  const handleSubmit = () => {
    if (shippingFee) {
      updateShippingFee();
    }
  }
  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="text-fs20 font-fw600 pb-5">{t("Set Shipping Fee")}</div>

      <div className="bg-white p-5 rounded-br5 shadow-md">
        <CommonInput
          className="mb-0"
          no_label={true}
          placeholder={t("Enter amount..")}
          type="number"
          required={true}
          min_number={1}
          value={shippingFee}
          onChange={(e) => setShippingFee(e.target.value)}
        />

        <div className="pt-5">
          <CommonButton type="submit" btnLabel={t("Save Changes")} onClick={handleSubmit} colorType="primary" />
        </div>
      </div>
    </form>
  );
};

export default ShippingFee;
