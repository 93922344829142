import React from "react";
import { DownArrowPlaceholder, DownArrowWhite } from "../Utility/ImageImports";

export default function BtnFillRounded({
  btnLabel = "something...",
  isBtnActive = false,
  onClick,
  fullWidth = false,
  basicColor = false,
  placeholderShow = false,
  btnBgColor = "bg-cBrand",
  btnBorderColor = "ring-cBrand",
}) {
  return (
    <div
      onClick={onClick}
      className={` flex justify-between items-center 
      ${
        fullWidth === true ? "w-full" : "w-w200"
      } px-5 h-s36 ${btnBgColor} capitalize text-fs14 ring-1 rounded-full

      
      ${
        placeholderShow === true
          ? "text-cInputPlaceholder font-fw400"
          : "font-fw600"
      } 
      ${btnBorderColor}
      text-white

      `}
    >
      <div className="">{btnLabel}</div>
      <div>
        <img
          src={basicColor === true ? DownArrowPlaceholder : DownArrowWhite}
          alt=""
          className={`${
            isBtnActive === true ? "rotate-180" : "rotate-0"
          } transition duration-300`}
        />
      </div>
    </div>
  );
}
