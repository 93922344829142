import axios from "axios";
import create from "zustand";
import { verifyIdentityRequestUrl, verifyRequestStatusUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useVerifyIdentityStore = create((set) => ({

  verifyIdentityRequestList: [],
  setVerifyIdentityRequestList: (value) => set({ verifyIdentityRequestList: value }),

  verifyIdentityStatus: 'pending',
  setVerifyIdentityStatus: (value) => set({ verifyIdentityStatus: value }),

  verifyIdentityPaginationUrl: '',
  setVerifyIdentityPaginationUrl: (value) => set({ verifyIdentityPaginationUrl: value }),

  verifyIdentitySearchValue: '',
  setVerifyIdentitySearchValue: (value) => set({ verifyIdentitySearchValue: value }),

  verificationDetails:{},
  setVerificationDetails: (value) => set({ verificationDetails: value }),
  
  verifyIdentityDropDownValue: {value:''},
  setVerifyIdentityDropDownValue: (name, value) => set((state) => (state.verifyIdentityDropDownValue[name] = value)),

  // verifyIdentityDropDownValue: { take: 10 },
  // setVerifyIdentityDropDownValue: (name, value) => set((state) => (state.verifyIdentityDropDownValue[name] = value)),

  verificationConfirmationModal: false,
  setVerificationConfirmationModal: (value) => set({ verificationConfirmationModal: value }),

  showRejectRequestModal: false,
  setShowRejectRequestModal: (value) => set({ showRejectRequestModal: value }),

  showVerificationDetailsModal: false,
  setShowVerificationDetailsModal: (value) => set({ showVerificationDetailsModal: value }),

}));

export default useVerifyIdentityStore;


//Verify Identity Request List
export const verifyIdentityRequestIndex = async (url = "", item = '') => {

  const { setVerifyIdentityRequestList, verifyIdentityStatus } = useVerifyIdentityStore.getState();

  let originalUrl = ''
  item === 'all' || item === '' ? originalUrl = verifyIdentityRequestUrl + `?status=${verifyIdentityStatus}` : originalUrl = verifyIdentityRequestUrl + `?status=${verifyIdentityStatus}&type=${item}`

  let body = {}
  item === 'all' || item === '' ? body = { "status": verifyIdentityStatus, take: 10 } : body = { "status": verifyIdentityStatus, "type": item, take: 10, type: item }

  try {
    setLoading(true);

    const res = await axios.get(url === "" ? originalUrl : url, { params: body });
    console.log("verifyIdentityRequestList res.data:::: ", res?.data);

    if (res.data.success) {
      await setVerifyIdentityRequestList(res.data.data)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("verifyIdentityRequestList:", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// searchVerifyIdentityRequest
export const searchVerifyIdentityRequest = async (e, url = "", item = 'all') => {
  const { setVerifyIdentityRequestList, verifyIdentityStatus,setVerifyIdentityDropDownValue } = useVerifyIdentityStore.getState();
  const { setLoadingSearch } = useUtilityStore.getState()
  try {
    setLoadingSearch(true);
    
    let originalUrl = ''
    item === 'all' || item === '' ? originalUrl = verifyIdentityRequestUrl + `?status=${verifyIdentityStatus}` : originalUrl = verifyIdentityRequestUrl + `?status=${verifyIdentityStatus}&type=${item}`
    const res = await axios.get(originalUrl, { params: { search: e, "status": verifyIdentityStatus, take: 10 } });
    console.log("searchVerifyIdentityRequest res.data:::: ", res.data.data);

    if (res.data.success) {
      await setVerifyIdentityRequestList(res.data.data)
      setLoadingSearch(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoadingSearch(false);
      return false;
    }

  } catch (error) {
    console.log("searchVerifyIdentityRequest: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoadingSearch(false);
    return false;
  }
};



//verify Request Status
export const verifyRequestStatusIndex = async (body) => {

  try {
    setLoading(true);
    const res = await axios.post(verifyRequestStatusUrl, body);
    console.log("verifyRequestStatusIndex res.data:::: ", res?.data);

    if (res.data.success) {
      await localStorage.setItem('verificationDetails',JSON.stringify(res.data.data))
      await fetchVerifyRequestData()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("verifyRequestStatusIndex:", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

export const fetchVerifyRequestData=async()=>{
 await useVerifyIdentityStore.getState().setVerificationDetails(JSON.parse(localStorage.getItem('verificationDetails')))
}