import React, { useEffect } from "react";
import useContactStore, { getContactIndex } from "../../App/Stores/ContactStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import AddBtnOutlined from "../../Components/Button/AddBtnOutlined";
import CommonTable from "../../Components/Table/CommonTable";
import TakeItem from "../../Components/Table/TakeItem";
import { FilterHover, FilterNormal } from "../../Components/Utility/ImageImports";
import ContactTableRow from "./ContactTableRow";

export default function ContactMessage() {
  const { setBarTitle } = useLayoutStore();
  const { contactIndex, contactTakeAmount, setContactTakeAmount } = useContactStore();

  const contactHeader = [
    { index: 1, name: "name" },
    // { index: 2, name: "subject" },
    { index: 3, name: "email" },
    // { index: 4, name: "user type" },
    { index: 5, name: "message" },
    { index: 6, name: "created on" },
    { index: 7, name: "last action" },
  ];

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle("Contact Us");
    PageTitle("Contact Us");

    fetchContactIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContactIndex = async () => {
    await getContactIndex();
  }


  return (
    <>
      <CommonTable
        takeComponent={
          <TakeItem
            value={contactTakeAmount}
            onChange={async (value) => {
              await setContactTakeAmount(value);
              await getContactIndex();
            }}
          />
        }
        // secondaryTitle={
        //   <AddBtnOutlined
        //     btnLabel="Filter"
        //     iconHover={FilterHover}
        //     iconNormal={FilterNormal}
        //   />
        // }
        headers={contactHeader}
        items={contactIndex?.data?.map((item, index) => {
          return <ContactTableRow key={index} data={item} dataIndex={index} end={contactIndex?.data.length - 1} />;
        })}
        addItemModal={<>{/* <AddShop /> */}</>}

        shoSearchBox={false}
        showPagination={
          contactIndex?.last_page !== 1 ? true : false
        }
        prevPageUrl={contactIndex?.prev_page_url}
        paginationObject={contactIndex}
        nextPageUrl={contactIndex?.next_page_url}
        pagesArray={contactIndex?.links}
        paginationOnClick={async (url) => {
          console.log("url::::", url);
          await getContactIndex(url);
        }}


        showingFrom={contactIndex?.from}
        showingTo={contactIndex?.to}
        totalResult={contactIndex?.total}
      />
    </>
  );
}
