import React from "react";
import useRoleStore from "../../App/Stores/RoleStore";
import CommonButton from "../../Components/Button/CommonButton";
import CommonModal from "../../Components/Modal/CommonModal";

export default function DeleteRole() {
  const { showDeleteRole, setShowDeleteRole } = useRoleStore();
  return (
    <>
      <CommonModal
        showModal={showDeleteRole}
        setShowModal={setShowDeleteRole}
        modalTitle="confirmation"
        mainContent={<div className="py-5">Are sure to delete this role ?</div>}
        primaryActionButton={
          <>
            <CommonButton colorType="danger" btnLabel="delete" />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton btnLabel="cancel" />
          </>
        }
      />
    </>
  );
}
