import create from "zustand";
import axios from "axios";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";
import {
  addCategory,
  getAllCategoryList,
  deleteCategoryUrl,
  editCategoryUrl,
  toggleCategoryUrl,
} from "../Utility/Url";
import useGiftCardStore from "./GiftCardStore";

const { setLoading } = useUtilityStore.getState();

const useCategoryStore = create((set) => ({
  categoryListAll: [],
  setCategoryListAll: (value) => set({ categoryListAll: value }),

  categoryListPagination: {},
  setCategoryListPagination: (value) => set({ categoryListPagination: value }),

  categoryListAllTemp: [],
  setCategoryListAllTemp: (value) => set({ categoryListAllTemp: value }),

  categoryFormData: { name: "", image: "" },
  setCategoryFormData: (name, value) =>
    set((state) => (state.categoryFormData[name] = value)),
  resetCategoryFormData: () =>
    set((state) => (state.categoryFormData = { name: "", image: "" })),

  updateImg: false,
  setUpdateImg: (value) => set({ updateImg: value }),

  updateName: false,
  setUpdateName: (value) => set({ updateName: value }),

  editForm: { name: "", image: "" },
  changeEditForm: (name, value) =>
    set((state) => (state.editForm[name] = value)),
  resetEditForm: () =>
    set((state) => (state.editForm = { name: "", image: "" })),

  deleteCategoryForm: { id: "" },
  setDeleteCategoryForm: (value) =>
    set((state) => (state.deleteCategoryForm["id"] = value)),

  deactivateCategoryForm: {
    id: -1,
  },
  setDeactivateCategoryForm: (value) =>
    set((state) => (state.deactivateCategoryForm["id"] = value)),

  // Modals
  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  showEditID: 0,
  setShowEditID: (value) => set({ showEditID: value }),

  showDeactivateModal: false,
  setShowDeactivateModal: (value) => set({ showDeactivateModal: value }),

  addingNewCategory: false,
  setAddingNewCategory: (value) => set({ addingNewCategory: value }),

  searchKey: "",
  setSearchKey: (value) => set({ searchKey: value }),
}));

export default useCategoryStore;

export const getAllCategory = async () => {
  try {
    setLoading(true);
    const res = await axios.get(getAllCategoryList);
    console.log("getALlCategory response data ", res.data);

    if (res.data.success) {
      useCategoryStore.getState().setCategoryListAll(res.data.data);
      useGiftCardStore.getState().setGCategoryList(res.data.data);
      useCategoryStore.getState().setCategoryListAllTemp(res.data.data);
      useCategoryStore.getState().setCategoryListPagination(res.data.data);

      console.log(
        "categoryListPagination ",
        useCategoryStore.getState().categoryListPagination
      );

      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }

    setLoading(false);
  } catch (error) {
    console.log("getAllCategory Error: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

export const saveNewCategory = async () => {
  try {
    setLoading(true);
    const res = await axios.post(
      addCategory,
      useCategoryStore.getState().categoryFormData
    );

    if (res.data.success) {
      useCategoryStore.getState().resetCategoryFormData();
      getAllCategory();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("saveNewCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

export const updateCategory = async ({ categoryId }) => {
  try {
    if (!categoryId) {
      Toastr({ message: "Invalid Category ID!", type: "error" });
      return;
    }

    const {
      setCategoryListAll,
      editForm,
      resetEditForm,
      updateImg,
      updateName,
      setUpdateName,
      setUpdateImg,
    } = useCategoryStore.getState();
    console.log("cat id ", categoryId);
    editForm.id = categoryId;
    console.log("form data: ", editForm);
    // return
    setLoading(true);
    let res = {};

    if (updateName === true && updateImg === false) {
      res = await axios.post(editCategoryUrl, {
        id: editForm?.id,
        name: editForm?.name,
      });
    }

    if (updateImg === true && updateName === false) {
      res = await axios.post(editCategoryUrl, {
        id: editForm?.id,
        image: editForm?.image,
      });
    }

    if (updateImg === true && updateName === true) {
      res = await axios.post(editCategoryUrl, {
        id: editForm?.id,
        image: editForm?.image,
        name: editForm?.name,
      });
    }

    if (updateImg === false && updateName === false) {
      setLoading(false);
      return null;
    }

    console.log("updateCategory data: ", res.data);

    if (res.data.success) {
      setCategoryListAll(res.data.data);
      resetEditForm();
      setUpdateName(false);
      setUpdateImg(false);
      getAllCategory();
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("updateCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Toggle Category
export const toggleCategoryActivation = async (activeStatusToSet) => {
  const body = {
    id: useCategoryStore.getState().deactivateCategoryForm.id,
    is_active: activeStatusToSet,
  };

  try {
    setLoading(true);

    console.log("toggle body:::", body);

    const res = await axios.post(toggleCategoryUrl, body);

    console.log(res.data);

    if (res.data.success) {
      getAllCategory();
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("saveNewCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Delete Category
export const deleteCategory = async () => {
  try {
    setLoading(true);
    const res = await axios.post(
      deleteCategoryUrl,
      useCategoryStore.getState().deleteCategoryForm
    );

    if (res.data.success) {
      // Toastr({ message: res.data.message, type: "success" });
      getAllCategory();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("deleteCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Search Category
export const searchCategoryList = (event) => {
  useCategoryStore.getState().setSearchKey(event.target.value);

  const result = useCategoryStore
    .getState()
    .categoryListAllTemp.filter((item) => {
      if (item) {
        let name = "";
        name = item.name ?? "";

        if (name.toLowerCase().includes(event.target.value.toLowerCase())) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  console.log("search result: ", result);
  useCategoryStore.getState().setCategoryListAll(result);
};
