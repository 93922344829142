import React, { useEffect, useState } from "react";
import useGiftCardStore, {
  addGiftCard,
} from "../../../App/Stores/GiftCardStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";
import useShopStore, { getAllShop } from "../../../App/Stores/ShopStore";
import { getAllCategory } from "../../../App/Stores/CategoryStore";
import AddBtnOutlined from "../../../Components/Button/AddBtnOutlined";
import FormToAddCard from "./FormToAddCard";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import { useTranslation } from "react-i18next";

const AddGiftCard = () => {
  const { t } = useTranslation();

  const {
    addingNewGiftCard,
    setAddingNewGiftCard,
    giftCardAddForm,
    addFormToGiftCardArray,
    giftCardArray,
    resetGiftCardArray,
    giftCardListPagination,
    currentAddCardForm,
    setCurrentAddCardForm
  } = useGiftCardStore();
  const { shopListAll } = useShopStore();
  // const [tempDataArray, setTempDataArray] = useState([]);

  const [onState, setOnState] = useState(false);
  // const [validForm, setValidForm] = useState(false);
  const [toggleAccordion, setToggledAccordion] = useState(false);

  const [totalForm, setTotalForm] = useState([1]);
  const [openedForm, setOpenedForm] = useState(0);

  const GenericValidation = (formObject) => {
    if (
      formObject?.name &&
      formObject?.shop_id &&
      formObject?.price &&
      formObject?.image &&
      formObject?.category_id
    ) {
      // setValidForm(true)
      return 1;
    } else {
      // setValidForm(false)
      return 0;
    }
  }

  const HandleNewForm = () => {
    if (GenericValidation(giftCardArray[currentAddCardForm])) {
      setOpenedForm(openedForm + 1);
      setCurrentAddCardForm(currentAddCardForm + 1);
      setTotalForm([...totalForm, 1]);
      addFormToGiftCardArray(
        {
          shop_id: "",
          category_id: "",
          name: "",
          price: "",
          image: "",
        }
      );
      setOnState(false);
    } else {
      setOnState(true);
      Toastr({ message: t("Fill up all required fields first !"), type: "error" });
    }

  }

  const HandleSubmit = async () => {
    // console.log("HandleSubmit is starting...!!");

    // console.log("giftCardListPagination:::", giftCardListPagination);
    let toPage = giftCardListPagination.last_page;
    if (
      giftCardListPagination.per_page * giftCardListPagination.last_page ===
      giftCardListPagination.total
    ) {
      // console.log("NEED 1 More Page !!!");
      toPage += 1;
    }
    let loadUrl = giftCardListPagination.path + "?page=" + toPage;
    console.log(loadUrl);

    if (!giftCardAddForm?.category_id) {
      setOnState(true);
    } else {
      setOnState(false);
    }

    let formOK = 1;
    giftCardArray.map((formItem) => {
      let temp = GenericValidation(formItem);
      formOK = formOK * temp
    });

    if (formOK === 1) {
      // console.log("All Forms' Data OK!!");
      setOnState(false);
      // console.log("giftCardArray:::", giftCardArray);
      addFormToGiftCardArray(
        {
          shop_id: "",
          category_id: "",
          name: "",
          price: "",
          image: "",
        }
      );
      let tempSuccess = await addGiftCard(loadUrl);
      if (tempSuccess) {
        resetGiftCardArray();
        addFormToGiftCardArray(
          {
            shop_id: "",
            category_id: "",
            name: "",
            price: "",
            image: "",
          }
        );
        setOpenedForm(0);
        setTotalForm([1]);
        setAddingNewGiftCard(false);
      }
    } else {
      setOnState(true);
      Toastr({ message: t("Fill up all required fields first !"), type: "error" });
      // console.log("HandleSubmit: Failed !");
    }
  };

  useEffect(() => {
    if (addingNewGiftCard) {
      getAllShop("", 500);
      getAllCategory();
    }
  }, [addingNewGiftCard]);

  useEffect(() => {
    // console.log("shopListAll::", shopListAll);
    let temp = [];
    shopListAll.map((item) =>
      temp.push({ id: item?.user_id, name: item?.user_data?.name })
    );
    // console.log("temp:::", temp);
    // setTempDataArray(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopListAll]);

  useEffect(() => {
    setTotalForm([1])
  }, [])

  return (
    <>
      <CommonModal
        showModal={addingNewGiftCard}
        setShowModal={setAddingNewGiftCard}
        widthClass="w-w40vw"
        modalTitle={t("Add Gift Card")}
        mainContent={
          <>
            <form onSubmit={(e) => e.preventDefault()} className="w-full">
              <div className="mx-auto w-full rounded-2xl bg-white p-0">
                <div>
                  {
                    totalForm?.map((item, index) =>
                      <FormToAddCard
                        key={index}
                        index={index}
                        formTitle={`${t("gift card")} ${index + 1}`}
                        onState={onState}
                        toggleAccordion={toggleAccordion}
                        setToggledAccordion={setToggledAccordion}
                        isOpen={openedForm}
                      />
                    )
                  }
                </div>
                <div className="pt-5 flex justify-between items-center">
                  <div className="flex space-x-5">
                    <AddBtnOutlined
                      btnLabel={t("Add New Card")}
                      type="submit"
                      onClick={() => {
                        HandleNewForm();
                      }}
                    />
                  </div>
                  <CommonButton
                    type="submit"
                    btnLabel={t("Save All")}
                    colorType="primary"
                    onClick={() => {
                      HandleSubmit();
                    }}
                  />
                </div>
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default AddGiftCard;
