import React from 'react'
import { useEffect } from 'react';
import useGiftCardStore, { deleteGiftCard } from '../../../App/Stores/GiftCardStore';
import useShopStore, { getShopGiftCard } from '../../../App/Stores/ShopStore';
import Card from '../../../Components/Card/Card';
import Pagination from '../../../Components/Pagination/Pagination';
import CommonSearchBox from '../../../Components/SearchBox/CommonSearchBox';
import DeleteGiftCard from '../../GiftCards/Popup/DeleteGiftCard';
import EditGiftCard from '../../GiftCards/Popup/EditGiftCard';

export default function ShopGiftCardTable({
    isWithAddBtn = true,
    isWithCategory = true,
    showPagination = true,
}) {
    const { shopGiftCardListAll, shopGiftCardListPagination } = useShopStore();
    const { setShowDeleteModal, searchGiftCardsKey, setSearchGiftCardsKey } = useGiftCardStore();

    useEffect(() => {
        getShopGiftCard();
    }, [])
    return (
        <div className="bg-white px-5 rounded-br10">
            <div className="flex justify-between items-center">
                <CommonSearchBox
                    value={searchGiftCardsKey}
                    onChange={(e) => {
                        setSearchGiftCardsKey(e.target.value);
                    }}
                />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-s20 mt-s30">
                {shopGiftCardListAll?.length > 0
                    ? shopGiftCardListAll?.map((card, index) => (
                        <Card key={index} data={card} />
                    ))
                    : ""}
            </div>

            <div className="flex justify-between items-center pt-5">
                {showPagination ? (
                    <div className="text-sm">
                        {shopGiftCardListPagination?.total > 0 ? (
                            <span>
                                Showing {shopGiftCardListPagination?.from} to{" "}
                                {shopGiftCardListPagination?.to}, out of{" "}
                                {shopGiftCardListPagination?.total} results.
                            </span>
                        ) : (
                            <span>No results found.</span>
                        )}
                    </div>
                ) : (
                    ""
                )}
                {shopGiftCardListPagination?.last_page > 1 ? (
                    <div className="">
                        <Pagination
                            pagesArray={shopGiftCardListPagination?.links}
                            currentPageNumber={shopGiftCardListPagination?.current_page}
                            prevPageUrl={shopGiftCardListPagination?.prev_page_url}
                            nextPageUrl={shopGiftCardListPagination?.next_page_url}
                            paginationOnClick={(url) => {
                                getShopGiftCard("", url);
                            }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <EditGiftCard />
            <DeleteGiftCard
                OnConfirmDelete={() => {                    
                    let loadUrl = shopGiftCardListPagination.path + "?page=" + shopGiftCardListPagination.current_page;
                    
                    let delSuccess = deleteGiftCard(loadUrl);
                    if (delSuccess) {
                        setShowDeleteModal(false);
                    }
                }}
            />
        </div>
    )
}
