import {
    NoImageShop, NoUser
} from "../../../Components/Utility/ImageImports";
// import OrderDetails from "../OrderDetails";
import { useEffect, useState } from "react";
import useOrderStore, { getOrderDetails } from "../../../App/Stores/OrderStore";
import { BaseUrlSrc } from "../../../App/Utility/Url";
import { formatDate } from "../../../App/Utility/UtilityFunctions";
import Image from "../../../Components/Image/Image";

export default function ShopOrderItem({ orderData = {}, barIndex, end }) {
    const { setShowOrderDetails } = useOrderStore();
    return (
        // <>
        <tr
            onClick={() => {
                getOrderDetails(orderData?.id);
                setShowOrderDetails(true);
            }}
            className={` w-full bg-white text-fs14 ${barIndex === end ? "border-b-0" : "border-b"
                }`}
        >
            <th className="px-s10 py-s10 font-fw400 flex items-center justify-start cursor-pointer border-r min-w-[220px]">
                <Image
                    className="mr-5 w-6 h-6 rounded-br5"
                    alt=""
                    src={orderData?.card_image}
                    dummyImage={NoImageShop}
                />

                <span className="flex flex-col justify-start text-left items-start min-w-[180px]">
                    <span className="font-fw500 max-w-[150px] truncate">
                        {orderData?.card_name ? orderData?.card_name : "NA"}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates natus molestiae earum enim assumenda quasi rerum repudiandae inventore! Necessitatibus molestias voluptate explicabo laborum blanditiis eius quod! Fugiat neque vero reprehenderit?\
                    </span>
                    <span>{orderData?.code ? orderData?.code : "NA"}</span>
                </span>
            </th>
            <td className="px-s10 py-s10 cursor-pointer border-r">
                <tr className="flex justify-start items-center">
                    <div className="rounded-full overflow-hidden mr-5">
                        <img
                            className="w-6 h-6 object-cover"
                            alt=""
                            src={orderData?.customer?.image_url ? BaseUrlSrc + "/" + orderData?.customer?.image_url : NoUser}
                        // src={BaseUrlSrc + data?.image}
                        />
                    </div>

                    <span className="max-w-[150px] truncate">
                        {orderData?.customer?.name ? orderData?.customer?.name : "NA"}
                    </span>
                </tr>
            </td>
            <td className="px-s10 py-s10 cursor-pointer border-r min-w-[220px]">
                <div className="text-left max-w-[200px] truncate">
                    {orderData?.customer?.email
                        ? orderData?.customer?.email
                        : "NA"}

                </div>
            </td>
            <td className="px-s10 py-s10 cursor-pointer border-r text-center min-w-[150px]">
                <span>
                    {orderData?.customer?.phone
                        ? orderData?.customer?.phone
                        : "NA"}
                </span>
            </td>

            <td className="px-s10 py-s10 cursor-pointer border-r min-w-[150px] text-center">
                <span>{orderData?.order_date ? formatDate(orderData?.order_date) : "NA"}</span>
            </td>

            <td className="flex justify-center px-s10 py-s10 min-w-[120px]">
                <OrderStatus orderStatus={orderData.status} />
            </td>
        </tr>
        // </>
    );
};


function OrderStatus({ orderStatus }) {
    const [bgColor, setBgColor] = useState("");

    useEffect(() => {
        switch (orderStatus) {
            case "completed":
                setBgColor("text-cSuccess ring-cSuccess");
                break;
            case "processing":
                setBgColor("text-cPending ring-cPending");
                break;
            case "pending":
                setBgColor("text-cPending ring-cPending");
                break;
            case "payment_due":
                setBgColor("text-cPending ring-cPending");
                break;
            case "rejected":
                setBgColor("text-cRed ring-cRed");
                break;
            case "canceled":
                setBgColor("text-cRed ring-cRed");
                break;
            default:
                setBgColor("text-cBrand ring-cBrand");
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);
    return (
        <span
            className={`flex justify-center items-center cursor-pointer ring-[1px] text-fs12 font-fw500 rounded-full py-s5 capitalize w-fit px-2 ${bgColor}`}
        >
            {orderStatus
                ? orderStatus === "payment_due"
                    ? "payment due"
                    : orderStatus
                : "NA"}
        </span>
    );
}
