/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { getAllCategory } from "../../App/Stores/CategoryStore";
import useGiftCardStore, {
  deleteGiftCard,
  getAllGiftCard,
  searchGiftCards,
} from "../../App/Stores/GiftCardStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import AddBtnOutlined from "../../Components/Button/AddBtnOutlined";
import Card from "../../Components/Card/Card";
import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import AutoPaginate from "../../Components/Pagination/AutoPaginate";
import CommonSearchBox from "../../Components/SearchBox/CommonSearchBox";
import AddGiftCard from "./Popup/AddGiftCard";
import DeleteGiftCard from "./Popup/DeleteGiftCard";
import EditGiftCard from "./Popup/EditGiftCard";

const GiftCards = ({
  isWithAddBtn = true,
  isWithCategory = true,
  showPagination = true,
}) => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    setAddingNewGiftCard,
    searchGiftCardsKey,
    setSearchGiftCardsKey,
    giftCardListAll,
    giftCardListPagination,
    selectedCategoryIDs,
    setSelectedCategoryIDs,
    setShowDeleteModal,
    gCategoryList
  } = useGiftCardStore();

  const [dataList, setDataList] = useState([]);

  async function getDataReady() {
    await getAllCategory();
    setSelectedCategoryIDs("");

    let temp = []

    temp.unshift({
      id: 0,
      name: "all category"
    })
    await gCategoryList.map((item) =>
      temp.push({ id: item.id, name: item.name })
    )
    setDataList(temp);
  }

  useEffect(() => {
    setBarTitle(t("Gift Cards"));
    PageTitle(t("Gift Cards"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataList.length <= 1) {
      getDataReady();
    }
  }, [dataList])


  // useEffect(() => {
  // console.log("paginationLinks::: ", paginationLinks.length);
  // }, [paginationLinks]);

  const [searchValue] = useDebounce(searchGiftCardsKey, 500);

  useEffect(() => {
    if (searchValue) {
      // console.log("SEARCH !!!!");
      searchGiftCards(searchValue);
    } else {
      if (giftCardListPagination.current_page) {
        let urlToLoad = giftCardListPagination.path + "?page=" + giftCardListPagination.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        getAllGiftCard("", urlToLoad);
      } else {
        getAllGiftCard();
      }
    }
  }, [searchValue]);


  return (
    <div className="">
      <div className="flex justify-between items-center">
        <CommonSearchBox
          value={searchGiftCardsKey}
          onChange={(e) => {
            setSearchGiftCardsKey(e.target.value);
          }}
        />
        <div className="flex space-x-3 items-center">
          {isWithAddBtn === true ? (
            <AddBtnOutlined
              btnLabel={t("Add Gift Card")}
              colorType="primary"
              onClick={() => setAddingNewGiftCard(true)}
            />
          ) : (
            ""
          )}
          <AddGiftCard />
          {isWithCategory === true ? (
            <div className="z-40" >
              {dataList.length ?
                <CommonDropDown
                  withToolTip={true}
                  toggleTitle={"all category"}
                  useDataObject={true}
                  dataObject={dataList}
                  itemOnClick={(e) => {
                    console.log(e);
                    setSelectedCategoryIDs(e.id);
                    //tanmoy
                    setSearchGiftCardsKey("")
                    if (e.id === 0) {
                      getAllGiftCard();
                      // navigateTo("/gift-cards")
                    } else {
                      getAllGiftCard(e.id, "");
                      // setGiftCardFilterCategory(e.id)
                      // navigateTo("/gift-cards/filter/" + e.id)
                    }
                  }}
                /> : ""}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* show all gift cards in a grid system responsive */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-s20 mt-s30">
        {giftCardListAll?.length > 0
          ? giftCardListAll?.map((card, index) => (
            <Card key={index} data={card} />
          ))
          : ""}
      </div>

      <div className="flex justify-between items-center pt-5">
        {showPagination ? (
          <div className="text-sm">
            {giftCardListPagination?.total > 0 ? (
              <span>
                {t("Showing")} {giftCardListPagination?.from} {t("to")}{" "}
                {giftCardListPagination?.to}, {t("out of")}{" "}
                {giftCardListPagination?.total} {t("results.")}
              </span>
            ) : (
              <span>{t("No results found.")}</span>
            )}
          </div>
        ) : (
          ""
        )}
        {giftCardListPagination?.last_page !== 1 ? (
          <div className="">
            <AutoPaginate
              paginationObject={giftCardListPagination}
              paginationOnClick={(url) => {
                console.log(url);
                if (selectedCategoryIDs) {
                  getAllGiftCard(selectedCategoryIDs, url);
                } else if (searchValue) {
                  searchGiftCards(searchValue, url);
                } else {
                  getAllGiftCard("", url);
                }
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <EditGiftCard />
      <DeleteGiftCard
        OnConfirmDelete={() => {
          // console.log("giftCardListPagination:::", giftCardListPagination);
          let loadUrl = giftCardListPagination.path + "?page=" + giftCardListPagination.current_page;
          // console.log(loadUrl);
          let delSuccess = deleteGiftCard(loadUrl);
          if (delSuccess) {
            setShowDeleteModal(false);
          }
        }}
      />
    </div>
  );
};

export default GiftCards;
