import { useEffect, useState } from "react";
import useOrderStore, { getOrderDetails } from "../../../App/Stores/OrderStore";
import { BaseUrlSrc } from "../../../App/Utility/Url";
import { formatDate } from "../../../App/Utility/UtilityFunctions";
import Image from "../../../Components/Image/Image";
import {
  NoImageShop, NoUser
} from "../../../Components/Utility/ImageImports";

const OrderTableItem = ({ orderData = {}, barIndex, end }) => {
  const { setShowOrderDetails } = useOrderStore();
  return (
    // <>
    <tr
      onClick={() => {
        getOrderDetails(orderData?.id);
        setShowOrderDetails(true);
      }}
      className={`hover:bg-cBandHover w-full bg-white text-fs14 ${barIndex === end ? "border-b-0" : "border-b"
        }`}
    >
      <td className="px-s10 py-s10 font-fw400 flex items-center justify-start cursor-pointer border-r min-w-[230px]">
        <Image
          className="mr-5 w-6 h-6 rounded-br5"
          alt=""
          src={orderData?.card_image}
          dummyImage={NoImageShop}
        />

        <tr className="flex flex-col justify-start text-left items-start">
          <span className="font-fw500 max-w-[200px] truncate pr-5">
            {orderData?.card_name ? orderData?.card_name : "NA"}
          </span>
          <span>{orderData?.code ? orderData?.code : "NA"}</span>
        </tr>
      </td>
      <td className="px-s10 py-s10 cursor-pointer border-r min-w-[230px]">
        <tr className="flex justify-start items-center">
          <div className="rounded-full overflow-hidden mr-5">
            <Image
              className="w-6 h-6 object-cover"
              alt=""
              src={orderData?.customer?.image_url}
              dummyImage={NoUser}
            />
          </div>

          <span className="max-w-[150px] truncate">
            {orderData?.customer?.name ? orderData?.customer?.name : "NA"}
          </span>
        </tr>
      </td>
      <td className="px-s10 py-s10 cursor-pointer border-r min-w-[220px]">
        <div className="text-left max-w-[200px] truncate">
          {orderData?.post_card_with_shop
            ? orderData?.post_card_with_shop?.shop.email
            : "NA"}
        </div>
      </td>
      <td className="px-s10 py-s10 cursor-pointer border-r min-w-[150px]">
        <div className="flex justify-center items-center">
          <span>
            {orderData?.post_card_with_shop?.shop?.phone
              ? orderData?.post_card_with_shop?.shop?.phone
              : "NA"}
          </span>
        </div>
      </td>

      <td className="px-s10 py-s10 cursor-pointer border-r min-w-[150px]">
        <div className="text-center space-x-2">
          <span>{orderData?.order_date ? formatDate(orderData?.order_date) : "NA"}</span>
        </div>
      </td>

      <td className="flex justify-center px-s10 py-s10 min-w-[150px]">
        <OrderStatus orderStatus={orderData.status} />
      </td>
    </tr>
    // </>
  );
};

export default OrderTableItem;

function OrderStatus({ orderStatus }) {
  const [bgColor, setBgColor] = useState("");

  useEffect(() => {
    switch (orderStatus) {
      case "completed":
        setBgColor("text-cSuccess ring-cSuccess");
        break;
      case "processing":
        setBgColor("text-cPending ring-cPending");
        break;
      case "pending":
        setBgColor("text-cPending ring-cPending");
        break;
      case "payment_due":
        setBgColor("text-cPending ring-cPending");
        break;
      case "rejected":
        setBgColor("text-cRed ring-cRed");
        break;
      case "canceled":
        setBgColor("text-cRed ring-cRed");
        break;
      default:
        setBgColor("text-cBrand ring-cBrand");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus]);
  return (
    <span
      className={`flex justify-center items-center cursor-pointer ring-[1px] text-fs12 font-fw500 rounded-full py-s5 capitalize w-fit px-2 ${bgColor}`}
    >
      {orderStatus
        ? orderStatus === "payment_due"
          ? "payment due"
          : orderStatus
        : "NA"}
    </span>
  );
}
