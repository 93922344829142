import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { handleUserLogout } from '../../App/Stores/authStore'
import useLayoutStore from '../../App/Stores/LayoutStore'
import useSettingsStore, { updateProfileData } from '../../App/Stores/SettingsStore'
import UseTestStore from '../../App/Stores/TestStore'
import OrderDetails from '../../Pages/Order/OrderDetails'
import VoucherLimitModal from '../../Pages/Voucher/Modals/VoucherLimitModal'
import CommonButton from '../Button/CommonButton'
import CommonInput from '../Input/CommonInput'
import ImageUpload from '../Input/ImageUpload'
import CommonModal from '../Modal/CommonModal'

export default function OverlayModalArea() {
    const { t } = useTranslation();

    // blue: store list
    const { showLogoutModal, setShowLogoutModal } = useLayoutStore()
    const { userData, setUserData, setDataWithImage } = useSettingsStore()
    const { showTestModal, setShowTestModal } = UseTestStore();

    const navigate = useNavigate()

    return (
        <>
            {/* blue: voucher */}
            <VoucherLimitModal />

            {/* green: order */}
            <OrderDetails />

            {/* green: <EditAccount /> */}
            <CommonModal
                showModal={showTestModal}
                setShowModal={setShowTestModal}
                modalTitle={t("Edit Profile")}
                mainContent={
                    <>
                        <form onSubmit={(e) => e.preventDefault()} className="pt-5 bg-cWhite">
                            <div className="flex justify-center items-center pb-5">
                                <div className="w-full">
                                    <ImageUpload
                                        imgValue={userData?.image_url ? (userData?.image_url) : ""}
                                        customWidth="w-s150"
                                        onChangeFile={(e) => {
                                            console.log("image", e);
                                            let tempImage = {
                                                target: {
                                                    name: "image_url",
                                                    value: e,
                                                }
                                            }
                                            setDataWithImage(true)
                                            setUserData(tempImage)
                                        }}
                                    />
                                </div>
                            </div>

                            <CommonInput required={true} value={userData?.name} name={"name"} label={t("name")} onChange={setUserData} />
                            <CommonInput required={true} value={userData?.email} name={"email"} label={t("email")} onChange={setUserData} />
                            <CommonInput required={true} value={userData?.phone} name={"phone"} label={t("phone")} onChange={setUserData} />

                            <div className="pt-5 flex justify-center">
                                <CommonButton btnLabel={t("save changes")} type="submit"
                                    onClick={() => {
                                        if (
                                            userData?.image_url &&
                                            userData?.name &&
                                            userData?.email &&
                                            userData?.phone
                                        ) {
                                            let updateSuccess = updateProfileData();
                                            if (updateSuccess) {
                                                setShowTestModal(false)
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </form>
                    </>
                }
            />

            {/* blue: log out modal */}
            <CommonModal
                showModal={showLogoutModal}
                setShowModal={setShowLogoutModal}
                widthClass="w-w40vw"
                modalTitle={t("Log Out")}
                mainContent={
                    <div className="flex justify-center py-5">
                        <div>{t("Do you want to Log out?")}</div>
                    </div>
                }
                primaryActionButton={
                    <>
                        <CommonButton
                            onClick={async () => {
                                await handleUserLogout()
                                navigate('/login')
                            }}
                            btnLabel={t("Yes")}
                            colorType="danger"
                        />
                    </>
                }
                secondaryActionButton={
                    <>
                        <CommonButton btnLabel={t("No")} colorType="basic" />
                    </>
                }
            />
        </>
    )
}
