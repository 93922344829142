import React from 'react'
import UseNotificationStore from '../../App/Stores/NotificationStore'
import { formatDate } from '../../App/Utility/UtilityFunctions';
import CommonModal from '../../Components/Modal/CommonModal'

const NotificationDetailsModal = () => {
    const { showNotificationDetails, setShowNotificationDetails, notificationDetails } = UseNotificationStore();
    return (
        <CommonModal
            showModal={showNotificationDetails}
            setShowModal={setShowNotificationDetails}
            modalTitle="Notification Details"
            mainContent={
                <div onClick={()=>console.log(notificationDetails)}>
                    <div className="flex items-center justify-between py-5">
                        <div className="text-base font-semibold">
                            {notificationDetails?.title ?? "NA"}
                        </div>
                        <div className="text-fs12 font-normal text-cTextSecondaryBody">
                            {notificationDetails?.created_at ? formatDate(notificationDetails?.created_at) : "NA"}
                        </div>
                    </div>

                    <div className="text-cTextSecondaryBody">
                        {notificationDetails?.description ?? "No Description Found"}
                    </div>
                </div >
            }
        />
    )
}

export default NotificationDetailsModal