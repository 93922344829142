import CommonTable from "../../../Components/Table/CommonTable";
import useDashboardStore from "../../../App/Stores/DashboardStore";
import TopSellingTableItem from "./TopSellingTableItem";

const TopSellingTable = ({ title, headers = [] }) => {
  const { topSellingCards } = useDashboardStore();

  return (
    <>
      <CommonTable
        shoSearchBox={false}
        tableTitle={title}
        headers={headers}
        pagination={false}
        items={topSellingCards?.map((item, index) => (
          <TopSellingTableItem
            key={index}
            barIndex={index + 1}
            tableData={item}
          />
        ))}
      />
    </>
  );
};

export default TopSellingTable;
