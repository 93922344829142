import React from "react";
import useRoleStore from "../../../App/Stores/RoleStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonDropDown from "../../../Components/DropDown/CommonDropDown";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonLabel from "../../../Components/Label/CommonLabel";
import CommonModal from "../../../Components/Modal/CommonModal";

export default function AddAdmin() {
  const { showAddAdmin, setShowAddAdmin } = useRoleStore();
  return (
    <>
      <CommonModal
        showModal={showAddAdmin}
        setShowModal={setShowAddAdmin}
        modalTitle="add new admin"
        singleButton={
          <>
            <CommonButton btnLabel="save" />
          </>
        }
        mainContent={
          <>
            <AddAdminForm />
          </>
        }
      />
    </>
  );
}

function AddAdminForm() {
  return (
    <>
      <div className="pt-10 flex justify-between items-center">
        <div className="w-full">
          <CommonInput
            className="mb-0"
            label={"first name"}
            placeholder="Enter first name"
          />
        </div>
        <div className="px-s20"></div>
        <div className="w-full">
          <CommonInput
            className="mb-0"
            label={"last name"}
            placeholder="Enter last name"
          />
        </div>
      </div>

      <div className="pt-5 flex justify-between items-center">
        <div className="w-full">
          <CommonInput
            className="mb-0"
            label={"email"}
            placeholder="Enter email"
          />
        </div>
        <div className="px-s20"></div>
        <div className="w-full">
          <CommonInput
            className="mb-0"
            label={"password"}
            placeholder="Enter password"
          />
        </div>
      </div>

      <div className="pt-5 pb-10 flex justify-between items-center">
        <div className="w-full">
          <CommonInput
            className="mb-0"
            label={"phone"}
            placeholder="Enter phone"
          />
        </div>

        <div className="px-s20"></div>

        <div className="w-full">
          <CommonLabel labelText="select role" />
          <div className="pt-s16"></div>
          <CommonDropDown
            toggleTitle="select role"
            placeholderTextShow={true}
            basicColor={true}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
}
