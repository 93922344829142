import React from 'react';

const ChipArray = ({ item, name, onClick, width = "w-[200px]" }) => {
    return (
        <div onClick={onClick}>
            <div className={`px-s20 mr-s20 capitalize h-s36 flex items-center justify-center rounded-full cursor-pointer select-none text-fs16 font-fw600 ${item ? "bg-cBrand text-white" : "bg-white text-cTextBlack border border-cChipBorder hover:bg-cBrand hover:border-cBrand hover:text-white"} ${width}`}>
                {name}
            </div>
        </div>
    );
};

export default ChipArray;