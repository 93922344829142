import React from 'react'
import CommonTable from '../../Components/Table/CommonTable'

function InvoiceReceipt({
    refToPrint,

    invoice_id = "1651162115615",
    order_id = "1651162115615",
    purchase_id = "1651616111651",

    date = "20 November 2022, 17:30",
    shop_image = "123",
    shop_name = "Amazing Shop",
    phone = "123654987",
    address = "99, somewhere in, Japan",

    from_name = "Cervelo",
    from_email = "crv@email.com",
    from_address = "105, can not find, ME",
    from_phone = "9955447788",

    to_name = "Trek",
    to_email = "trk@email.com",
    to_address = "25/A, hidden place not to, FIND",
    to_phone = "1122334455",

    order_item_name = "item name",
    order_shop = "shop name",
    order_size = "item size",
    order_qty = "item qty",
    order_price = "item price",
    order_price_total = "total price",

    order_price_auth_fee = "authority fee",
    order_price_discount = "discount price",
    order_price_shipping = "shipping price",
    order_grand_total = "grand total price",

}) {

    return (
        <div
            ref={refToPrint}
            className={`p-5 w-full bg-[#ffffff]`}>

            {/*l title */}
            <div className="mb-5 text-2xl font-semibold text-center">Invoice Details: {invoice_id}</div>

            {/*e top bar */}
            <div className="flex justify-between items-center text-fs12">
                <div>Gifty Purchase Summary: {purchase_id}</div>
                <div>{date}</div>
            </div>

            {/* e   bar top */}
            <div style={{ backgroundColor: '#BDBDBD' }} className="mt-5 w-full h-[35px] text-fs16 font-semibold flex items-center pl-s10">
                Seller Center
            </div>

            {/*e shop name and details */}
            <div className="flex items-center mt-5">
                <div className="overflow-hidden rounded-full bg-[#ffffff] w-s80 h-s80">
                    {/* <Image
                        // src2={{shop_image}}
                        src2={{ uri: (BaseUrlSrc + "/" + shop_image), method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                        dummyImage={ShopSelected}
                        className="object-cover w-full h-full"
                        alt='shop-dp'
                        /> */}
                    <img src={shop_image} alt="" />
                </div>

                <div className="flex flex-col pl-5 space-y-s5">
                    <div className="font-semibold text-fs14">{shop_name}</div>
                    <div className="font-light text-fs12">Tel: {phone}</div>
                    <div className="font-light text-fs12">Address: {address}</div>
                </div>
            </div>

            <div className="w-full bg-[#BDBDBD] h-s2 my-s15"></div>

            {/*e  from ... to */}
            <div className="flex justify-between">
                <div className="w-full">
                    <div className="font-semibold text-fs14">From:</div>
                    <div className="text-fs12">Name: {from_name}</div>
                    <div className="text-fs12">Email: {from_email}</div>
                    <div className="text-fs12">Address: {from_address}</div>
                    <div className="text-fs12">Phone: {from_phone}</div>
                </div>

                <div className="w-full">
                    <div className="font-semibold text-fs14">To:</div>
                    <div className="text-fs12">Name: {to_name}</div>
                    <div className="text-fs12">Address: {to_address}</div>
                    <div className="text-fs12">Address Type: {to_email}</div>
                    <div className="text-fs12">Phone: {to_phone}</div>
                </div>
            </div>

            <div className="w-full bg-[#BDBDBD] h-s2 my-s15"></div>

            {/*e   ordered items ... table */}
            <div className="flex justify-between">
                <div className="font-semibold text-fs16">Ordered Items</div>

                <div className="font-semibold text-fs16">Order ID: {order_id}</div>
            </div>
            <CommonTable
                roundedBorderOuter={false}
                withOuterShadow={false}
                headers={[
                    { index: 1, name: "#" },
                    { index: 2, name: "Product Name" },
                    { index: 3, name: "Shop Name" },
                    // { index: 4, name: "Size" },
                    { index: 5, name: "Qty" },
                    { index: 6, name: "Price" },
                    { index: 7, name: "Total Price" },
                ]}
                showPagination={false}
                shoSearchBox={false}
                pagination={false}
                showPageCountText={false}
                outerPadding="p-0"
                items={
                    <>
                        <InvoiceReceiptRow
                            order_item_name={order_item_name}
                            order_shop={order_shop}
                            order_size={order_size}
                            order_qty={order_qty}
                            order_price={order_price}
                            order_price_total={order_price_total}
                        />
                    </>
                }
            />

            <div className="flex flex-row-reverse mt-5 text-fs14">
                <div className="border border-collapse">

                    <div className="flex max-w-[300px] text-right border-b text-[#767676]">
                        <div className="px-s10 py-s5 w-[220px] border-r">Authority Fee</div>
                        <div className="px-s10 py-s5 w-[220px]">¥ {order_price_auth_fee}</div>
                    </div>

                    <div className="flex max-w-[300px] text-right border-b text-[#767676]">
                        <div className="px-s10 py-s5 w-[220px] border-r">Shipping Cost</div>
                        <div className="px-s10 py-s5 w-[220px]">¥ {order_price_shipping}</div>
                    </div>

                    <div className="flex max-w-[300px] text-right border-b text-[#767676]">
                        <div className="px-s10 py-s5 w-[220px] border-r">Discount</div>
                        <div className="px-s10 py-s5 w-[220px]">¥ {order_price_discount}</div>
                    </div>

                    <div className="flex max-w-[300px] text-right ">
                        <div className="px-s10 py-s5 w-[220px] border-r">Total</div>
                        <div className="px-s10 py-s5 w-[220px]">¥ {order_grand_total}</div>
                    </div>

                </div>
            </div>

        </div>
    )
}
export default InvoiceReceipt


function InvoiceReceiptRow({
    order_item_name = "item name",
    order_shop = "shop name",
    order_size = "item size",
    order_qty = "item qty",
    order_price = "item price",
    order_price_total = "total price",
}) {
    return (
        <tr className="text-[#767676] text-fs14">
            <td className="py-s10 text-center border">1</td>
            <td className="py-s10 text-center border">{order_item_name}</td>
            <td className="py-s10 text-center border">{order_shop}</td>            
            <td className="py-s10 text-center border">{order_qty}</td>
            <td className="py-s10 text-center border">{order_price}</td>
            <td className="py-s10 text-center border">{order_price_total}</td>
        </tr>
    )
}

