import React from "react";
import { useNavigate } from "react-router-dom";
import AddBtnOutlined from "../../Components/Button/AddBtnOutlined";
import { VoucherLogo } from "../../Components/Utility/ImageImports";

export default function NoVoucher() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-h80vh bg-white rounded-br5 flex flex-col justify-center items-center relative">
      <img src={VoucherLogo} alt="" className="absolute bottom-20 right-0" />
      <div className="text-fs24 font-fw600 text-cTextSecondaryBody">
        You haven’t created any Voucher
      </div>
      <div className="pt-s10">
        <AddBtnOutlined
          onClick={() => navigate("/Voucher/add-Voucher")}
          btnLabel="create new admin"
        />
      </div>
    </div>
  );
}
