import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerificationHistoryTableItem from '../../Pages/VerifyIdentity/IdentityVerifyDetails/VerificationHistoryTableItem';
import CommonTable from './CommonTable';


const VerificationHistory = () => {

    const { t } = useTranslation();

    const [history, seHistory] = useState(true)

    const headers = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Verification Method") },
        { index: 3, name: t("Applying Date") },
        { index: 4, name: t("Last Action") },
        { index: 5, name: t("Status") },

    ];

    return (
        <div>
            {history ? <CommonTable
                headers={headers}
                shoSearchBox={false}
                pagination={false}
                secondaryPaddingBottom=""
                items={
                    <>
                        <VerificationHistoryTableItem />
                        <VerificationHistoryTableItem />
                        <VerificationHistoryTableItem />
                        <VerificationHistoryTableItem />
                        <VerificationHistoryTableItem />
                    </>
                }
            /> : <span className='text-fs24 font-fw600 h-s200 flex justify-center mt-s100'>No History yet!</span>}
        </div>
    );
};

export default VerificationHistory;