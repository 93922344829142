import React, { useEffect, useState } from "react";

const CommonButtonOutlined = ({
  btnLabel = "Trek",
  colorType = "basic",
  isDisabled = false,
  CustomStyles,
  onClick,
  width,
}) => {
  // colorType="basic", "danger", "primary", "success", "warning"
  const [colorCode, setColorCode] = useState("ring-cPlaceholder");
  const [textColorCode, setTextColorCode] = useState("text-white");
  const [hoverColorCode, setHoverColorCode] = useState("text-cPlaceholder");

  useEffect(() => {
    switch (colorType) {
      case "basic":
        setColorCode("ring-cPlaceholder");
        setHoverColorCode("hover:bg-cPlaceholder");
        setTextColorCode("text-cPlaceholder");
        break;
      case "danger":
        setColorCode("ring-cRed");
        setHoverColorCode("hover:bg-cRed");
        setTextColorCode("text-cRed");
        break;
      case "primary":
        setColorCode("ring-cBrand");
        setHoverColorCode("hover:bg-cBrand");
        setTextColorCode("text-cBrand");
        break;
      case "success":
        setColorCode("ring-cSuccess");
        setHoverColorCode("hover:bg-cSuccess");
        setTextColorCode("text-cSuccess");
        break;
      case "warning":
        setColorCode("ring-cPending");
        setHoverColorCode("hover:bg-cPending");
        setTextColorCode("text-cPending");
        break;
      default:
        setColorCode("ring-cPlaceholder");
        setHoverColorCode("hover:bg-cPlaceholder");
        setTextColorCode("text-cPlaceholder");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorType]);
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center rounded-br5 select-none ring-2 hover:text-white duration-300 font-fw600 text-fs14 h-s36 capitalize
        w-[140px]
        ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
        ${CustomStyles}
        ${colorCode}
        ${textColorCode}
        ${hoverColorCode}
        ${width} 
        `}
    >
      {btnLabel}
    </div>
  );
};

export default CommonButtonOutlined;
