import React from 'react'
import useDashboardStore from '../../../App/Stores/DashboardStore';
import CommonTable from '../../../Components/Table/CommonTable';
import RecentOrderTableItem from './RecentOrderTableItem';

export default function ShowRecentOrderTable({ title, headers = [] }) {
  const { recentOrderData } = useDashboardStore();

  return (
    <>
        <CommonTable
        shoSearchBox={false}
        tableTitle={title}
        headers={headers}
        showSearchBox={false}
        pagination={true}
        items={recentOrderData?.map((item, index) => (
          <RecentOrderTableItem
            key={index}
            barIndex={index + 1}
            tableData={item}
          />
        ))}
      />
    </>
  )
}
