import app_logo from '../../Images/logo/pc_app_logo.png'
import app_logo_sm from '../../Images/logo/pc_app_logo_sm.png'
import gifty_brand from '../../Images/logo/gifty-LQ.png'
import about_us from '../../Images/icon/about-us.svg'

export const GIFTY_LOGO = gifty_brand
export const iAPP_LOGO = app_logo
export const iAPP_LOGO_SM = app_logo_sm
export const iAboutUs = about_us

