import React, { useEffect, useState } from "react";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import useGiftCardStore, {
  updateGiftCard,
} from "../../../App/Stores/GiftCardStore";
import CommonDropDown from "../../../Components/DropDown/CommonDropDown";
import CommonLabel from "../../../Components/Label/CommonLabel";
import useShopStore, { getAllShop } from "../../../App/Stores/ShopStore";
import ComboBox from "../../../Components/Input/ComboBox";
import useCategoryStore from "../../../App/Stores/CategoryStore";
import ImageUpload from "../../../Components/Input/ImageUpload";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import { useTranslation } from "react-i18next";

const EditGiftCard = () => {
  const { t } = useTranslation();

  const {
    showEditModal,
    setShowEditModal,
    giftCardDetails,
    setGiftCardUpdateForm,
    giftCardUpdateForm,
    giftCardListPagination,
    setFinalUpdateCardData,
    finalUpdateCardData,
  } = useGiftCardStore();
  const { shopListAll } = useShopStore();
  const { categoryListAll } = useCategoryStore();

  const [tempDataArray, setTempDataArray] = useState([]);

  const FillEmptyForm = () => {

    let tmpCatID = {
      target: {
        name: "category_id",
        value: giftCardDetails.category_id,
      },
    };
    setGiftCardUpdateForm(tmpCatID);

    let tmpCode = {
      target: {
        name: "code",
        value: giftCardDetails.code,
      },
    };
    setGiftCardUpdateForm(tmpCode);

    let tmpCardID = {
      target: {
        name: "id",
        value: giftCardDetails.id,
      },
    };
    setGiftCardUpdateForm(tmpCardID);
    setFinalUpdateCardData({ ...finalUpdateCardData, "id": giftCardDetails.id })


    let tmpCardName = {
      target: {
        name: "name",
        value: giftCardDetails.name,
      },
    };
    setGiftCardUpdateForm(tmpCardName);

    let tmpCardPrice = {
      target: {
        name: "price",
        value: giftCardDetails.price,
      },
    };
    setGiftCardUpdateForm(tmpCardPrice);

    let tmpShopID = {
      target: {
        name: "shop_id",
        value: giftCardDetails.shop_id,
      },
    };
    setGiftCardUpdateForm(tmpShopID);

    let tmpCardImage = {
      target: {
        name: "image",
        value: giftCardDetails.image,
      },
    };
    setGiftCardUpdateForm(tmpCardImage);

  };

  const HandleSubmit = async () => {
    // FillEmptyForm();
    setFinalUpdateCardData({ ...finalUpdateCardData, "id": giftCardDetails.id });
    if (
      giftCardUpdateForm?.name &&
      giftCardUpdateForm?.shop_id &&
      giftCardUpdateForm?.price &&
      giftCardUpdateForm?.image &&
      giftCardUpdateForm?.category_id
    ) {
      // console.log("All validation PASS !!")
      // console.log("giftCardUpdateForm:::", giftCardUpdateForm);

      let loadUrl =
        giftCardListPagination.path +
        "?page=" +
        giftCardListPagination.current_page;

      // console.log("giftCardListPagination:::", giftCardListPagination);
      // console.log("loadUrl::", loadUrl);
      //orange: api calling..
      let doneUpdate = updateGiftCard(loadUrl);
      if (doneUpdate) {
        setShowEditModal(false);
      }
    } else {
      Toastr({ message: t("Fill up all fields !"), type: "error" });
    }
  };

  useEffect(() => {
    if (shopListAll.length < 1) {
      // console.log("FOUND EMPTY,  CALLED getAllShop();");
      getAllShop();
    }
    let temp = [];
    shopListAll.map((item) =>
      temp.push({ id: item?.user_id, name: item?.user_data?.name })
    );
    // console.log("temp:::", temp);
    setTempDataArray(temp);
  }, [shopListAll]);

  useEffect(() => {
    if (showEditModal) {
      FillEmptyForm();
      // console.log("EDIT MODAL RENDER !!");
      // console.log("shopListAll::", shopListAll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditModal]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditModal]);

  return (
    <>
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        widthClass="w-w40vw"
        modalTitle={t("Edit Gift Card")}
        mainContent={
          <>
            <div className="pt-5 bg-cWhite">
              <form onSubmit={(e) => e.preventDefault()}>
                <CommonInput
                  required={true}
                  label={t("name")}
                  name="name"
                  value={giftCardUpdateForm?.name}
                  onChange={(e) => {
                    setGiftCardUpdateForm(e);
                    setFinalUpdateCardData({ ...finalUpdateCardData, "name": e.target.value })
                  }}
                />

                <div className="flex justify-between py-s20">
                  <div className="w-full">
                    <CommonLabel labelText={t("Shop")} required={true} />
                    <div className="pt-s16"></div>

                    <ComboBox
                      required={true}
                      placeholder={"Start typing Shop name"}
                      data={tempDataArray ?? tempDataArray}
                      value={giftCardDetails?.shop?.name}
                      onSelectItem={(e) => {
                        console.log(e);
                        let tmp = {
                          target: {
                            name: "shop_id",
                            value: e.id,
                          },
                        };
                        setGiftCardUpdateForm(tmp);
                        setFinalUpdateCardData({ ...finalUpdateCardData, "shop_id": e.id })
                      }}
                    />
                  </div>
                  <div className="p-s20"></div>
                  <div className="w-full">
                    <CommonLabel labelText={t("Category")} required={true} />
                    <div className="pt-s16"></div>

                    <CommonDropDown
                      required={true}
                      placeholderTextShow={true}
                      basicColor={true}
                      fullWidth={true}
                      toggleTitle={t("Select category")}
                      value={giftCardDetails?.category?.name}
                      useDataObject={true}
                      dataObject={categoryListAll}
                      itemOnClick={(e) => {
                        console.log(e);
                        let tmp = {
                          target: {
                            name: "category_id",
                            value: e.id,
                          },
                        };
                        setGiftCardUpdateForm(tmp);
                        setFinalUpdateCardData({ ...finalUpdateCardData, "category_id": e.id })
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="w-full">
                    <CommonInput
                      required={true}
                      label={t("Code No")}
                      name={"code"}
                      value={giftCardUpdateForm?.code}
                      onChange={(e) => {
                        setGiftCardUpdateForm(e);
                        setFinalUpdateCardData({ ...finalUpdateCardData, "code": e.target.value })
                      }
                      }
                    />
                  </div>
                  <div className="p-s10"></div>
                  <div className="w-full">
                    <CommonInput
                      required={true}
                      label={t("Price")}
                      name={"price"}
                      value={giftCardUpdateForm?.price}
                      onChange={(e) => {
                        setGiftCardUpdateForm(e)
                        setFinalUpdateCardData({ ...finalUpdateCardData, "price": e.target.value })
                      }}
                    />
                  </div>
                </div>

                <div className="w-full pb-5">
                  <ImageUpload
                    btnLabel={t("change image")}
                    imgValue={giftCardUpdateForm?.image}
                    customWidth="w-s150"
                    onChangeFile={(e) => {
                      let tmp = {
                        target: {
                          name: "image",
                          value: e,
                        },
                      };
                      setGiftCardUpdateForm(tmp);
                      setFinalUpdateCardData({ ...finalUpdateCardData, "image": e })
                    }
                    }
                  />
                </div>
                <div className="pt-5 flex justify-center">
                  <CommonButton
                    type="submit"
                    btnLabel={t("save changes")}
                    colorType="primary"
                    onClick={() => HandleSubmit()}
                  />
                </div>
              </form>
            </div>
          </>
        }
      />
    </>
  );
};

export default EditGiftCard;
