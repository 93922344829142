import CommonTable from "../../../Components/Table/CommonTable";
import CategoryTableItem from "./CategoryTableItem";
import useCategoryStore, { searchCategoryList } from "../../../App/Stores/CategoryStore";
import AddCategory from "../Popup/AddCategory";
import DeleteCategory from "../Popup/DeleteCategory";
import EditCategory from "../Popup/EditCategory";
import DeactivateCategory from "../Popup/DeactivateCategory";

const CategoryTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewCategory,
    searchKey,
    categoryListAll,
    categoryListPagination,
  } = useCategoryStore();

  return (
    <>
      <DeleteCategory />
      <EditCategory />
      <DeactivateCategory />
      <CommonTable
        headers={headers}
        addItemTitle={tableBtnTitle}
        showingFrom={1}
        showingTo={categoryListAll.length}
        totalResult={categoryListAll.length}
        items={
          categoryListAll.length > 0
            ? categoryListAll.map((item, index) => (
              <CategoryTableItem
                key={index}
                barIndex={index}
                end={categoryListAll.length - 1}
                tableData={item}
              />
            ))
            : ""
        }
        addItemModal={
          <>
            <AddCategory />
          </>
        }
        addItemFunction={setAddingNewCategory}
        searchValue={searchKey}
        searchOnChange={(e) => searchCategoryList(e)}
        pagination={false}
        pagesArray={categoryListPagination.links}
        currentPageNumber={categoryListPagination.current_page}
      />
    </>
  );
};

export default CategoryTable;
