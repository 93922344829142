import React from 'react'
import { useNavigate } from 'react-router-dom';
import useVoucherStore from '../../../App/Stores/VoucherStore';
import CommonModal from '../../../Components/Modal/CommonModal';

const VoucherLimitModal = ({
    data = ["John", "James", "Mike", "Travor", "Franklin", "Ron"]
}) => {
    const { showVoucherLimit, setShowVoucherLimit } = useVoucherStore();
    return (
        <>
            <CommonModal
                showModal={showVoucherLimit}
                setShowModal={setShowVoucherLimit}
                modalTitle="Limit Details"
                mainContent={
                    <div className='space-y-s10'>
                        {data.map((item, index) =>
                            <VoucherUsers key={index} titleText={item} />
                        )}
                    </div>
                }

            />
        </>
    )
}

const VoucherUsers = ({ titleText = "", id=2 }) => {
    const { navigateTo } = useNavigate();
    const handleOnClick = () => {
        localStorage.setItem("customerID", id);
        navigateTo()
    }
    return (
        <div onClick={handleOnClick} className='bg-cGridView p-s10 rounded-br5 cursor-pointer hover:bg-cSelectedBar'>{titleText}</div>
    )
}

export default VoucherLimitModal