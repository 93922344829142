import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useBoostedStore, {
  addNewBoostedShop,
} from "../../../../App/Stores/BoostedStore";
import useShopStore, { getAllShop } from "../../../../App/Stores/ShopStore";
import CommonButton from "../../../../Components/Button/CommonButton";
import ComboBox from "../../../../Components/Input/ComboBox";
import CommonInput from "../../../../Components/Input/CommonInput";
import CommonLabel from "../../../../Components/Label/CommonLabel";
import CommonModal from "../../../../Components/Modal/CommonModal";

export default function AddBoostShop() {
  const { t } = useTranslation();

  const { showAddBoostedShop, setShowAddBoostedShop } = useBoostedStore();

  return (
    <>
      <CommonModal
        showModal={showAddBoostedShop}
        setShowModal={setShowAddBoostedShop}
        modalTitle={t("Add Boosted shop")}
        mainContent={
          <>
            <BoostShopAddForm />
          </>
        }
      />
    </>
  );
}

function BoostShopAddForm() {
  const { t } = useTranslation();

  const {
    addBoostedShopForm,
    boostedShopList,
    setAddBoostedShopForm,
    setShowAddBoostedShop,
    boostedShopListPagination,
  } = useBoostedStore();
  const { shopListAll } = useShopStore();
  const [tempDataArray, setTempDataArray] = useState([]);

  const HandleSubmit = () => {
    // console.log("boostedShopListPagination:::", boostedShopListPagination);
    if (
      addBoostedShopForm.shop_id &&
      addBoostedShopForm.start_date &&
      addBoostedShopForm.end_date
    ) {
      // console.log("addBoostedShopForm:::", addBoostedShopForm);
      let loadUrl = "";
      if (boostedShopListPagination.to === boostedShopListPagination.per_page) {
        let p_num = boostedShopListPagination.current_page+1
        loadUrl = boostedShopListPagination.path + "?page=" +p_num;
      } else {
        loadUrl = boostedShopListPagination.last_page_url;
      }
      // console.log("loadUrl::::", loadUrl);
      let addASuccess = addNewBoostedShop(loadUrl);
      if (addASuccess) {
        setShowAddBoostedShop(false);
      }
    }
  };

  useEffect(() => {
    getAllShop("", 500, true);
    // console.log("shopListAll::", shopListAll);
    // console.log("boostedShopList::", boostedShopList);

    // let b_list_id = boostedShopList.map((item) => item.shop_id);
    // console.log("b_list_id:::", b_list_id);
    // let filtered_shop_list = shopListAll.filter((s_item) =>
    //   b_list_id.includes(s_item.user_id) ? "" : s_item
    // );
    // console.log("filtered_shop_list:::", filtered_shop_list);

    let temp = [];
    shopListAll.map((item) =>
      temp.push({ id: item?.user_id, name: item?.user_data?.name })
    );
    // console.log("temp:::", temp);
    setTempDataArray(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="bg-white rounded-br5 w-full"
      >
        <div className="pt-5 pb-s15">
          <CommonLabel labelText={t("Select Shop")} required={true} />
        </div>
        <div className="relative">
          <ComboBox
            noDataMessage={t("No shop available to select.")}
            required={true}
            placeholder={t("Start typing Shop name")}
            data={tempDataArray ?? tempDataArray}
            onSelectItem={(e) => {
              console.log(e);
              let tmp = {
                target: {
                  name: "shop_id",
                  value: e.id,
                },
              };
              setAddBoostedShopForm(tmp);
            }}
          />
        </div>

        <div className="flex items-center justify-between pt-5">
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Boost Start Date")}
              required={true}
              name="start_date"
              type="date"
              value={addBoostedShopForm?.start_date}
              onChange={(e) => {
                if (e.target.value > addBoostedShopForm?.end_date) {
                  let tmp = {
                    target: {
                      name: "end_date",
                      value: "",
                    },
                  };
                  setAddBoostedShopForm(tmp);
                }

                let startDate = {
                  target: {
                    name: "start_date",
                    value: e.target.value,
                  },
                };
                setAddBoostedShopForm(startDate);
              }}
              placeholder={t("Select start date")}
            />
          </div>
          <div className="pr-s20"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Boost End Date")}
              required={true}
              disabled={addBoostedShopForm?.start_date ? false : true}
              startDate={addBoostedShopForm?.start_date}
              name="end_date"
              type="date"
              value={addBoostedShopForm?.end_date}
              onChange={setAddBoostedShopForm}
              placeholder={t("Select expiry date")}
            />
          </div>
        </div>

        <div className="pt-10 flex justify-center">
          <CommonButton
            width={"w-s200"}
            type={"submit"}
            btnLabel={t("Add Boosted Shop")}
            colorType="primary"
            onClick={() => HandleSubmit()}
          />
        </div>
      </form>
    </div>
  );
}
