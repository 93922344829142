import React, { useEffect } from 'react'
import useLayoutStore from '../../App/Stores/LayoutStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';

export default function Logs() {
    const { setBarTitle } = useLayoutStore();
  
    useEffect(() => {
      setBarTitle("Logs");
      PageTitle("Logs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <div>Logs</div>
  )
}
