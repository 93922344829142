import React, { useEffect, useState } from "react";

const CommonButton = ({
  btnLabel = "Trek",
  colorType = "primary",
  isDisabled = false,
  customStyle,
  onClick,
  width = "w-[140px]",
  type = 'button',
}) => {
  // colorType="basic", "danger", "primary", "success", "warning"
  const [colorCode, setColorCode] = useState("bg-cPlaceholder");
  const [textColorCode, setTextColorCode] = useState("text-white");

  const colorCheckup = (colorType) => {
    switch (colorType) {
      case "basic":
        setColorCode("bg-cPlaceholder");
        setTextColorCode("text-cTextBlack");
        break;
      case "danger":
        setColorCode("bg-cRed");
        setTextColorCode("text-white");
        break;
      case "primary":
        setColorCode("bg-cBrand hover:bg-cBrandDark");
        setTextColorCode("text-white");
        break;
      case "success":
        setColorCode("bg-cSuccess");
        setTextColorCode("text-white");
        break;
      case "warning":
        setColorCode("bg-cPending");
        setTextColorCode("text-white");
        break;
      default:
        setColorCode("bg-cPlaceholder");
        setTextColorCode("text-cTextBlack");
        break;
    }
  };

  useEffect(() => {
    colorCheckup(colorType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorType]);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex items-center justify-center rounded-br5 select-none h-s36 font-fw600 text-fs14 capitalize duration-300   
      ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
      ${customStyle}
      ${colorCode}
      ${textColorCode}
      ${width}       
      `}
    >
      {btnLabel}
    </button>
  );
};

export default CommonButton;
