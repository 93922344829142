import React from "react";
import useContactStore from "../../App/Stores/ContactStore";
import { formatDate } from "../../App/Utility/UtilityFunctions";

export default function ContactTableRow({ data, index, end }) {
  const { setShowContactMessageDetails } = useContactStore();

  return (
    <tr
      onClick={() => {
        console.log(data);
        setShowContactMessageDetails(true);
      }}
      className="cursor-pointer border-b border-collapse bg-white hover:bg-cBandHover"
    >
      <td className="border-r text-left pl-s5 py-s10 min-w-[220px]">{data?.name ?? "NA"}</td>      
      <td className="border-r text-left pl-s5 py-s10 min-w-[250px]">{data?.email ?? "NA"}</td>      
      <td className="border-r text-left pl-s5 py-s10 min-w-[300px]">{data?.message ?? "NA"}</td>
      <td className="border-r text-center py-s10 min-w-[150px]">{data?.created_at ? formatDate(data?.created_at) : "NA"}</td>
      <td className="border-r text-center py-s10 min-w-[150px]">{data?.updated_at ? formatDate(data?.updated_at) : "NA"}</td>      
    </tr>
  );
}
