import React from "react";
import { MuiCustomSwitch } from "../../../App/Stores/UtilityStore";
import { DeleteIcon, EditIcon } from "../../../Components/Utility/ImageImports";

export default function AdminTableItem() {
  return (
    <>
      <tr className="text-fs14 border-b">
        <td className="text-center border-r py-5">1987654621 </td>
        <td className="text-center border-r py-5">2</td>
        <td className="text-center border-r py-5">3</td>
        <td className="text-center border-r py-5">4</td>
        <td className="text-center border-r py-5">5</td>
        <td className="text-center">
          <tr className="flex space-x-3 items-center justify-center ">
            <td
              onClick={() => {
                // setShowEditModal(true);
              }}
            >
              <img src={EditIcon} alt="" className="cursor-pointer" />
            </td>

            <td
              onClick={() => {
                // setShowDeleteModal(true);
              }}
            >
              <img
                // onClick={() => setShowDeleteRole(true)}
                src={DeleteIcon}
                alt=""
                className="w-[17px] h-[17px] ml-5 mr-2 cursor-pointer"
              />
            </td>

            <td className="cursor-pointer">
              <MuiCustomSwitch
                color="secondary"
                // checked={true}
                onClick={() => { }}
                // onChange={(e) => {}}
                inputProps={{ "aria-label": "controlled" }}
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}
