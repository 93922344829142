import React from "react";
import CommonModal from "../../../Components/Modal/CommonModal";
import CommonButton from "../../../Components/Button/CommonButton";
import useCategoryStore, { deleteCategory } from "../../../App/Stores/CategoryStore";
import { useTranslation } from "react-i18next";

const DeleteCategory = () => {
  const { t } = useTranslation();

  const { showDeleteModal, setShowDeleteModal } = useCategoryStore();
  
  return (
    <>
      <CommonModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        widthClass="w-w40vw"
        mainContent={
          <div className="flex justify-center items-center py-5 mb-5">
            <div className="text-fs16">
              {t("Are you sure you want to delete this category?")}
            </div>
          </div>
        }
        modalTitle={t("Delete Category")}
        primaryActionButton={
          <>
            <CommonButton
              onClick={() => { deleteCategory() }}
              colorType="danger"
              btnLabel={t("Delete")}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              onClick={() => setShowDeleteModal(false)}
              btnLabel={t("Cancel")}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteCategory;
