import React from "react";
import Image from "../../../Components/Image/Image";
import GiftCardImage from "../../../Images/dummy-img/giftcard1.jpeg";

export default function RecentOrderTableItem({ tableData = {}, barIndex }) {
  return (
    <>
      <tr className={`bg-white shadow-cShopItem border-collapse border-b select-none cursor-pointer`}>
        <td
          onClick={() => { }}
          className="px-6 text-left font-fw400 py-4 flex justify-start items-center"
        >
          <Image
            dummyImage={GiftCardImage}
            className="w-8 h-6 rounded-br5"
            alt=""
            src={tableData?.card_image}
          />
          <span className="pl-5 max-w-[200px] truncate">
            {tableData?.card_name ? tableData?.card_name : "NA"}
          </span>

        </td>

        <CommonTD showData={tableData?.quantity ?? 0} minW="min-w-[100px]" />
        <CommonTD showData={tableData?.customer_name ?? "NA"} />
        <CommonTD showData={tableData?.payment_method ?? "NA"} minW="capitalize" />
        <CommonTD showData={tableData?.phone} />
        <CommonTD showData={tableData?.address} minW="min-w-[200px]" />
        <CommonTD showData={tableData?.price} />
      </tr>
    </>
  );
}

const CommonTD = ({ showData, minW = "min-w-[180px]" }) => {
  return (
    <td
      onClick={() => { }}
      className={`px-6 text-center py-4 border-collapse border max-w-[180px] truncate ${minW}`}
    >
      {showData ? showData : "NA"}
    </td>
  );
};
