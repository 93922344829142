/* eslint-disable react-hooks/exhaustive-deps */
import CustomerTableItem from "./CustomerTableItem";
import CommonTable from "../../../Components/Table/CommonTable";
import useCustomerStore, { getAllCustomer, searchCustomerList } from "../../../App/Stores/CustomerStore";
import AddCustomer from "../Popup/AddCustomer";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";

const CustomerTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewCustomer,
    searchCustomerKey,
    customerListAll,
    customerListPagination,
    searchKey,
    setSearchCustomerKey,
    setCustomerPaginationUrl,
    customerPaginationUrl
  } = useCustomerStore();

  const [searchValue] = useDebounce(searchCustomerKey, 500);  

  useEffect(() => {
    if (searchValue) {
      searchCustomerList(searchValue,customerPaginationUrl);
    } else {
      if (customerListPagination.current_page) {
        let urlToLoad = customerListPagination.path + "?page=" + customerListPagination.current_page;        
        getAllCustomer(urlToLoad);
      } else {
        
        getAllCustomer(customerPaginationUrl);
      }
    }
  }, [searchValue]);

  return (
    <>
      <CommonTable
        headers={headers}
        addItemTitle={tableBtnTitle}
        showingFrom={customerListPagination.from}
        showingTo={customerListPagination.to}
        totalResult={customerListPagination.total}
        items={
          customerListAll.length > 0
            ? customerListAll.map((item, index) => (
              <CustomerTableItem
                key={index}
                barIndex={index}
                end={customerListAll.length - 1}
                tableData={item}
              />
            ))
            : ""
        }
        addItemModal={
          <>
            <AddCustomer />
          </>
        }
        addItemFunction={setAddingNewCustomer}
        searchValue={searchCustomerKey}
        searchOnChange={(e) => setSearchCustomerKey(e.target.value)}
        paginationObject={customerListPagination}
        pagesArray={customerListPagination.links}
        currentPageNumber={customerListPagination.current_page}
        prevPageUrl={customerListPagination.prev_page_url}
        nextPageUrl={customerListPagination.next_page_url}
        showPagination={customerListPagination?.last_page !== 1 ? true : false}
        paginationOnClick={(url) => {
          setCustomerPaginationUrl(url)
          if(searchValue){
            searchCustomerList(searchValue,url)
          }else getAllCustomer(url);
        }}
      />
    </>
  );
};

export default CustomerTable;
