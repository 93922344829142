import create from "zustand";
import axios from "axios"
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from './UtilityStore';
import { addFaqUrl, deleteFaqUrl, getAllFaqListUrl, updateFaqUrl } from '../Utility/Url';

const { setLoading } = useUtilityStore.getState();

const useFaqStore = create((set) => ({

  faqListAll: [],
  setFaqListAll: (value) => set({ faqListAll: value }),

  faqFormData: { title: "", description: "", },
  setFaqFormData: (name, value) => set((state) => state.faqFormData[name] = value),
  resetFaqFormData: () => set((state) => (state.faqFormData = { title: "", description: "" })),

  deleteFaqForm: { id: "", title: "", description: "", },
  setDeleteFaqForm: (value) => set((state) => (state.deleteFaqForm["id"] = value)),

  editForm: { title: "", description: "", },
  changeEditForm: (name, value) => set((state) => state.editForm[name] = value),
  resetEditForm: () => set((state) => (state.faqFormData = { title: "", description: "" })),

  faqUpdateData: {},
  setFaqUpdateData: (value) => set({ faqUpdateData: value }),

  showEditAccordion: false,
  setShowEditAccordion: (value) => set({ showEditAccordion: value }),

  showDeleteShopBoost: false,
  setShowDeleteShopBoost: (value) => set({ showDeleteShopBoost: value }),

  faqListAllTemp: [],
  setFaqListAllTemp: (value) => set({ faqListAllTemp: value }),

  addingNewFaq: false,
  setAddingNewFaq: (value) => set({ addingNewFaq: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  searchKey: "",
  setSearchKey: (value) => set({ searchKey: value }),
}));

// Get All FAQ
export const getAllFaq = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getAllFaqListUrl);

    console.log("getALlFaq response data: ", res.data);
    // return

    if (res.data.success) {
      useFaqStore.getState().setFaqListAll(res.data.data);

      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }

    setLoading(false);
  } catch (error) {
    console.log("getAllFaq Error: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Add FAQ
export const saveNewFAQ = async () => {
  try {
    setLoading(true);
    const res = await axios.post(addFaqUrl, useFaqStore.getState().faqFormData);

    if (res.data.success) {
      useFaqStore.getState().resetFaqFormData();
      getAllFaq();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("saveNewFaq: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Delete Faq
export const deleteFaq = async () => {
  try {
    setLoading(true);
    const res = await axios.post(deleteFaqUrl, useFaqStore.getState().deleteFaqForm);

    if (res.data.success) {
      // Toastr({ message: res.data.message, type: "success" });
      getAllFaq();
      useFaqStore.getState().setShowEditAccordion(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("deleteFaq: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// Update FAQ
export const updateFaq = async ({ faqId }) => {
  try {
    if (!faqId) {
      Toastr({ message: "Invalid Faq ID!", type: "error" });
      return
    }

    const { setFaqListAll, editForm, resetEditForm } = useFaqStore.getState()
    console.log('Faq id ', faqId);

    editForm.id = faqId
    console.log('form data: ', editForm);
    // return

    setLoading(true);
    const res = await axios.post(updateFaqUrl, editForm)
    console.log('updateFaq data: ', res.data);

    if (res.data.success) {
      setFaqListAll(res.data.data)
      resetEditForm()
      getAllFaq()
      useFaqStore.getState().setShowEditAccordion(false);
      // Toastr({ message: res.data.message, type: 'success' })
    } else {
      Toastr({ message: res.data.message, type: 'error' })
    }
    setLoading(false)

  } catch (error) {
    console.log('updateFaq: ', error);
    Toastr({ message: 'An error occurred!', type: 'error' })
    setLoading(false)
  }
}

// Search
// Search Category
export const searchFaqList = (event) => {
  useFaqStore.getState().setSearchKey(event.target.value);

  const result = useFaqStore.getState().faqListAllTemp.filter((item) => {
    if (item) {
      let name = "";
      name = item.name ?? "";

      if (
        name.toLowerCase().includes(event.target.value.toLowerCase())
      ) {
        return item;
      } else {
        return null;
      }
    }
  });
  console.log("search result: ", result);
  useFaqStore.getState().setFaqListAll(result);
};

export default useFaqStore;
