import React, { useState } from 'react';
import VerificationHistory from '../../../Components/Table/VerificationHistoryTable';
import IdentityVerifyDetails from './IdentityVerifyDetails';


const IdentityVerificationHistory = () => {

    return (
        <IdentityVerifyDetails>
            <div className="pt-s20 px-1">
                <hr />
            </div>
            <VerificationHistory />
        </IdentityVerifyDetails>
    );
};

export default IdentityVerificationHistory;