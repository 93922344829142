import React from "react";
import useSettingsStore, { changePassword } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const ChangePassword = () => {
  const { changePasswordForm, setChangePasswordForm, } = useSettingsStore()

  const handleSubmit = (e) => {
    e.preventDefault()
    changePassword()
  }


  return (
    <div className="w-full">
      {/* <div className="text-fs20 font-fw600 pb-s16">Change Password</div> */}
      <div className="bg-white rounded-br10 p-5 ">

        <form onSubmit={handleSubmit}>
          <div>
            <CommonInput name={'old_password'} className="mb-0" label={"Current Password"} type="password" placeholder={"Type Current Password"} togglePasswordBtn={true} value={changePasswordForm.old_password} onChange={setChangePasswordForm} required={true} min_input={6} />
          </div>

          <div className="py-5">
            <CommonInput name={'password'} className="mb-0" label={"New Password"} type="password" placeholder={"Type new Password"} togglePasswordBtn={true} value={changePasswordForm.password} onChange={setChangePasswordForm} required={true} min_input={6} />
          </div>

          <div>
            <CommonInput name={'password_confirmation'} className="mb-0" label={"Confirm New Password"} type="password" placeholder={"Type again new Password"} togglePasswordBtn={true} value={changePasswordForm.password_confirmation} onChange={setChangePasswordForm} required={true} min_input={6} />
          </div>


          <div className="flex justify-center pt-10">
            <CommonButton type="submit" btnLabel="Change Passwords" colorType="primary" width={"w-s200"} />
          </div>

        </form>

      </div>
    </div>
  );
};

export default ChangePassword;
