import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useVerifyIdentityStore, { fetchVerifyRequestData } from '../../../App/Stores/VerifyIdentityStore';
import Image from '../../../Components/Image/Image';
import BackLink from '../../../Components/Pagination/BackLink';
import { NotVerified, VerificationPending, Verified } from '../../../Components/Utility/ImageImports';

const IdentityVerifyDetails = (props) => {

    const { t } = useTranslation();

    const { verificationDetails } = useVerifyIdentityStore()

    useEffect(() => {
        fetchDetailData()
        window.scrollTo(0, 0);
    }, [])

    const fetchDetailData = async () => {
        await fetchVerifyRequestData()
    }

    //const verificationDetails = JSON.parse(localStorage.getItem('verificationDetails'))

    //console.log("verificationDetails", verificationDetails)

    return (
        <div>
            <BackLink linksArray={[
                { label: t("Verify Identity "), linkTo: `/verify/identity` },
                { label: t("Verify Identity details"), linkTo: "" },
            ]} />

            <div className='bg-cWhite rounded-br5 p-5'>
                <div className='flex items-center'>
                    <div className='flex flex-col justify-center items-center space-y-s10'>
                        <Image className='w-s100 h-s100 rounded-full' src={verificationDetails?.image_url} alt="" />
                        <div className=''>
                            {verificationDetails?.status === 'verified' &&
                                <div className='flex items-center space-x-2'>
                                    <img className='' src={Verified} alt="" />
                                    <span>Verified</span>
                                </div>}
                            {verificationDetails?.status === 'not_verified' &&
                                <div className='flex items-center space-x-2'>
                                    <img className='' src={VerificationPending} alt="" />
                                    <span>Verification Pending</span>
                                </div>
                            }
                            {verificationDetails?.status === 'pending' &&
                                <div className='flex items-center space-x-2'>
                                    <img className='' src={NotVerified} alt="" />
                                    <span>Not Verified</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='ml-s20 mt-s10'>
                        <div><span className='text-cIconColor font-fw500 text-fs14'>Name : </span><span className='text-cTextShopDetails font-fw500 text-fs14'>{verificationDetails?.name}</span></div>
                        <div className='my-s8'><span className='text-cIconColor font-fw500 text-fs14'>Email : </span> <span className='text-cTextShopDetails font-fw500 text-fs14'>{verificationDetails?.email}</span></div>
                        <div><span className='text-cIconColor font-fw500 text-fs14'>Phone : </span> <span className='text-cTextShopDetails font-fw500 text-fs14'>{verificationDetails?.phone ? verificationDetails?.phone : 'NA'}</span></div>
                    </div>
                </div>
            </div>

            <div className='bg-cWhite px-5 pb-5 pt-s10 rounded-br5 mt-s20 bg-cyan-0'>
                {/* <div className="flex">
                    <ChipArray
                        onClick={() => { navigate(`/verify/identity/details`) }}
                        item={location.pathname === `/verify/identity/details`
                            && true} name="Current Request"
                    />
                    <ChipArray
                        onClick={() => { navigate(`/verify/identity/details/history`) }}
                        item={location.pathname === `/verify/identity/details/history` && true}
                        name="Verification History" />
                </div> */}
                {props.children}
            </div>
        </div>
    );
};

export default IdentityVerifyDetails;