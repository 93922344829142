import axios from "axios";
import create from "zustand";
import { getNotificationIndexUrl, getNotificationShowUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const UseNotificationStore = create((set) => ({

    //   showTestModal: false,
    //   setShowTestModal: (value) => set({ showTestModal: value }),

    notificationTakeAmount: 10,
    setNotificationTakeAmount: (value) => set({ notificationTakeAmount: value }),

    showNotificationLoading: false,
    setShowNotificationLoading: (value) => set({ showNotificationLoading: value }),

    showNotificationDetails: false,
    setShowNotificationDetails: (value) => set({ showNotificationDetails: value }),

    notificationIndex: {},
    setNotificationIndex: (value) => set({ notificationIndex: value }),

    notificationDetails: {},
    setNotificationDetails: (value) => set({ notificationDetails: value }),

}));

export default UseNotificationStore;



/* 
    API Functions
 */

// green: get all notification list
export const getNotificationIndex = async (paginationUrl = "", isDropdown = false) => {
    const { setNotificationIndex, setShowNotificationLoading, notificationTakeAmount } = UseNotificationStore.getState();

    try {
        console.log("paginationUrl::: ", paginationUrl, " take::: ", notificationTakeAmount);
        if (isDropdown) setShowNotificationLoading(true);
        else setLoading(true);

        let targetUrl = paginationUrl === "" ? (getNotificationIndexUrl + "?take=" + notificationTakeAmount) : paginationUrl + "&take=" + notificationTakeAmount;

        const res = await axios.get(targetUrl);


        console.log("getNotificationIndex res.data:::: ", res.data.data);

        if (res.data.success) {
            setNotificationIndex(res.data.data.notifications);
        } else {
            Toastr({ message: res.data.message, type: "error" });
        }
        setShowNotificationLoading(false);
        setLoading(false);
    } catch (error) {
        console.log("getNotificationIndex: ", error);
        Toastr({ message: "An error occurred!", type: "error" });
        setShowNotificationLoading(false);
        setLoading(false);
    }
};

// g    show notification detail    
export const getNotificationDetails = async (notificationID = 0) => {
    const { setNotificationDetails } = UseNotificationStore.getState();

    try {
        setLoading(true);

        const res = await axios.post(getNotificationShowUrl, { id: notificationID });

        console.log("getNotificationDetails res.data:::: ", res.data);
        // return

        if (res.data.success) {
            setNotificationDetails(res.data.data);
            await getNotificationIndex();
        } else {
            Toastr({ message: res.data.message, type: "error" });
        }
        setLoading(false);
    } catch (error) {
        console.log("getNotificationDetails: ", error);
        Toastr({ message: "An error occurred!", type: "error" });
        setLoading(false);
    }
};