import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useVerifyIdentityStore, { fetchVerifyRequestData, verifyRequestStatusIndex } from '../../../App/Stores/VerifyIdentityStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';

const VerificationModal = () => {

    const { verificationConfirmationModal, setVerificationConfirmationModal, verificationDetails,setVerifyIdentityStatus } = useVerifyIdentityStore()

    const { t } = useTranslation();

    const navigate = useNavigate()

    useEffect(() => {
        fetchDetailData()
    }, [])

    const fetchDetailData = async () => {
        await fetchVerifyRequestData()
    }
    //const verificationDetails = JSON.parse(localStorage.getItem('verificationDetails'))

    return (
        <div>
            <CommonModal
                showModal={verificationConfirmationModal}
                setShowModal={setVerificationConfirmationModal}
                modalTitle={t("Confirmation")}
                mainContent={
                    <>
                        <div className="pt-5 pb-[30px] flex justify-center items-center">
                            <div>You are confirming that the information of the customer have been matched with the system. Once you confirm a customer’s successful verification, you can never change it again.</div>
                        </div>
                        <div className="flex justify-center">
                            <CommonButton
                                onClick={() => {
                                    let body = {}
                                    body = { "user_id": verificationDetails?.user_id, "status": "verified" }
                                    const successfulStatus = verifyRequestStatusIndex(body)
                                    if (successfulStatus) {
                                        setVerificationConfirmationModal(false)
                                        setVerifyIdentityStatus('history')
                                        navigate("/verify/identity")
                                    }
                                }}
                                width="w-[200px]"
                                btnLabel="Verification successfully"
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default VerificationModal;