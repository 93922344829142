import React from 'react';
import VerificationHistory from '../../../Components/Table/VerificationHistoryTable';
import CustomerDetails from '../Details/CustomerDetails';

const CustomerVerificationHistory = () => {
    return (
        <CustomerDetails>
            <VerificationHistory/>
        </CustomerDetails>
    );
};

export default CustomerVerificationHistory;