import React from 'react'
import { useTranslation } from 'react-i18next';
import useFaqStore, { updateFaq } from '../../../../App/Stores/FaqStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonButtonOutlined from '../../../../Components/Button/CommonButtonOutlined';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function EditAccordionData() {
    const { t } = useTranslation();

    const {
        showEditAccordion,
        setShowEditAccordion,
        setShowDeleteModal,
        setDeleteFaqForm,
        editForm,
        changeEditForm,
        faqUpdateData
    } = useFaqStore();

    const handleChangeValue = (e) => changeEditForm(e.target.name, e.target.value)

    const onEditForm = () => {
        updateFaq({ faqId: faqUpdateData?.id })
        console.log('on Edit ', faqUpdateData?.id);
    }

    return (
        <>
            <CommonModal
                showModal={showEditAccordion}
                setShowModal={setShowEditAccordion}
                widthClass="w-w40vw"
                modalTitle={t("Edit FAQ")}
                mainContent={
                    <div className="pt-5 ">
                        <CommonInput
                            label={t("FAQ from admin")}
                            value={editForm.title}
                            name={"title"}
                            required={true}
                            onChange={handleChangeValue}
                        />
                        <CommonInput
                            textarea={true}
                            rows={5}
                            label={t("Answer")}
                            value={editForm.description}
                            name={"description"}
                            required={true}
                            onChange={handleChangeValue}
                        />
                        <div className='flex justify-between space-x-5'>
                            <CommonButtonOutlined
                                onClick={() => {
                                    setShowDeleteModal(true);
                                    console.log('del id', faqUpdateData?.id);
                                    setDeleteFaqForm(faqUpdateData?.id)
                                }
                                }
                                colorType="danger"
                                btnLabel={t("Delete")}
                            />
                            <CommonButton
                                onClick={() => onEditForm()}
                                colorType="primary"
                                btnLabel={t("Save Changes")}
                            />
                        </div>
                    </div>

                }
            />
        </>
    );
};
