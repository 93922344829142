import React from "react";
import useUtilityStore from '../../App/Stores/UtilityStore';
import CommonButton from '../../Components/Button/CommonButton';
import CommonInput from '../../Components/Input/CommonInput';
import ProfileImageUpload from '../../Components/Input/ProfileImageUpload';
import CommonLabel from '../../Components/Label/CommonLabel';

export default function AccountSettings() {
  const { loggedUser, setShowEditModal } = useUtilityStore();

  return (
    <>
      <div className="py-s20 bg-cWhite px-s20 rounded-br10 shadow-sm w-3/4 mx-auto">
        
        <div className="flex justify-center items-center pb-[25px]">
          <div>            
            <ProfileImageUpload />
          </div>
        </div>

        <CommonLabel labelText="Name"/>
        <CommonInput value={loggedUser.name}/>
        <CommonLabel labelText="Email"/>
        <CommonInput value={loggedUser.email}/>
        <CommonLabel labelText="Phone Number"/>
        <CommonInput value={loggedUser.phone ? loggedUser.phone : "Not Found"}/>

        <CommonButton
          btnLabel="Save Changes"
          colorType="primary"
        />
          
      </div>
    </>
  )
}
