import axios from "axios";
// import { useNavigate } from "react-router-dom";
import create from "zustand";
import {
  addNewShopUrl,
  deleteShopUrl,
  getAllGiftCardsUrl,
  getAllShopListUrl,
  getShopDetailsUrl,
  searchAllShopListUrl,
  updateShopUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();
// const currentLocation = window.location.pathname;

const useShopStore = create((set) => ({
  shopFormData: {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    address: "",
    details: "",
    image: "",
  },
  setShopFormData: (e) =>
    set((state) => (state.shopFormData[e.target.name] = e.target.value)),
  resetShopFormData: () =>
    set(
      (state) =>
      (state.shopFormData = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        address: "",
        details: "",
        image: "",
      })
    ),

  deleteShopForm: {
    user_id: "",
  },
  setDeleteShopForm: (value) =>
    set((state) => (state.deleteShopForm["user_id"] = value)),

  deactivateShopForm: {
    user_id: -1,
  },
  setDeactivateShopForm: (value) =>
    set((state) => (state.deactivateShopForm["user_id"] = value)),

  shopUpdateFormData: {
    user_id: "",
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    details: "",
  },
  setShopUpdateFormData: ({ name, value }) =>
    set((state) => (state.shopUpdateFormData[name] = value)),
  resetShopUpdateFormData: () =>
    set(
      (state) =>
      (state.shopUpdateFormData = {
        user_id: "",
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        details: "",
      })
    ),

  selectedShopID: 0,
  setSelectedShopID: (value) => set({ selectedShopID: value }),

  shopDetails: {},
  setShopDetails: (value) => set({ shopDetails: value }),

  shopListAll: [],
  setShopListAll: (value) => set({ shopListAll: value }),

  shopListPagination: {},
  setShopListPagination: (value) => set({ shopListPagination: value }),

  shopGiftCardListAll: {},
  setShopGiftCardListAll: (value) => set({ shopGiftCardListAll: value }),

  shopGiftCardListPagination: {},
  setShopGiftCardListPagination: (value) => set({ shopGiftCardListPagination: value }),

  shopListAllTemp: [],
  setShopListAllTemp: (value) => set({ shopListAllTemp: value }),

  couponListAll: [],
  setCouponListAll: (value) => set({ couponListAll: value }),

  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  successDeleteShop: false,
  setSuccessDeleteShop: (value) => set({ successDeleteShop: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  showDeactivateModal: false,
  setShowDeactivateModal: (value) => set({ showDeactivateModal: value }),

  showShopDetails: false,
  setShowShopDetails: (value) => set({ showShopDetails: value }),

  addingNewShop: false,
  setAddingNewShop: (value) => set({ addingNewShop: value }),

  dataBoxSelection: 1,
  setDataBoxSelection: (value) => set({ dataBoxSelection: value }),

  searchKey: "",
  setSearchKey: (value) => set({ searchKey: value }),
}));

/* 
    API Functions
 */

// green: get all shop list
export const getAllShop = async (paginationUrl = "", take = 10, is_boosted = false) => {
  try {
    setLoading(true);

    let targetUrl = "";

    if (paginationUrl !== "") {
      // targetUrl = paginationUrl.indexOf("?");
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getAllShopListUrl + targetUrl;
    } else {
      targetUrl = getAllShopListUrl;
    }
    // console.log("targetUrl:::", targetUrl);
    // console.log("SHOP TAKE::::", take);

    let res = {}

    if (is_boosted === true) {
      res = await axios.get(targetUrl, {
        params: {
          is_boosted: is_boosted,
          take: take,
        }
      });
    } else {

      res = await axios.get(targetUrl, {
        params: {
          // is_boosted: is_boosted,
          take: take,
        }
      });
    }


    console.log("getALlShop res.data:::: ", res.data.data);

    if (res.data.success) {
      useShopStore.getState().setShopListAll(res.data.data.data);
      useShopStore.getState().setShopListAllTemp(res.data.data.data);
      useShopStore.getState().setShopListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// yellow: search sop list
export const searchShops = async (searchValue,url="") => {
   console.log("searchCustomerKeysearchCustomerKey")
  const { setShopListAll, setShopListAllTemp, setShopListPagination } = useShopStore.getState();
  try {
    setLoading(true);
    console.log("searchValue::", searchValue);
    const res = await axios.get(url===""?searchAllShopListUrl:url, {
      params: {
        take: 10,
        search: searchValue,
      },
    });

    console.log("searchShops::: res.data", res.data);

    if (res.data.success) {
      setShopListAll(res.data.data.data);
      setShopListAllTemp(res.data.data.data);
      setShopListPagination(res.data.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchShops: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("searchShops: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// green: get shop details
export const getShopDetails = async (id) => {
  console.log("shop details id:: ", id);
  try {
    setLoading(true);

    const res = await axios.post(getShopDetailsUrl, {
      user_id: id,
    });

    console.log("getShopDetails res.data:::: ", res.data.data);

    if (res.data.success) {
      useShopStore.getState().setShopDetails(res.data.data);

      useShopStore.getState().setShopUpdateFormData({
        name: "user_id",
        value: id,
      });

      useShopStore.getState().setShopUpdateFormData({
        name: "name",
        value: res?.data?.data.shop.name,
      });

      useShopStore.getState().setShopUpdateFormData({
        name: "email",
        value: res?.data?.data.shop.email,
      });

      useShopStore.getState().setShopUpdateFormData({
        name: "phone",
        value: res?.data?.data.shop.phone,
      });

      useShopStore.getState().setShopUpdateFormData({
        name: "address",
        value: res?.data?.data.shop.address,
      });

      useShopStore.getState().setShopUpdateFormData({
        name: "details",
        value: res?.data?.data.shop.shop.details,
      });

      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getShopDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// blue: save / add new shop
export const saveNewShop = async (urlToLoad = "url...") => {
  console.log("shopFormData::::", useShopStore.getState().shopFormData);
  try {
    setLoading(true);

    const res = await axios.post(
      addNewShopUrl,
      useShopStore.getState().shopFormData
    );

    console.log("shopFormData::::", res.data);

    if (res.data.success) {
      useShopStore.getState().resetShopFormData();
      // Toastr({ message: res.data.message, type: "success" });
      getAllShop(urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("saveNewShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// green: update a shop
export const updateShop = async (updateShopData = {}) => {
  const body = updateShopData;
  const { shopListPagination } = useShopStore.getState();

  try {
    setLoading(true);

    console.log("updateShopData:::", updateShopData);
    const res = await axios.post(updateShopUrl, { ...body, user_id: useShopStore.getState().selectedShopID });

    console.log(res.data);

    if (res.data.success) {
      useShopStore.getState().resetShopUpdateFormData();
      getShopDetails(useShopStore.getState().selectedShopID);
      if (shopListPagination.current_page) {
        let urlToLoad = shopListPagination.path + "?page=" + shopListPagination.current_page;
        console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        getAllShop(urlToLoad);
      } else {
        getAllShop();
      }
      // getAllShop();
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("saveNewShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// yellow: toggle activate/deactivate a shop
export const toggleShopActivation = async (
  activeStatusToSet,
  urlToLoad = "url..."
) => {
  const body = {
    user_id: useShopStore.getState().deactivateShopForm.user_id,
    is_active: activeStatusToSet,
  };

  try {
    setLoading(true);

    console.log("toggle body:::", body);

    const res = await axios.post(updateShopUrl, body);

    console.log(res.data);

    if (res.data.success) {
      // if (window.location.pathname === "/shops/details") {
      //   getShopDetails(body.user_id);
      // } else {
      getAllShop(urlToLoad);
      // }
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("saveNewShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// orange: delete shop
export const deleteShop = async () => {
  try {
    setLoading(true);

    const res = await axios.post(
      deleteShopUrl,
      useShopStore.getState().deleteShopForm
    );
    if (res.data.success) {
      // Toastr({ message: res.data.message, type: "success" });

      getAllShop();

      console.log("Success Delete");
      useShopStore.getState().setSuccessDeleteShop(true);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// blue: get all coupon / vouchers
export const getAllCoupon = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getAllCoupon);

    console.log("getAllCoupon res.data:::: ", res.data);

    if (res.data.success) {
      useShopStore.getState().setCouponListAll(res.data.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllCoupon: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

export default useShopStore;


// green: get all gift card list
export const getShopGiftCard = async (categoriesIDs, paginationUrl = "") => {
  try {
    // console.log(categoriesIDs);
    setLoading(true);
    const shopID = localStorage.getItem("shopID");
    let res;
    let targetUrl = "";

    if (paginationUrl !== "") {
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getAllGiftCardsUrl + targetUrl;
      console.log("targetUrl:::", targetUrl);
    } else {
      targetUrl = getAllGiftCardsUrl;
    }

    if (categoriesIDs) {
      res = await axios.get(targetUrl, {
        params: { take: 10, categories: [categoriesIDs] },
      });
    } else {
      res = await axios.get(targetUrl, {
        params: { take: 10, shop_id: shopID },
      });
    }

    console.log("getShopGiftCard res.data:::: ", res.data);

    if (res.data.success) {
      useShopStore.getState().setShopGiftCardListAll(res.data.data.data);
      useShopStore.getState().setShopGiftCardListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getShopGiftCard: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};