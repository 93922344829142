import React from "react";

export default function CommonLabel({
  labelText = "some label...",
  required = false,
}) {
  return (
    <div className="capitalize text-fs16 font-fw500 text-cInputLabel">
      {labelText}
      {required ? <span className="text-cRed"> *</span> : ""}
    </div>
  );
}
