import CommonTable from "../../../Components/Table/CommonTable";
import useDashboardStore from "../../../App/Stores/DashboardStore";
import RecentOrderTableItem from "./RecentOrderTableItem";
import { useTranslation } from "react-i18next";

const RecentOrderTable = ({ title, headers = [] }) => {
  const { t } = useTranslation();

  const { recentOrders } = useDashboardStore();

  return (
    <>
      <CommonTable
        shoSearchBox={false}
        tableTitle={title}
        seeAllText={t("See all")}
        seeAllLink={"/orders"}
        headers={headers}
        showSearchBox={false}
        pagination={false}
        items={recentOrders?.map((item, index) => (
          <RecentOrderTableItem
            key={index}
            barIndex={index + 1}
            tableData={item}
          />
        ))}
      />
    </>
  );
};

export default RecentOrderTable;
