import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useCategoryStore from '../../../App/Stores/CategoryStore';
import useGiftCardStore from '../../../App/Stores/GiftCardStore';
import useShopStore from '../../../App/Stores/ShopStore';
import GroupAccordion from '../../../Components/Accordion/GroupAccordion';
import CommonDropDown from '../../../Components/DropDown/CommonDropDown';
import ComboBox from '../../../Components/Input/ComboBox';
import CommonInput from '../../../Components/Input/CommonInput';
import ImageUpload from '../../../Components/Input/ImageUpload';
import CommonLabel from '../../../Components/Label/CommonLabel';

export default function FormToAddCard({ onState, isOpen, formTitle, index, toggleAccordion, setToggledAccordion }) {
    const { t } = useTranslation();

    const [tempDataArray, setTempDataArray] = useState([]);

    const { giftCardArray, setDataToForm } = useGiftCardStore();
    const { shopListAll } = useShopStore();
    const { categoryListAll } = useCategoryStore();

    useEffect(() => {
        // console.log("shopListAll::", shopListAll);
        let temp = [];
        shopListAll.map((item) =>
            temp.push({ id: item?.user_id, name: item?.user_data?.name })
        );
        // console.log("temp:::", temp);
        setTempDataArray(temp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopListAll]);
    return (
        <div className='py-s10'>
            <div className="w-full">
                <GroupAccordion
                    accordion_type="dependent"
                    isInitOpen={isOpen === index}
                    setForceOpenClose={isOpen}
                    customOnClick={() => setToggledAccordion(index)}
                    header={formTitle}
                    body={
                        <div className="bg-cWhite pt-s10 pb-5" >
                            <div>
                                <CommonInput
                                    label={t("Card Name")}
                                    name={"name"}
                                    required={true}
                                    value={giftCardArray[index]?.name}
                                    onChange={(e) => setDataToForm("name", e.target.value, index)}
                                    placeholder={t("Give a name")}
                                />
                                <div className="w-full">
                                    <CommonLabel
                                        labelText={t("Select Shop")}
                                        required={true}
                                    />
                                    <div className="pt-s16"></div>
                                    <ComboBox
                                        placeholder={t("Start typing Shop name")}
                                        data={tempDataArray ?? tempDataArray}
                                        onSelectItem={(e) => {
                                            console.log(e);
                                            // let tmp = {
                                            //     target: {
                                            //         name: "shop_id",
                                            //         value: e.id,
                                            //     },
                                            // };
                                            // setGiftCardAddForm(tmp);
                                            setDataToForm("shop_id", e.id, index)
                                        }}
                                        name="type"
                                        required={true}
                                        value={giftCardArray[index]?.type}
                                    />
                                </div>
                                <div className="flex items-center py-5 w-full">
                                    <div className="w-full">
                                        <CommonInput
                                            label={t("add price")}
                                            name="price"
                                            type='number'
                                            min_number={1}
                                            required={true}
                                            value={giftCardArray[index]?.price}                                            
                                            onChange={(e) => setDataToForm("price", e.target.value, index)}
                                            placeholder={"20"}
                                            className="mb-0"
                                        />
                                    </div>
                                    <div className="pr-s20"></div>
                                    <div className="w-full flex flex-col justify-center">
                                        <CommonLabel
                                            labelText={t("Select Category")}
                                            required={true}
                                        />
                                        <div className="pt-s15"></div>
                                        <CommonDropDown
                                            fullWidth={true}
                                            basicColor={true}
                                            toggleTitle={"Category"}
                                            useDataObject={true}
                                            dataObject={categoryListAll}
                                            warningBorder={onState}
                                            itemOnClick={(e) => {
                                                // let tmp = {
                                                //     target: {
                                                //         name: "category_id",
                                                //         value: e.id,
                                                //     },
                                                // };
                                                // console.log(e);
                                                setDataToForm("category_id", e.id, index)
                                                // setGiftCardAddForm(tmp);
                                            }}
                                        />
                                    </div>
                                </div>
                                <CommonLabel labelText={t("Upload gift card Image")} required={true} />
                                <div className="pt-s16"></div>
                                <ImageUpload
                                    customWidth='w-s150'
                                    onChangeFile={(e) => {
                                        // console.log('result IMAGE:::', e);
                                        setDataToForm("image", e, index);
                                    }
                                    }
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}
