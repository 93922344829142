import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFaqStore, { getAllFaq } from '../../../App/Stores/FaqStore';

import CommonAccordion from "../../../Components/Accordion/CommonAccordion";
import AddBtnOutlined from "../../../Components/Button/AddBtnOutlined";
import AddFaq from './FaqPopup/AddFaq';
import DeleteFaq from "./FaqPopup/DeleteFaq";
import EditAccordionData from "./FaqPopup/EditAccordionData";

const FaqDetails = () => {
  const { t } = useTranslation();

  const { setAddingNewFaq, faqListAll } = useFaqStore();

  useEffect(() => {
    getAllFaq()
  }, []);

  return (

    <div className="w-full">
      <AddFaq />
      <DeleteFaq />
      <EditAccordionData />
      <div className="text-cTextBlack text-fs20 font-fw600">{t("FAQ")}</div>

      <div className="my-5 p-5 rounded-br5 bg-white shadow-md">
        {/* green: top bar */}
        <div className="flex items-center justify-end pb-5 px-[1px]">
          <div className="flex items-center">
            <div>
              <AddBtnOutlined btnLabel={t("Add FAQ")} onClick={() => setAddingNewFaq(true)} />
            </div>
          </div>
        </div>

        {/* blue: all faq area.... */}
        <div className="rounded-br5  max-h-s400 overflow-y-auto w-full">
          <div className="pb-s2"></div>
          {faqListAll.map((faq, index) => (
            <div key={index} className={`${faqListAll.length !== index + 1 ? "pb-5" : "pb-s2"} w-full`}>
              <CommonAccordion
                key={index}
                dataID={faq.id}
                data={faq}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqDetails;
