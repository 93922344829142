import React from 'react';
import { UserAvatar } from '../Utility/ImageImports';

const Image = ({ src, src2, className, onClick, dummyImage, ref }) => {
    // console.log("image src::: ", src);
    // console.log("image src2::: ", src2);
    return (
        <>
            <img
                ref={ref}
                onClick={onClick}
                className={className}
                src={src ? 'https://gifty-api.non-logic.com/' + src : (src2 ? src2 : dummyImage ? dummyImage : UserAvatar)}
                alt="Attachment"
                onError={(e) => { e.target.onerror = null; e.target.src = dummyImage ? dummyImage : UserAvatar }}
            />
        </>
    )
}

export default Image