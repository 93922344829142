import { useTranslation } from "react-i18next";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import { BaseUrlSrc } from "../../App/Utility/Url";
import AccountSettingsDropDown from "../../Pages/AccountSettings/AccountSettingsDropDown";
import NotificationDropDown from "../../Pages/Notification/NotificationDropDown";
import FreeDropDown from "../DropDown/FreeDropDown";
import LanguageSwitch from "../Switch/LanguageSwitch";
import { NotificationIcon, ProfileImage } from "../Utility/ImageImports";

const Topbar = ({ isOpen = true }) => {
  const { t } = useTranslation();
  const { loggedUser } = useUtilityStore();
  const { barTitle } = useLayoutStore();

  return (
    <div
      className={`${isOpen ? "pl-64" : "pl-20"} w-full py-s10 shadow-md fixed bg-cLayoutBg z-50`}>
      {/* <EditAccount /> */}
      <div className="relative w-full">
        <div className="text-[36px] font-bold pl-s50 capitalize">{barTitle}</div>
        <div className="flex items-center justify-between top-[50%] right-[15px] translate-y-[-50%] absolute space-x-10 ">

          {/* <Link to="/contact-message">
            <img src={MessageIcon} alt="" className="cursor-pointer" />
          </Link> */}

          <div>
            <FreeDropDown
              width="w-[400px]"
              button={
                // <Link to="">
                <img src={NotificationIcon} alt="" className="cursor-pointer" />
                // </Link>
              }
              body={<NotificationDropDown />}

            />
          </div>


          <div className="">
            <LanguageSwitch />
          </div>

          <div className="rounded-full">
            <FreeDropDown
              width="w-[400px]"
              button={
                <img
                  src={loggedUser?.image_url ? (BaseUrlSrc + "/" + loggedUser?.image_url) : ProfileImage}
                  alt=""
                  className="w-s35 h-s35 object-cover rounded-full cursor-pointer"
                />
              }
              body={
                <>
                  <AccountSettingsDropDown />
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
