import React from "react";
import useRoleStore from "../../App/Stores/RoleStore";
import CommonButton from "../../Components/Button/CommonButton";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import CommonModal from "../../Components/Modal/CommonModal";
import { CheckItems } from "./CheckItems";

export default function AddRole() {
  const { showAddRole, setShowAddRole } = useRoleStore();

  return (
    <>
      <CommonModal
        showModal={showAddRole}
        setShowModal={setShowAddRole}
        modalTitle="add role"
        mainContent={<AddForm />}
        singleButton={
          <div className="pt-5">
            <CommonButton label="save" />
          </div>
        }
      />
    </>
  );
}

function AddForm() {
  return (
    <div>
      <CommonInput label={"Name"} placeholder="Give a name to the role" />
      <div className="pt-5">
        <CommonLabel labelText="Access to" />
      </div>
      <div className="text-left flex items-center justify-between text-fs16 px-s5">
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems title={"dashboard"} />
          </div>
          <div className="py-5">
            <CheckItems title={"customers"} />
          </div>
          <div className="py-5">
            <CheckItems title={"shops"} />
          </div>
        </div>
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems title={"category"} />
          </div>
          <div className="py-5">
            <CheckItems title={"gift cards"} />
          </div>
          <div className="py-5">
            <CheckItems title={"orders"} />
          </div>
        </div>
        <div className="flex flex-col justify-between items-start">
          <div className="py-5">
            <CheckItems title={"coupon"} />
          </div>
          <div className="py-5">
            <CheckItems title={"FAQ"} />
          </div>
          <div className="py-5">
            <CheckItems title={"language"} />
          </div>
        </div>
      </div>
    </div>
  );
}
