import React from "react";

export default function TopSellingTableItem({ tableData = {}, barIndex }) {
  return (
    <>
      <tr className={`bg-white shadow-cShopItem  select-none cursor-pointer`}>
        <CommonTD showData={barIndex} className="w-[50px]" />
        <CommonTD
          className={"text-left min-w-[120px] max-w-[200px] truncate"}
          showData={tableData?.card_name}
        />
        <CommonTD className={"min-w-[150px]"} showData={tableData?.card_price} />
        <CommonTD className={"min-w-[150px]"} showData={tableData?.card_total_order} />
        <CommonTD className={"min-w-[150px]"} showData={tableData?.card_total_sale} />
      </tr>
    </>
  );
}

const CommonTD = ({ showData, className }) => {
  return (
    <td
      onClick={() => { }}
      className={`px-6 text-center py-4 border-collapse border ${className}`}
    >
      {showData ? showData : "0"}
    </td>
  );
};
