import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import useLayoutStore from "../../App/Stores/LayoutStore";
import useVoucherStore, {
  deleteVoucher,
  getAllVoucher,
  searchVoucherData,
  toggleVoucher,
} from "../../App/Stores/VoucherStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonDeactivateModal from "../../Components/Modal/CommonDeactivateModal";
import CommonDeleteConfirmModal from "../../Components/Modal/CommonDeleteConfirmModal";
import CommonTable from "../../Components/Table/CommonTable";
import VoucherDetails from "./VoucherDetails";
import VoucherTableItem from "./VoucherTableItem";

export default function VoucherTable() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    voucherList,
    voucherListPagination,
    searchVoucherKey,
    setSearchVoucherKey,
    resetAddVoucherFormData,
    showDeactivateVoucher,
    setShowDeactivateVoucher,
    showDeleteVoucher,
    setShowDeleteVoucher,
    voucherLastPageAdd,
    setVoucherLastPageAdd,
    voucherDetails
  } = useVoucherStore();

  const VoucherHeaders = [
    { index: 1, name: t("title") },
    { index: 2, name: t("Voucher type") },
    { index: 3, name: t("code") },
    { index: 4, name: t("limit") },
    { index: 5, name: t("expire date") },
    { index: 6, name: t("amount") },
    { index: 7, name: t("Action") },
  ];

  const navigate = useNavigate();

  const [searchValue] = useDebounce(searchVoucherKey, 500);

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Voucher"));
    PageTitle(t("Voucher"));

    if (voucherLastPageAdd) {
      getAllVoucher(voucherLastPageAdd);
      setVoucherLastPageAdd(null)
    } else {
      setSearchVoucherKey("")
      getAllVoucher();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchVoucherData(searchValue);
  }, [searchValue]);


  return (
    <div>
      <VoucherDetails />

      <CommonDeactivateModal
        showDeactivateModal={showDeactivateVoucher}
        setShowDeactivateModal={setShowDeactivateVoucher}
        messageToShow={t("Are you sure, you want to Deactivate this Voucher?")}
        onConfirm={() => {
          let loadUrl =
            voucherListPagination.path +
            "?page=" +
            voucherListPagination.current_page;

          toggleVoucher(loadUrl);
        }}
      />

      <CommonDeleteConfirmModal
        showDeleteModal={showDeleteVoucher}
        setShowDeleteModal={setShowDeleteVoucher}
        messageToShow={t("Are you sure you want to Delete this Voucher ?")}
        onConfirm={() => {
          let loadUrl =
            voucherListPagination.path +
            "?page=" +
            voucherListPagination.current_page;

          deleteVoucher(voucherDetails.voucher.id, loadUrl);
        }}
      />

      <CommonTable
        addItemTitle={t("add voucher")}
        addItemFunction={() => {
          navigate("add-voucher");
          resetAddVoucherFormData();
        }}
        headers={VoucherHeaders}
        items={
          voucherList?.length
            ? voucherList.map((item, index) => (
              <VoucherTableItem key={index} data={item} />
            ))
            : ""
        }
        showingFrom={voucherListPagination?.from}
        showingTo={voucherListPagination?.to}
        totalResult={voucherListPagination?.total}
        searchValue={searchVoucherKey}
        searchOnChange={(e) => setSearchVoucherKey(e.target.value)}

        paginationObject={voucherListPagination}
        pagesArray={voucherListPagination.links}
        currentPageNumber={voucherListPagination.current_page}
        prevPageUrl={voucherListPagination.prev_page_url}
        nextPageUrl={voucherListPagination.next_page_url}
        showPagination={voucherListPagination?.last_page !== 1 ? true : false}
        paginationOnClick={(url) => {
          if(searchValue){
            searchVoucherData(searchValue,url)
          }else getAllVoucher(url);
        }}
      />
    </div>
  );
}
