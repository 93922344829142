import axios from "axios";
import create from "zustand";
import { dashBoardDataUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useDashboardStore = create((set) => ({
  recentOrderData: [
    {
      orderedProduct: "Lovable Gift Card",
      qty: "3",
      customerName: "Subrata halder",
      paymentMethod: "COD",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "5",
      customerName: "Subrata halder",
      paymentMethod: "Stripe",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "8",
      customerName: "Subrata halder",
      paymentMethod: "Pay Pal",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "1",
      customerName: "Subrata halder",
      paymentMethod: "Debit Card",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "3",
      customerName: "Subrata halder",
      paymentMethod: "COD",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
  ],

  topSellingData: [
    {
      serialNumber: "1",
      cardName: "LX Gift Card",
      price: "$50",
      totalOrder: "34,666 Piece",
      totalSales: "$3,46,660",
      isActive: true,
    },
    {
      serialNumber: "2",
      cardName: "ASP Gift Card",
      price: "$20",
      totalOrder: "20,000 Piece",
      totalSales: "$1,20,000",
      isActive: true,
    },
    {
      serialNumber: "3",
      cardName: "EUG Gift Card",
      price: "$50",
      totalOrder: "15,000 Piece",
      totalSales: "$1,20,000",
      isActive: true,
    },
    {
      serialNumber: "4",
      cardName: "JPX Gift Card",
      price: "$40",
      totalOrder: "10,000 Piece",
      totalSales: "$1,50,000",
      isActive: true,
    },
    {
      serialNumber: "5",
      cardName: "AZR Gift Card",
      price: "$70",
      totalOrder: "10,000 Piece",
      totalSales: "$3,00,000",
      isActive: true,
    },
  ],

  recentOrders: [],
  setRecentOrders: (value) => set({ recentOrders: value }),

  topSellingCards: [],
  setTopSellingCards: (value) => set({ topSellingCards: value }),

  analytics: {},
  setAnalytics: (value) => set({ analytics: value }),

  totalCard: "",
  setTotalCard: (value) => set({ totalCard: value }),

  totalShop: "",
  setTotalShop: (value) => set({ totalShop: value }),

  totalCategory: "",
  setTotalCategory: (value) => set({ totalCategory: value }),

  totalCustomer: "",
  setTotalCustomer: (value) => set({ totalCustomer: value }),
}));

export default useDashboardStore;

/* 
    API Functions
 */

// green: get all shop list
export const getDashboard = async () => {
  try {
    setLoading(true);
    const res = await axios.get(dashBoardDataUrl);

    console.log("getDashboard res.data:::: ", res.data.data);

    if (res.data.success) {
      useDashboardStore.getState().setRecentOrders(res.data.data.recent_orders);
      useDashboardStore.getState().setTopSellingCards(res.data.data.top_selling_cards_of_month);
      useDashboardStore.getState().setAnalytics(res.data.data.analytics);

      useDashboardStore.getState().setTotalCard(res.data.data.total_card);
      useDashboardStore
        .getState()
        .setTotalCategory(res.data.data.total_category);
      useDashboardStore
        .getState()
        .setTotalCustomer(res.data.data.total_customer);
      useDashboardStore.getState().setTotalShop(res.data.data.total_shop);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getDashboard: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};
