import React, { useState } from "react";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import useCustomerStore, { updateCustomer } from "../../../App/Stores/CustomerStore";
import ImageUpload from "../../../Components/Input/ImageUpload";
import { useEffect } from "react";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import { useTranslation } from "react-i18next";

const EditCustomer = () => {
  const { t } = useTranslation();

  const { showEditModal, setShowEditModal, customerUpdateData, setCustomerUpdateData, setEditImageState, setCustomerUpdateBody, customerListPagination } = useCustomerStore();

  const [updateBody, setUpdateBody] = useState({});
  const [passMatch, setPassMatch] = useState(false);

  useEffect(() => {
    setUpdateBody({ ...updateBody, "user_id": localStorage.getItem("customerID") })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        widthClass="w-w40vw"
        modalTitle={t("Edit Customer")}
        mainContent={
          <form onSubmit={(e) => e.preventDefault()} className="pt-s20 bg-white">
            <div className="flex justify-center items-center pb-s25">
              {/* image */}
              <div className="w-full">
                <ImageUpload
                  btnLabel={t("change image")}
                  imgValue={customerUpdateData?.image_url ? customerUpdateData?.image_url : ""}
                  customWidth={"w-s100"}
                  CustomStyles={"mx-s30"}
                  onChangeFile={(e) => {
                    setEditImageState(true);
                    console.log("image", e);
                    let imgEvent = {
                      target: {
                        name: "image_url",
                        value: e
                      }
                    }
                    setUpdateBody({ ...updateBody, "image_url": e })
                    setCustomerUpdateData(imgEvent);
                  }}
                />
              </div>
            </div>

            {/* green: inputs */}
            <div>
              <CommonInput
                label={t("Name")}
                name={"name"}
                onChange={(e) => {
                  setCustomerUpdateData(e)
                  setUpdateBody({ ...updateBody, "name": e.target.value })
                }}
                value={customerUpdateData?.name ? customerUpdateData?.name : ""}
                placeholder={t("Enter Name...")}
              />
              <div className="flex items-center">
                <div className="w-full">
                  <CommonInput
                    label={t("Email")}
                    type="email"
                    name={"email"}
                    onChange={(e) => {
                      setCustomerUpdateData(e)
                      setUpdateBody({ ...updateBody, "email": e.target.value })
                    }}
                    value={customerUpdateData?.email ? customerUpdateData?.email : ""}
                    placeholder={t("Enter Email...")}
                  />
                </div>
                <div className="p-s10"></div>
                <div className="w-full">
                  <CommonInput
                    label="Phone"
                    type="tel"
                    name={"phone"}
                    onChange={(e) => {
                      setCustomerUpdateData(e)
                      setUpdateBody({ ...updateBody, "phone": e.target.value })
                    }}
                    value={customerUpdateData?.phone ? customerUpdateData?.phone : ""}
                    placeholder={t("Enter Phone Number...")}
                  />
                </div>
              </div>
              <CommonInput
                label="Address"
                name={"address"}
                onChange={(e) => {
                  setCustomerUpdateData(e)
                  setUpdateBody({ ...updateBody, "address": e.target.value })
                }}
                value={customerUpdateData?.address ? customerUpdateData?.address : ""}
                placeholder={t("Enter Address...")}
              />

              <div className="flex items-center">
                <div className="w-full">
                  <CommonInput
                    min_input={6}
                    label="New Password"
                    type="password"
                    warningBorder={passMatch}
                    name={"password"}
                    onChange={(e) => {
                      setCustomerUpdateData(e);
                      setUpdateBody({ ...updateBody, "password": e.target.value })
                    }}
                    value={customerUpdateData?.password}
                    placeholder={t('New password')}
                  />
                </div>
                <div className="p-s10"></div>
                <div className="w-full">
                  <CommonInput
                    min_input={6}
                    label={t("Confirm New Password")}
                    type="password"
                    warningBorder={passMatch}
                    name={"password_confirmation"}
                    onChange={(e) => {
                      setCustomerUpdateData(e);
                      setUpdateBody({ ...updateBody, "password_confirmation": e.target.value })
                    }}
                    value={customerUpdateData?.password_confirmation}
                    placeholder={t('Retype password')}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-5">
              <CommonButton
                type="submit"
                btnLabel={t("Save")}
                colorType="primary"
                onClick={() => {
                  if (customerUpdateData.password && customerUpdateData.password !== customerUpdateData.password_confirmation) {
                    setPassMatch(true);
                    return Toastr({ message: t("Password and Confirm Password should be Same!"), type: "error" });
                  } else {
                    setPassMatch(false);
                    setCustomerUpdateBody(updateBody)
                    if (customerListPagination.current_page) {
                      let urlToLoad = customerListPagination.path + "?page=" + customerListPagination.current_page;
                      // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
                      updateCustomer(urlToLoad);
                    } else {
                      updateCustomer();
                    }
                    setShowEditModal(false)
                  }
                }}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default EditCustomer;
