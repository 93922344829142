import React from "react";
import useContactStore from "../../App/Stores/ContactStore";
import CommonButton from "../../Components/Button/CommonButton";
import Image from "../../Components/Image/Image";
import CommonInput from "../../Components/Input/CommonInput";
import CommonModal from "../../Components/Modal/CommonModal";

export default function ContactReply({ data }) {
  const { showContactMessageDetails, setShowContactMessageDetails } =
    useContactStore();

  return (
    <>
      <CommonModal
        showModal={showContactMessageDetails}
        setShowModal={setShowContactMessageDetails}
        // widthClass="w-[45vw]"
        modalTitle="Message Reply"
        singleButton={
          <>
            <CommonButton btnLabel="Send" colorType="primary" />
          </>
        }
        mainContent={
          <div className="pt-5">
            <div className="bg-cVerify rounded-br5 flex py-5 mb-5 pl-5 items-center">
              <Image src={""} className="h-s80 w-s80 rounded-full object-cover" />

              <div className="pl-s10">
                <div className="text-fs18 font-semibold pb-s10">John Doe</div>
                <div className="text-fs14">mail@email.com</div>
              </div>
            </div>
            <div className="flex pb-5">
              <div className="text-cInputLabel font-fw500 text-fs16">
                Subject:
              </div>
              <div className="pl-s10">Some text that represents a subject for the message or purpose of this message that the sender has decided.</div>
            </div>
            <div className="w-full pb-5">
              <div className="text-cInputLabel font-fw500 text-fs16 pb-s10">
                Message
              </div>
              <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                temporibus dolor quam suscipit neque placeat cupiditate
                voluptatum rem quod incidunt debitis accusamus sit similique
                deserunt, quidem ducimus alias. Ullam, eius.
              </div>
            </div>
            <div className="pb-5">
              <hr />
            </div>
            <div className="w-full">
              <CommonInput textarea={true} label="Reply" />
            </div>
          </div>
        }
      />
    </>
  );
}
