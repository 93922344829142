/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { getGeneralInfo, updateGeneralInfo } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import RichTextEditor from "../../../Components/Input/RichTextEditor";

const PrivacyDetails = () => {
  const { t } = useTranslation();

  const { generalInfo, setGeneralInfo, privacyEditState, setPrivacyEditState } = useSettingsStore();

  console.log(generalInfo.info);

  const handleSubmit = () => {
    if (generalInfo) {
      updateGeneralInfo('privacy-policy');
    }
  }

  useEffect(() => {
    getGeneralInfo('privacy-policy');
    setPrivacyEditState(false);
  }, [])

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="text-fs20 font-fw600 pb-5">{t("Privacy & Policy")}</div>

      <div className={`bg-white p-5 rounded-br5 ${privacyEditState ? "h-s400" : ""} drop-shadow-md`}>
        {privacyEditState ? <div className="h-[318px]">
          <RichTextEditor
            placeholder={t("Write your business Privacy & Policy...")}
            value={generalInfo}
            onChange={(e) => setGeneralInfo(e)}
          />
        </div> :
          <div className="flex">
            <div dangerouslySetInnerHTML={{ __html: generalInfo }}></div>
          </div>
        }
      </div>

      <div className="flex justify-end pt-5">
        {privacyEditState ? <CommonButton btnLabel={t("Save Changes")} type="submit" colorType="primary" onClick={handleSubmit} /> :
          <CommonButton btnLabel={t("Edit")} type="submit" colorType="primary" onClick={() => setPrivacyEditState(true)} />
        }
      </div>
    </form>
  );
};

export default PrivacyDetails;
