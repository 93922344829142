import React, { useEffect } from 'react'
import useLayoutStore from '../../../App/Stores/LayoutStore';
import { PageTitle } from '../../../App/Utility/UtilityFunctions';
import ShowRecentOrderTable from './ShowRecentOrderTable';

export default function ShowRecentOrder() {
    const { setBarTitle } = useLayoutStore();
  
    useEffect(() => {
      setBarTitle("Recent Orders");
      PageTitle("Recent Orders");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const recentOrderHeaders = [
        { index: 1, name: "Ordered Product" },
        { index: 2, name: "Qty" },
        { index: 3, name: "Customer Name" },
        { index: 4, name: "Payment Method" },
        { index: 5, name: "Phone No." },
        { index: 6, name: "Address" },
        { index: 7, name: "Price" },
      ];

  return (
    <>
        
        <ShowRecentOrderTable title={"Recent Order - (20)"} headers={recentOrderHeaders} />
    </>
  )
}
