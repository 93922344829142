import React from "react";
import { Link } from "react-router-dom";
import useBoostedStore from "../../../../App/Stores/BoostedStore";
import useShopStore from "../../../../App/Stores/ShopStore";
import { BaseUrlSrc } from "../../../../App/Utility/Url";
import { formatDate } from "../../../../App/Utility/UtilityFunctions";
import {
  DeleteIcon,
  EditIcon, NoImageShop
} from "../../../../Components/Utility/ImageImports";

export default function BoostedShopTableItem({ tableData }) {
  const { setShopDetails } = useShopStore();
  const {
    setShowDeleteShopBoost,
    setDeleteShopBoostID,
    setUpdateBoostedShopForm,
    updateBoostedShopForm,
    setShowEditBoostedShop,
  } = useBoostedStore();

  const HandleEditItem = () => {
    console.log("EDIT OK!");

    let eID = {
      target: {
        name: "boosted_id",
        value: tableData?.id,
      },
    };
    setUpdateBoostedShopForm(eID);

    let eShopName = {
      target: {
        name: "shop_name",
        value: tableData?.shop.name,
      },
    };
    setUpdateBoostedShopForm(eShopName);

    let eStartDate = {
      target: {
        name: "start_date",
        value: tableData?.start_date,
      },
    };
    setUpdateBoostedShopForm(eStartDate);

    let eEndDate = {
      target: {
        name: "end_date",
        value: tableData?.end_date,
      },
    };
    setUpdateBoostedShopForm(eEndDate);

    let eStatus = {
      target: {
        name: "status",
        value: tableData?.status,
      },
    };
    setUpdateBoostedShopForm(eStatus);

    console.log("updateBoostedShopForm:::", updateBoostedShopForm);

    setShowEditBoostedShop(true);
  };

  return (
    <>
      <tr
        className={`hover:bg-cBandHover bg-white shadow-cShopItem  select-none text-fs14 border-b`}
      >
        <th
          onClick={() => {
            setShopDetails({});
            localStorage.setItem("shopID", tableData?.shop_id);
          }}
          className={`px-s10 text-left font-fw400 py-s10 border-r min-w-[250px]`}
        >
          <Link
            to={"/shops/details"}
            className="cursor-pointer hover:text-cMainBlue"
          >
            <div className="flex items-center">

              <img
                className="w-6 h-6 object-cover rounded-full"
                alt=""
                src={
                  tableData?.shop?.image_url
                    ? BaseUrlSrc + "/" + tableData?.shop?.image_url
                    : NoImageShop
                }
              />

              <span className="pl-s10 max-w-[200px] truncate">
                {tableData?.shop?.name ?? "NA"}
              </span>
            </div>
          </Link>
        </th>

        <CommonTD
          tableData={tableData}
          showData={formatDate(tableData?.start_date)}
        />
        <CommonTD
          tableData={tableData}
          showData={formatDate(tableData?.end_date)}
        />
        <td className={`px-5 text-center py-s10 border-r capitalize font-fw400 min-w-[150px]`}>
          <span
            className={`px-s10 py-s5 ring-[1px] rounded-full
        ${tableData?.status === "active" && "text-cSuccess ring-cSuccess"}
        ${tableData?.status === "init" && "text-cChartBg2 ring-cChartBg2"}
        ${tableData?.status === "expired" && "text-cRed ring-cRed"}`}
          >
            {tableData?.status === "init" ? "Not Started" : tableData?.status}
          </span>
        </td>

        <td className="px-s10 py-s10 cursor-default min-w-[120px]">
          <tr className="flex space-x-s15 items-center justify-center ">
            <td>
              <img
                onClick={() => HandleEditItem()}
                src={EditIcon}
                alt=""
                className="w-s16 h-s16 object-cover cursor-pointer mx-s5"
              />
            </td>
            <td>
              <img
                onClick={() => {
                  setDeleteShopBoostID(tableData.id);
                  setShowDeleteShopBoost(true);
                }}
                src={DeleteIcon}
                alt=""
                className="w-s16 h-s16 object-cover cursor-pointer mx-s5"
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}
const CommonTD = ({ showData, tableData }) => {
  return (
    <td className="px-s10 text-center py-s10 border-r min-w-[150px]">
      {showData ? showData : "0"}
    </td>
  );
};
