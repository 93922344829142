/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuthStore, { handleUserLogin } from "../../App/Stores/authStore";
import { GIFTY_LOGO } from "../../App/Utility/source";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import CommonInput from "../../Components/Input/CommonInput";
import LanguageSwitch from "../../Components/Switch/LanguageSwitch";
import {
  LoginLogoImage,
  LoginLogoTexture,
} from "../../Components/Utility/ImageImports";

export default function Login() {
  const { login_form, isLoggedIn, changeLoginFormValue, resetLoginFormValue } =
    useAuthStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    PageTitle("Login");
    resetLoginFormValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen w-full flex justify-between relative">
      <LeftSideLogo />

      <LoginForm
        login_form={login_form}
        changeLoginFormValue={changeLoginFormValue}
      />
    </div>
  );
}

function LoginForm(props) {
  const { t } = useTranslation();
  return (
    <div className="bg-white w-full">
      <div className="lg:pr-5 pr-2 pt-5 flex items-center justify-end">
        <LanguageSwitch />
        <div className=""></div>
      </div>
      <div className="flex justify-end pr-s100 pt-s75">
        <img src={LoginLogoTexture} alt="" />
      </div>

      <div className="flex justify-center py-5">
        <div className="w-full px-s100">
          <div className="w-full text-center pb-[8%] text-fs36 text-cTextBlack font-fw600">
            {t("Admin Login")}
          </div>
          <form
            onSubmit={(e) => {
              handleUserLogin(e);
            }}
          >
            <div className="mb-5">
              <CommonInput
                type="email"
                label={t("email")}
                placeholder={t("Enter email address")}
                name={"email"}
                required={true}
                value={props.login_form.email}
                onChange={props.changeLoginFormValue}
              />
            </div>

            <div className="mb-5">
              <CommonInput
                type="password"
                togglePasswordBtn={true}
                label={t("password")}
                placeholder={t("Enter password")}
                name="password"
                required={true}
                value={props.login_form.password}
                onChange={props.changeLoginFormValue}
              />
            </div>

            <div className="w-full flex justify-center pt-4">
              <CommonButton type="submit" width={"w-full"} btnLabel={t("Login")} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function LeftSideLogo() {
  return (
    <div className="bg-cTintBrick w-full flex flex-col items-center justify-center">
      <div className="px-s100 py-5">
        <img src={GIFTY_LOGO} alt="" className="w-s250" />
      </div>

      <div className="p-5">
        <img src={LoginLogoImage} alt="" />
      </div>

    </div>
  );
}
