import React, { useEffect } from "react";
import useSettingsStore, { getAllFees } from "../../../App/Stores/SettingsStore";
import AuthorityFee from "./AuthorityFee";
import ShippingFee from "./ShippingFee";

export default function AllFees() {
  const { authFee, shippingFee } = useSettingsStore();

  useEffect(() => {
    getAllFees();

    console.log("authFee:::::", authFee);
    console.log("shippingFee:::::", shippingFee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className=" flex items-center">
      <div className="w-full">
        <AuthorityFee />
      </div>
      <div className="px-s60"></div>
      <ShippingFee />
    </div>
  );
}
