import React from "react";

import { useNavigate } from "react-router-dom";
import useShopStore, {
  getShopDetails,
  toggleShopActivation
} from "../../../App/Stores/ShopStore";
import { MuiCustomSwitch } from "../../../App/Stores/UtilityStore";
import Image from "../../../Components/Image/Image";
import {
  NoShop
} from "../../../Components/Utility/ImageImports";

export default function ShopTableItem({ tableData = {}, barIndex, end }) {
  const {
    setShowDeactivateModal,
    setDeactivateShopForm,
    setSelectedShopID,
    shopListPagination,
  } = useShopStore();
  const handleActiveShop = (e) => {
    console.log(e.target.checked);
  };

  // initialize useNavigate
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate("details");
  };

  return (
    <>
      <tr
        className={`hover:bg-cBandHover bg-white shadow-cShopItem  select-none cursor-pointer text-fs14 ${barIndex === end ? "border-b-0" : "border-b"
          }`}
      >
        <th
          onClick={() => {
            getShopDetails(tableData?.user_id);
            localStorage.setItem("shopID", tableData?.user_id);
            setSelectedShopID(tableData?.user_id);
            navigateToDetails();
          }}
          className={`pl-s10 py-s10 text-center border-r font-fw400 min-w-[200px] flex items-center`}
        >
          <span className="min-w-[25px]" >
            <Image
              className="object-cover p-1 w-6 h-6"
              alt=""
              src={tableData?.user_data?.image_url}
              dummyImage={NoShop}
            />
          </span>
          <span className="px-s10 max-w-[200px] truncate">
            {tableData?.user_data?.name}
          </span>
        </th>
        <td
          onClick={() => {
            getShopDetails(tableData?.user_id);
            localStorage.setItem("shopID", tableData?.user_id);
            setSelectedShopID(tableData?.user_id);
            navigateToDetails();
          }}
          className="px-s10 text-center py-s10 border-r max-w-[300px] truncate"
        >
          {tableData?.user_data?.address ? tableData?.user_data?.address : "NA"}
        </td>

        <CommonTD
          tableData={tableData}
          showData={tableData?.shop_cards_count
          }
        />
        <CommonTD tableData={tableData} showData={tableData?.orders_count} />
        <CommonTD
          tableData={tableData}
          showData={tableData?.pending_orders_count}
        />

        <td className="min-w-[70px] py-s10 cursor-default">
          <tr className="flex justify-center items-center space-x-3">
            <td>
              <MuiCustomSwitch
                color="secondary"
                checked={tableData?.user_data?.is_active}
                onClick={() => {
                  setDeactivateShopForm(tableData?.user_id);
                  if (tableData?.user_data?.is_active) {
                    setShowDeactivateModal(true);
                  } else {
                    let loadUrl =
                      shopListPagination.path +
                      "?page=" +
                      shopListPagination.current_page;

                    toggleShopActivation(1, loadUrl);
                  }
                }}
                size="small"
                onChange={(e) => handleActiveShop(e)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}
const CommonTD = ({ showData, tableData }) => {
  const { setSelectedShopID } = useShopStore();

  // initialize useNavigate
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate("details");
  };
  return (
    <td
      onClick={() => {
        localStorage.setItem("shopID", tableData?.user_id);
        getShopDetails(tableData?.user_id);
        setSelectedShopID(tableData?.user_id);
        navigateToDetails();
      }}
      className=" py-s10 text-center border-r min-w-[150px]"
    >
      {showData ? showData : "0"}
    </td>
  );
};
