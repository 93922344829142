import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useGiftCardStore, {
  getCardOrderList,
  getGiftCardsDetails,
  searchCardOrdersList
} from "../../App/Stores/GiftCardStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useOrderStore from "../../App/Stores/OrderStore";
import { formatDate, PageTitle } from "../../App/Utility/UtilityFunctions";
import Image from "../../Components/Image/Image";
import BackLink from "../../Components/Pagination/BackLink";
import CommonTable from "../../Components/Table/CommonTable";
import GiftCard1 from "../../Images/dummy-img/giftcard1.jpeg";
import OrderTableItem from "../Order/DataTable/OrderTableItem";

export default function GiftCardDetails() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    searchOrderValue,
    setSearchOrderValue
  } = useOrderStore();

  const {
    giftCardDetails,
    giftCardOrderPagination,
    giftCardOrderList,
    selectedFilterOrder,
    selectedCardID,
  } = useGiftCardStore();

  useEffect(() => {
    setBarTitle(t("Card Info"));
    PageTitle(t("Card Info"));

    if (!giftCardDetails?.name) {
      getGiftCardsDetails();
      getCardOrderList("all orders", "", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    { index: 1, name: t("Invoice Id") },
    { index: 2, name: t("Shop Name") },
    { index: 3, name: t("Shop Email") },
    { index: 4, name: t("Shop Phone") },
    { index: 5, name: t("Order Date") },
    { index: 6, name: t("Status") },
  ];
  const [searchValue] = useDebounce(searchOrderValue, 500);
  useEffect(() => {
    searchCardOrdersList(searchValue);
  }, [searchValue]);


  return (
    <>
      <BackLink linksArray={[
        { label: t("gift cards"), linkTo: "/gift-cards" },
        { label: t("Card info"), linkTo: "/gift-card-details" },
      ]} />
      <div className="w-full bg-cWhite rounded-br10 shadow-sm p-5 mb-s20">
        <div className="flex justify-between items-center">
          <div>
            <Image
              className="w-auto h-s250 rounded-br5"
              src={giftCardDetails?.image}
              dummyImage={GiftCard1}
              alt=""
            />
          </div>
          <div className="flex items-center justify-between w-1/2">
            <div className="font-fw500 ">
              <div className="pb-s10">{t("Card Name")}</div>
              <div className="pb-s10">{t("Card Category")}</div>
              <div className="pb-s10">{t("Code No")}</div>
              <div className="pb-s10">{t("Card Price")}</div>
              <div className="pb-s10">{t("Upload Date")}</div>
              <div className="pb-s10">{t("Total Sale")}</div>
            </div>
            <div className="text-right ">
              <div className="pb-s10">{giftCardDetails?.name}</div>
              <div className="pb-s10">{giftCardDetails?.category?.name}</div>
              <div className="pb-s10">{giftCardDetails?.code}</div>
              <div className="pb-s10">{giftCardDetails?.price}</div>
              <div className="pb-s10">{formatDate(giftCardDetails?.created_at)}</div>
              <div className="pb-s10">{giftCardDetails?.sold_count} times</div>
            </div>
          </div>
        </div>
      </div>

      <CommonTable
        // titleComponent={
        //   <div className="pb-0">
        //     <CommonDropDown
        //       toggleTitle={"Filter Status"}
        //       data={[
        //         "all orders",
        //         "pending",
        //         "processing",
        //         "completed",
        //         "canceled",
        //         "rejected",
        //       ]}
        //       itemOnClick={(item) => {
        //         // console.log(item);
        //         setSelectedFilterOrder(item);
        //         getCardOrderList(selectedFilterOrder, "", selectedCardID);
        //       }}
        //     />
        //   </div>
        // }
        secondaryTitle={t("Card Orders")}
        headers={headers}
        showingFrom={giftCardOrderPagination?.from}
        showingTo={giftCardOrderPagination?.to}
        totalResult={giftCardOrderPagination?.total}
        items={
          <>
            {giftCardOrderList?.length
              ? giftCardOrderList?.map((item, index) => (
                <OrderTableItem
                  orderData={item}
                  key={index}
                  end={giftCardOrderList?.length - 1}
                  barIndex={index}
                />
              ))
              : ""}
          </>
        }
        searchValue={searchOrderValue}
        searchOnChange={(e) => setSearchOrderValue(e.target.value)}
        showPagination={
          giftCardOrderPagination?.per_page <= giftCardOrderPagination?.total
            ? true
            : false
        }
        paginationObject={giftCardOrderPagination}
        pagesArray={giftCardOrderPagination?.links}
        currentPageNumber={giftCardOrderPagination?.current_page}
        prevPageUrl={giftCardOrderPagination?.prev_page_url}
        nextPageUrl={giftCardOrderPagination?.next_page_url}
        paginationOnClick={(url) => {
          console.log(url);
          getCardOrderList(selectedFilterOrder, url, selectedCardID);
        }}
      />
    </>
  );
}
