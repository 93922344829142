import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useShopStore, { saveNewShop } from "../../../App/Stores/ShopStore";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import ImageUpload from "../../../Components/Input/ImageUpload";
import CommonModal from "../../../Components/Modal/CommonModal";

const AddShop = () => {
  const { t } = useTranslation();

  const { addingNewShop, setAddingNewShop, resetShopFormData } = useShopStore();

  useEffect(() => {
    resetShopFormData();
    // console.log("123");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CommonModal
        showModal={addingNewShop}
        setShowModal={setAddingNewShop}
        widthClass="w-w40vw"
        modalTitle={t("Add Shop")}
        mainContent={
          <>
            <AddForm />
          </>
        }
      />
    </>
  );
};

function AddForm() {
  const { t } = useTranslation();

  const {
    setShopFormData,
    shopFormData,
    setAddingNewShop,
    shopListPagination,
  } = useShopStore();
  const [passMatch, setPassMatch] = useState(false);

  const HandleSubmit = () => {

    if (shopFormData.password !== shopFormData.password_confirmation) {
      setPassMatch(true);
      return Toastr({ message: t("Password and Confirm Password should be Same!"), type: "error" });
    }

    // console.log("shopListPagination:::", shopListPagination);
    let toPage = shopListPagination.last_page;
    if (
      shopListPagination.per_page * shopListPagination.last_page ===
      shopListPagination.total
    ) {
      // console.log("NEED 1 More Page !!!");
      toPage += 1;
    }
    let loadUrl = shopListPagination.path + "?page=" + toPage;
    console.log(loadUrl);
    if (
      shopFormData?.name &&
      shopFormData?.email &&
      shopFormData?.phone &&
      shopFormData?.password &&
      shopFormData?.password_confirmation &&
      shopFormData?.address
    ) {
      // console.log("OK!!");
      let saveSuccess = saveNewShop(loadUrl);
      if (saveSuccess) {
        setAddingNewShop(false);
      }
    }
  };

  return (
    <div className="mt-5 pt-5 bg-white max-h-[80vh] overflow-auto">
      <div className="flex justify-center items-center pb-5">
        {/* image */}
        <div className="w-full">
          <ImageUpload
            CustomStyles={"mx-s30"}
            onChangeFile={(e) => {
              console.log("image", e);
              let imgEvent = {
                target: {
                  name: "image_url",
                  value: e,
                }
              }
              setShopFormData(imgEvent);
            }}
          />
        </div>
      </div>
      {/* inputs */}
      <form onSubmit={(e) => e.preventDefault()}>
        <CommonInput
          onChange={setShopFormData}
          label={t("Name")}
          name={"name"}
          value={shopFormData?.name}
          required={true}
          placeholder={t("Enter Name")}
        />
        <div className="flex items-center">
          <div className="w-full">
            <CommonInput
              onChange={setShopFormData}
              required={true}
              name="email"
              value={shopFormData?.email}
              label={t("Email")}
              placeholder={"Enter Email"}
              type="email"
            />
          </div>
          <div className="p-s10"></div>
          <div className="w-full">
            <CommonInput
              onChange={setShopFormData}
              label={t("Phone")}
              value={shopFormData?.phone}
              name={"phone"}
              type="tel"
              required={true}
              phoneInputMasking={true}
              placeholder={t("Enter Phone number")}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full">
            <CommonInput
              togglePasswordBtn={true}
              warningBorder={passMatch}
              min_input={6}
              onChange={setShopFormData}
              required={true}
              name="password"
              value={shopFormData?.password}
              label={t("Password")}
              placeholder={t("Enter Password")}
              type="password"
            />
          </div>
          <div className="p-s10"></div>
          <div className="w-full">
            <CommonInput
              togglePasswordBtn={true}
              warningBorder={passMatch}
              min_input={6}
              onChange={setShopFormData}
              required={true}
              name="password_confirmation"
              value={shopFormData?.password_confirmation}
              label={t("Confirm Password")}
              placeholder={t("Re-enter Password")}
              type="password"
            />
          </div>
        </div>
        <CommonInput
          onChange={setShopFormData}
          label={t("Address")}
          name={"address"}
          required={true}
          value={shopFormData?.address}
          placeholder={t("Enter Address")}
        />

        <CommonInput
          onChange={setShopFormData}
          textarea={true}
          name="details"
          value={shopFormData.details}
          placeholder={t("Write some details about the Shop")}
          label={t("About")}
          className3="h-s150"
        />

        <div className="flex justify-center">
          <CommonButton
            btnLabel={t("Save")}
            type="submit"
            colorType="primary"
            onClick={() => HandleSubmit()}
          />
        </div>
      </form>
    </div>
  );
}
export default AddShop;
