import React from 'react'
import Barcode from 'react-barcode'

const InvoiceSticker = ({
    stickerRef,
    deliveryFrom = "FROM NAME",
    deliveryDate = "Delivery DATE",
    deliveryAddress = "Delivery ADDRESS",
    deliveryUserName = "Delivery NAME",
    deliveryUserPhone = "Delivery PHONE",
    deliveryInvoiceNumber = "A98-15BC-987456BDE"
}) => {
    return (
        <div ref={stickerRef} className="bg-white p-5 w-full">

            {/* title */}
            <div className="flex justify-between items-center">
                <div className="font-bold text-fs18">Delivery from {deliveryFrom}</div>
                <div className="font-semibold text-fs12 text-[#767676]">{deliveryDate}</div>
            </div>
            <div className="w-full h-s2 bg-[#DEDEDE] my-s10"></div>

            <div className="font-bold text-fs18">Deliver to</div>
            <div className="border-[2px] rounded-br10 border-[#DEDEDE] p-s15">
                <div className="flex justify-between">
                    <div className="text-fs14">{deliveryAddress}</div>
                    <div className="text-fs14">{deliveryUserPhone}</div>
                </div>
                <div className="font-semibold pt-5">{deliveryUserName}</div>
            </div>

            <div className="flex flex-row-reverse pt-5">
                <Barcode value={deliveryInvoiceNumber} />
            </div>

        </div>
    )
}

export default InvoiceSticker