import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useRoleStore from "../../App/Stores/RoleStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonTable from "../../Components/Table/CommonTable";
import AddRole from "./AddRole";
import DeleteRole from "./DeleteRole";
import { EditRole } from "./EditRole";
import RoleTableItem from "./RoleTableItem";

const RoleManagement = ({ roleHeaders }) => {
  const { RoleDummyData, setShowAddRole } = useRoleStore();

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle("Roles");
    PageTitle("Roles");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AddRole />
      <DeleteRole />
      <EditRole />
      <div>
        <CommonTable
          headers={roleHeaders}
          addItemFunction={() => setShowAddRole(true)}
          addItemTitle="create new role"
          items={
            <>
              {RoleDummyData.map((item, index) => (
                <RoleTableItem
                  key={index}
                  data={item}
                  end={RoleDummyData.length - 1}
                  dataIndex={index}
                />
              ))}
            </>
          }
        />
        <div className="py-10 text-blue-400 font-fw600 drop-shadow-md">
          <Link to="no-role-available">No DATA?</Link>
        </div>
      </div>
    </>
  );
};

export default RoleManagement;
