import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOrderListOfCustomer } from '../../../App/Stores/CustomerStore';
import useOrderStore from '../../../App/Stores/OrderStore';
import CommonTable from '../../../Components/Table/CommonTable';
import OrderTableItem from '../../Order/DataTable/OrderTableItem';
import CustomerDetails from '../Details/CustomerDetails';

const OrderHistoryTableItem = () => {

    const { t } = useTranslation();

    const { orderListPagination, searchOrderValue, searchOrderList, orderList } = useOrderStore();

    const headers = [
        { index: 1, name: t("Invoice Id") },
        { index: 2, name: t("Customer name") },
        { index: 3, name: t("Shop email") },
        { index: 4, name: t("Shop phone") },
        { index: 5, name: t("order date") },
        { index: 6, name: t("Status") },
      ]

    return (
        <CustomerDetails>
            <CommonTable
                // titleComponent={
                //   <div className="pb-0">
                //     <CommonDropDown
                //       toggleTitle={"Filter Status"}
                //       data={[
                //         "all orders",
                //         "pending",
                //         "processing",
                //         "completed",
                //         "canceled",
                //         "rejected",
                //       ]}
                //       itemOnClick={(item) => {
                //         // console.log(item);
                //         if (item === "all orders") {
                //           getAllOrderListOfCustomer();
                //         } else getAllOrderListOfCustomer(item);
                //       }}
                //     />
                //   </div>
                // }
                headers={headers}
                showingFrom={orderListPagination.from}
                showingTo={orderListPagination.to}
                totalResult={orderListPagination.total}
                // secondaryTitle={t("Order Details")}
                items={
                    <>
                        {orderList?.map((item, index) => (
                            <>
                                <OrderTableItem
                                    orderData={item}
                                    key={index}
                                    end={orderList?.length - 1}
                                    barIndex={index}
                                />
                            </>
                        ))}
                    </>
                }
                searchValue={searchOrderValue}
                searchOnChange={searchOrderList}
                pagesArray={orderListPagination.links}

                paginationObject={orderListPagination}
                currentPageNumber={orderListPagination.current_page}
                prevPageUrl={orderListPagination.prev_page_url}
                nextPageUrl={orderListPagination.next_page_url}
                showPagination={orderListPagination?.last_page > 1 ? true : false}
                paginationOnClick={(url) => {
                    console.log(url);
                    getAllOrderListOfCustomer("all orders", url, localStorage.getItem("customerID"));
                }}
            />

        </CustomerDetails>
    );
};

export default OrderHistoryTableItem;