import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import useRoleStore from "../../../App/Stores/RoleStore";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";
import CommonTable from "../../../Components/Table/CommonTable";
import AddAdmin from "./AddAdmin";
import AdminTableItem from "./AdminTableItem";

export default function AdminTable() {
  const { setBarTitle } = useLayoutStore();
  const { setShowAddAdmin } = useRoleStore();

  const adminHeaders = [
    { index: 1, name: "first name" },
    { index: 2, name: "last name" },
    { index: 3, name: "email" },
    { index: 4, name: "phone" },
    { index: 5, name: "role" },
    { index: 6, name: "Action" },
  ];

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle("Admins");
    PageTitle("Admins");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AddAdmin />
      <div>
        <CommonTable
          headers={adminHeaders}
          addItemTitle="create new admin"
          addItemFunction={() => setShowAddAdmin(true)}
          items={
            <>
              <AdminTableItem />
              <AdminTableItem />
              <AdminTableItem />
              <AdminTableItem />
              <AdminTableItem />
            </>
          }
        />
        <div className="py-5">
          <Link to="no-admin">no-admin</Link>
        </div>
      </div>
    </>
  );
}
