import React from "react";
import useRoleStore from "../../App/Stores/RoleStore";
import { MuiCustomSwitch } from "../../App/Stores/UtilityStore";
import { DeleteIcon, EditIcon } from "../../Components/Utility/ImageImports";

export default function RoleTableItem({ data, end, dataIndex }) {
  const { setShowEditModal, setShowDeleteRole } = useRoleStore();

  return (
    <>
      <tr className={`text-center ${dataIndex === end ? "" : "border-b"}`}>
        <td className="border-r">{data.name}</td>
        <td className="border-r">{data.access}</td>
        <td className="px-6 py-4">
          <tr className="flex space-x-3 items-center justify-center ">
            <td
              onClick={() => {
                setShowEditModal(true);
              }}
            >
              <img src={EditIcon} alt="" className="cursor-pointer" />
            </td>

            <td
              onClick={() => {
                // setShowDeleteModal(true);
              }}
            >
              <img
                onClick={() => setShowDeleteRole(true)}
                src={DeleteIcon}
                alt=""
                className="w-[17px] h-[17px] ml-5 mr-2 cursor-pointer"
              />
            </td>

            <td className="cursor-pointer">
              <MuiCustomSwitch
                color="secondary"
                // checked={true}
                onClick={() => { }}
                // onChange={(e) => {}}
                inputProps={{ "aria-label": "controlled" }}
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}
