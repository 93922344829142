import axios from "axios";
import create from "zustand";
import { allFeesUrl, changeLanguageUrl, changePasswordUrl, getGeneralInfoUpdateUrl, getGeneralInfoUrl, getProfileUrl, updateFeesUrl, updateProfileUrl, updateRecommendedCategoryUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import { getAllCategory } from "./CategoryStore";
import UseTestStore from "./TestStore";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useSettingsStore = create((set) => ({

  userData: {
    name: "",
    email: "",
    phone: "",
    image_url: "",
  },
  setUserData: (e) => set((state) => (state.userData[e.target.name] = e.target.value)),

  finalUpdateUserData: {},
  setFinalUpdateUserData: (value) => set({ finalUpdateUserData: value }),

  dataWithImage: false,
  setDataWithImage: (value) => set({ dataWithImage: value }),

  privacyEditState: false,
  setPrivacyEditState: (value) => set({ privacyEditState: value }),

  termsEditState: false,
  setTermsEditState: (value) => set({ termsEditState: value }),

  authFee: 0,
  setAuthFee: (value) => set({ authFee: value }),

  shippingFee: 0,
  setShippingFee: (value) => set({ shippingFee: value }),

  generalInfo: "",
  setGeneralInfo: (value) => set({ generalInfo: value }),

  termsData: "",
  setTermsData: (value) => set({ termsData: value }),

  selectedOption: "",
  setSelectedOption: (option) => set((state) => (state.selectedOption = option)),

  changePasswordForm: { old_password: '', password: '', password_confirmation: '' },
  setChangePasswordForm: (e) => set((state) => (state.changePasswordForm[e.target.name] = e.target.value)),
  resetChangePasswordForm: (e) => set((state) => (state.changePasswordForm = { old_password: '', password: '', password_confirmation: '' })),

}));

export default useSettingsStore;


// ====================================================
//          API Fn
// ====================================================


// green: get all shop list
export const getAllFees = async (paginationUrl = "") => {
  try {
    setLoading(true);

    const res = await axios.get(allFeesUrl);

    console.log("getALlFees res.data:::: ", res.data.data);

    if (res.data.success) {
      useSettingsStore.getState().setAuthFee(res.data.data.auth_fee.value)
      useSettingsStore.getState().setShippingFee(res.data.data.ship_fee.value)
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllFees: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};


// blue: update shipping fee
export const updateShippingFee = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(
      updateFeesUrl,
      {
        type: "ship_fee",
        value: useSettingsStore.getState().shippingFee,
      }
    );

    console.log("updateShippingFee::::", res.data);

    if (res.data.success) {
      getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateShippingFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// blue: update Auth fee
export const updateAuthFee = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(
      updateFeesUrl,
      {
        type: "auth_fee",
        value: useSettingsStore.getState().authFee
      }
    );

    console.log("updateAuthFee::::", res.data);

    if (res.data.success) {
      getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateAuthFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// blue: update Auth fee
export const updateRecommendedCategory = async (catID) => {
  try {
    setLoading(true);

    const res = await axios.post(
      updateRecommendedCategoryUrl,
      {
        category_id: catID
      }
    );

    console.log("updateRecommendedCategory::::", res.data);

    if (res.data.success) {
      getAllCategory();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateRecommendedCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// green: get general info[privacy-policy, terms-and-conditions, about-us] 
export const getGeneralInfo = async (type = 'privacy-policy') => {
  try {
    setLoading(true);

    const res = await axios.get(getGeneralInfoUrl + '?type=' + type);

    console.log("getGeneralInfo res.data:::: ", res.data);

    if (res.data.success) {
      useSettingsStore.getState().setGeneralInfo(res?.data?.data?.info ?? '')
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getGeneralInfo: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// green: update general info[privacy-policy, terms-and-conditions, about-us] 
export const updateGeneralInfo = async (type = "privacy-policy") => {
  try {
    setLoading(true);
    console.log('type:::', type);

    const res = await axios.post(getGeneralInfoUpdateUrl, {
      type: type,
      info: useSettingsStore.getState().generalInfo,
    });

    console.log("updateGeneralInfo res.data:::: ", res.data);

    if (res.data.success) {
      getGeneralInfo(type)
      useSettingsStore.getState().setGeneralInfo(res?.data?.data?.info ?? '');
      useSettingsStore.getState().setPrivacyEditState(false);
      useSettingsStore.getState().setTermsEditState(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("updateGeneralInfo: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};



// green: get profile data 
export const getProfileData = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getProfileUrl);

    console.log("getProfileData res.data:::: ", res.data.data);

    if (res.data.success) {
      // useSettingsStore.getState().setTermsData(res.data.data)
      useUtilityStore.getState().setLoggedUser(res.data.data);
      localStorage.setItem("user", JSON.stringify(res.data.data));
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};



// green: update Profile 
export const updateProfileData = async () => {
  try {
    setLoading(true);
    const { userData, dataWithImage } = useSettingsStore.getState();
    let res = {};
    if (dataWithImage) {
      res = await axios.post(updateProfileUrl, userData);
    } else {
      res = await axios.post(updateProfileUrl, {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
      });
    }

    console.log("updateProfileData res.data:::: ", res.data);

    if (res.data.success) {
      getProfileData()
      // useSettingsStore.getState().setProfileData(res.data.data)
      setLoading(false);
      return true
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false
    }

  } catch (error) {
    console.log("updateProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false
  }


};



export const changePassword = async () => {
  try {
    const { changePasswordForm } = useSettingsStore.getState();
    if (changePasswordForm.password !== changePasswordForm.password_confirmation) {
      Toastr({ message: "Password and confirm password do not match!", type: "error" });
      return false;
    }

    setLoading(true);

    const res = await axios.post(changePasswordUrl, changePasswordForm);

    console.log("changePassword res.data:::: ", res.data.data);

    if (res.data.success) {
      UseTestStore.getState().setShowEditPassModal(false)
      useSettingsStore.getState().resetChangePasswordForm();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("changePassword: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

//  change Language
export const changeLanguage = async (code = "") => {
  try {
    setLoading(true);

    const res = await axios.post(changeLanguageUrl, {
      code: code,
    });

    localStorage.setItem("lang_code", code);

    console.log("changeLanguage res.data:::: ", res.data.data);

    if (res.data.success) {
      await getProfileData();
      setLoading(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("changeLanguage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};