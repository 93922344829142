import React from "react";

const DataBox = ({
  title = "Total Data",
  totalNumber = 120,
  isSelected = false,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`m-s2 cursor-pointer select-none py-s15 bg-white text-center z-20 border-b-2 ${
        isSelected ? " border-cBrand text-cBrand" : "border-cNmSelect"
      }`}
    >
      <div className={`text-fs18 min-w-[220px] ${isSelected ? "font-fw500":"font-fw400"}`}>{title}</div>
      <div className={`text-fs36 pt-p10 ${isSelected ? "font-fw600":"font-fw400"}`}>{totalNumber}</div>
    </div>
  );
};

export default DataBox;
