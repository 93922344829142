import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useOrderStore, {
  getAllOrderList,
  searchOrdersList,
} from "../../../App/Stores/OrderStore";
import CommonDropDown from "../../../Components/DropDown/CommonDropDown";
import CommonTable from "../../../Components/Table/CommonTable";
import OrderTableItem from "./OrderTableItem";

const OrderTable = ({ title, headers = [], tableBtnTitle }) => {
  const { t } = useTranslation();

  const {
    searchOrderValue,
    orderList,
    orderListPagination,
    setSearchOrderValue,
    orderStatusTable,
    setPaginationUrl,
    setDropdownItem,
    dropdownItem, setOrderList
  } = useOrderStore();
  const [searchValue] = useDebounce(searchOrderValue, 500);

  const fetchAllOrders = async () => {
    await getAllOrderList();
  }

  useEffect(() => {
    if (window.location.pathname === "/orders") {
      setSearchOrderValue("")
      fetchAllOrders();
    }
  }, []);

  useEffect(() => {
    if (searchValue) {
      //setOrderList('all orders')
      searchOrdersList(searchValue);
    } else {
      fetchAllOrders();
    }
  }, [searchValue]);


  return (
    <>
      <CommonTable
        titleComponent={
          <div className="pb-0">
            <CommonDropDown
              toggleTitle={orderStatusTable ? orderStatusTable : "all orders"}
              data={[
                "all orders",
                "pending",
                "processing",
                "completed",
                "canceled",
                "rejected",
              ]}
              itemOnClick={(item) => {
                // console.log(item);
                setDropdownItem(item)
                setSearchOrderValue("")
                if (item === "all orders") {
                  getAllOrderList();
                } else { getAllOrderList(item); };
              }}
            />
          </div>
        }
        headers={headers}
        showingFrom={orderListPagination.from}
        showingTo={orderListPagination.to}
        totalResult={orderListPagination.total}
        items={
          <>
            {orderList?.map((item, index) => (
              <OrderTableItem
                orderData={item}
                key={index}
                end={orderList?.length - 1}
                barIndex={index}
              />
            ))}
          </>
        }
        searchValue={searchOrderValue}
        searchOnChange={(e) => setSearchOrderValue(e.target.value)}

        paginationObject={orderListPagination}
        pagesArray={orderListPagination.links}
        currentPageNumber={orderListPagination.current_page}
        prevPageUrl={orderListPagination.prev_page_url}
        nextPageUrl={orderListPagination.next_page_url}
        showPagination={
          orderListPagination?.last_page !== 1 ? true : false
        }
        paginationOnClick={(url) => {
          setPaginationUrl(url)
          console.log(url);
          if (searchValue) {
            searchOrdersList(searchValue, url);
          } else getAllOrderList(dropdownItem, url);
        }}
      />
    </>
  );
};

export default OrderTable;
