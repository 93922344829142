import ShopTableItem from "./ShopTableItem";
import useShopStore, {
  getAllShop,
  searchShops,
} from "../../../App/Stores/ShopStore";
import AddShop from "../Popup/AddShop";
import CommonTable from "../../../Components/Table/CommonTable";
import DeactivateShop from "../Popup/DeactivateShop";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";

const ShopTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewShop,
    searchKey,
    shopListAll,
    shopListPagination,
    setSearchKey } = useShopStore();

  const [searchValue] = useDebounce(searchKey, 500);

  useEffect(() => {
    if (searchValue) {
      searchShops(searchValue);
    } else {
      if (shopListPagination.current_page) {
        let urlToLoad = shopListPagination.path + "?page=" + shopListPagination.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        getAllShop(urlToLoad);
      } else {
        getAllShop();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);


  return (
    <>
      <CommonTable
        headers={headers}
        addItemTitle={tableBtnTitle}
        showingFrom={shopListPagination.from}
        showingTo={shopListPagination.to}
        totalResult={shopListPagination.total}
        items={
          shopListAll.length > 0
            ? shopListAll.map((item, index) => (
              <ShopTableItem
                key={index}
                barIndex={index}
                end={shopListAll.length - 1}
                tableData={item}
              />
            ))
            : ""
        }
        addItemModal={
          <>
            <AddShop />
          </>
        }
        paginationObject={shopListPagination}
        addItemFunction={setAddingNewShop}
        searchValue={searchKey}
        searchOnChange={(e) => setSearchKey(e.target.value)}
        pagesArray={shopListPagination.links}
        currentPageNumber={shopListPagination.current_page}
        showPagination={
          shopListPagination?.last_page !== 1 ? true : false
        }
        prevPageUrl={shopListPagination.prev_page_url}
        nextPageUrl={shopListPagination.next_page_url}
        paginationOnClick={(url) => {
          // console.log("url::::", url);
          if (searchValue) {
            searchShops(searchValue,url);
          } else getAllShop(url);
        }}
      />
      <DeactivateShop />
    </>
  );
};

export default ShopTable;
