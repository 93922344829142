import React from 'react';
import { useTranslation } from 'react-i18next';
import useVerifyIdentityStore from '../../../App/Stores/VerifyIdentityStore';
import CommonModal from '../../../Components/Modal/CommonModal';
import { BackSideLicense, FrontSideLicense } from '../../../Components/Utility/ImageImports';

const VerificationDetailsModal = () => {

    const { showVerificationDetailsModal, setShowVerificationDetailsModal } = useVerifyIdentityStore()

    const { t } = useTranslation();

    return (
        <div>
            <CommonModal
                showModal={showVerificationDetailsModal}
                setShowModal={setShowVerificationDetailsModal}
                widthClass="w-w70vw"
                modalTitle={t("Verification Details")}
                mainContent={
                    <>
                        <div className='mx-s30 mb-s20'>
                            <div className='bg-cVerify p-s15 text-fs14 font-fw600 my-s20'>
                                <div className='flex justify-between mt-s15'>
                                    <span >Verifying Method</span>
                                    <span>Driving License</span>
                                </div>
                                <div className='flex justify-between my-s15'>
                                    <span>Applying Date</span>
                                    <span>12 November 2022</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span>Action Date</span>
                                    <span>12 November 2022</span>
                                </div>
                                <div className='flex justify-between my-s15'>
                                    <span>Status</span>
                                    <span>Accepted</span>
                                </div>
                            </div>
                            <div className='flex justify-around '>
                                <div className="border-2 border-cNmSelect p-s10 mr-s50">
                                    <span className='text-cTextBlack text-fs20 font-fw600 mb-s12 flex justify-center'>Front Side</span>
                                    <img className='w-s335 h-s200' src={FrontSideLicense} alt="" />
                                </div>

                                <div className="border-2  border-cNmSelect p-s10 ">
                                    <span className='text-center text-cTextBlack text-fs20 font-fw600 mb-s12 flex justify-center'>Back Side</span>
                                    <img className='w-s335 h-s200' src={BackSideLicense} alt="" />
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default VerificationDetailsModal;