import React from "react";
import CommonModal from "../../../Components/Modal/CommonModal";
import CommonButton from "../../../Components/Button/CommonButton";
import useShopStore, { deleteShop } from "../../../App/Stores/ShopStore";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteShop = () => {
  const { t } = useTranslation();

  const { showDeleteModal, setShowDeleteModal, shopListPagination } =
    useShopStore();
  const navigateTo = useNavigate();

  return (
    <>
      <CommonModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        widthClass="w-w40vw"
        modalTitle={t("Confirmation")}
        mainContent={
          <div className="flex justify-center items-center py-5 mb-5">
            <div className="text-[16px]">
              {t("Are you sure you want to delete this shop?")}
            </div>
          </div>
        }
        primaryActionButton={
          <>
            <CommonButton
              onClick={async () => {
                // console.log("giftCardListPagination:::", shopListPagination);
                let toPage = shopListPagination.last_page;
                if (shopListPagination.from === shopListPagination.to) {
                  // console.log("NEED 1 Less Page !!!");
                  toPage = toPage - 1;
                }
                let loadUrl = shopListPagination.path + "?page=" + toPage;
                console.log(loadUrl);

                let successDelete = await deleteShop(loadUrl);

                if (successDelete) {
                  // console.log("Delete Done, Redirecting..");
                  navigateTo("/shops");
                }
              }}
              colorType="danger"
              btnLabel={t("Delete")}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              onClick={() => setShowDeleteModal(false)}
              btnLabel={t("Cancel")}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteShop;
