import React from 'react'
import UseNotificationStore, { getNotificationDetails } from '../../App/Stores/NotificationStore'
import { formatDate } from '../../App/Utility/UtilityFunctions'

const NotificationTableRow = ({ data, index, startPoint }) => {
    const { setShowNotificationDetails } = UseNotificationStore();

    return (
        <tr
            onClick={async () => {
                // return console.log(data);
                await getNotificationDetails(data?.id);
                setShowNotificationDetails(true);
            }}
            className={`border border-collapse bg-white hover:bg-cBandHover cursor-pointer
                        ${data?.is_seen === 0 ? "font-semibold" : "font-normal"}
                        `}>
            <td className='border-r border-collapse py-s10 text-center w-s50 min-w-[50px]'>{startPoint + index}</td>
            <td className='border-r border-collapse py-s10 text-left min-w-[200px] relative'>
                <span className='pl-5'>{data?.title}</span>
                {data?.is_seen === 0 ? <div className='absolute top-[50%] left-s5 -translate-y-[50%] h-s10 w-s10 rounded-full bg-cRed'></div> : ''}
            </td>
            <td className='border-r border-collapse p-s10 min-w-[350px]'>
                <span className="">{data?.description}</span>
            </td>
            <td className='border-r border-collapse text-center min-w-[200px]'>
                {formatDate(data?.created_at)}
            </td>
        </tr>
    )
}

export default NotificationTableRow