import React, { useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { Disclosure, Transition } from "@headlessui/react";
import EditIconRound from "../../Images/icon/edit-icon.svg";
import useFaqStore from '../../App/Stores/FaqStore';

export default function CommonAccordion({
  acTitle = "Something...",
  acDetails = "more data to read....",
  isEditOn = true,
  dataID,
  data,
}) {
  const { setShowEditAccordion, changeEditForm, setFaqUpdateData } = useFaqStore();

  const onEditHandle = (data) => {
    setShowEditAccordion(true)
    setFaqUpdateData(data)

    changeEditForm('title', data.title)
    changeEditForm('description', data.description)    
  }

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div className=" ring-cNmSelect ring-[1px] mx-[1px] py-s10 rounded-br5 transition-transform duration-300">
            <Disclosure.Button className="text-cMainBlack text-fs16 font-fw600 flex items-center justify-between w-full">

              <div className="pl-s10">{data.title}</div>

              <div className="flex items-center pr-s10">
                {isEditOn === true ? (
                  <img
                    src={EditIconRound}
                    alt=""
                    className="w-s15 h-auto"
                    onClick={(e) => { e.preventDefault(); onEditHandle(data); }}
                  />
                ) : (
                  ""
                )}
                <div className="pr-s10"></div>
                <ChevronUpIcon
                  className={`${open ? "rotate-180 transform" : ""
                    } h-s30 w-s30 text-cMainBlack transition-transform duration-300`}
                />
              </div>

            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-100 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="pt-s10">
                <div className="w-full h-[1px] mb-s10 bg-cNmSelect"></div>

                <div className="px-s10">{data.description}</div>

              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  );
}
