import React from "react";
import Clamp from "react-multiline-clamp";
import { useNavigate } from "react-router-dom";
import useGiftCardStore, {
  getCardOrderList,
  getGiftCardsDetails
} from "../../App/Stores/GiftCardStore";
import GiftCard1 from "../../Images/dummy-img/giftcard1.jpeg";
import DeleteIcon from "../../Images/icon/delete-red.svg";
import EditIcon from "../../Images/icon/edit.svg";
import Image from "../Image/Image";

export default function Card({ data }) {
  const {
    setShowDeleteModal,
    setShowEditModal,
    setSelectedCardID,
    setGiftCardDelBody,
  } = useGiftCardStore();

  const [onHoverState, setOnHoverState] = React.useState(false);

  const HandleOnclick = async () => {
    setSelectedCardID(data?.id);
    localStorage.setItem("cardID", data?.id);

    // blue: api calls
    await getGiftCardsDetails(data?.id);
    let orderListSuccess = await getCardOrderList("all orders", "", data.id);
    if (orderListSuccess) {
      navigateTo("/gift-card-details");
    }
  };

  const navigateTo = useNavigate();

  return (
    <>
      <div
        onMouseEnter={() => setOnHoverState(true)}
        onMouseLeave={() => setOnHoverState(false)}
        className={`
              w-full p-s12 rounded-br10 shadow-sm cursor-pointer ring-[1px] ring-cNmSelect

              transition-all duration-300 ease-in-out
              ${onHoverState ? "bg-cBandHover scale-105" : "hover:bg-cWhite"}
        `}
      >
        <Image
          onClick={() => HandleOnclick()}
          className="w-full h-s130 object-cover rounded-br10 mx-auto ring-[1px] ring-cNmSelect bg-white"
          src={data?.image}
          dummyImage={GiftCard1}
          // src={data?.image ? BaseUrlSrc + "/" + data?.image : GiftCard1}
          alt=""
        />

        <div className="mt-s10">
          <Clamp withTooltip lines={1}>
            <h1 onClick={() => HandleOnclick()} className="text-s20 font-fw600">
              {data?.name ? data?.name : "NA"}
            </h1>
          </Clamp>
          <div className="flex items-center justify-between">
            <h3 onClick={() => HandleOnclick()} className="text-s16 font-fw500">
              Price {data?.price ? data?.price : "0"}
            </h3>
            <div className="flex items-center space-x-3">
              <button
                onClick={async () => {
                  await getGiftCardsDetails(data?.id);
                  setShowEditModal(true);
                }}
              >
                <img src={EditIcon} alt="" />
              </button>
              <button
                onClick={() => {
                  console.log("id and shop id:::", data?.id, data?.shop_id);
                  setGiftCardDelBody({ id: data?.id, shop_id: data?.shop_id });
                  setShowDeleteModal(true);
                }}
              >
                <img src={DeleteIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
