import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import useLayoutStore from '../../App/Stores/LayoutStore';
import useVerifyIdentityStore, { searchVerifyIdentityRequest, verifyIdentityRequestIndex } from '../../App/Stores/VerifyIdentityStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonDropDown from '../../Components/DropDown/CommonDropDown';
import CommonTable from '../../Components/Table/CommonTable';
import VerifyIdentityTable from './VerifyIdentityTable';

function VerifyIdentity() {
    const { setBarTitle } = useLayoutStore();
    const { t } = useTranslation();

    const {
        verifyIdentityDropDownValue,
        setVerifyIdentityDropDownValue,
        setVerifyIdentityPaginationUrl,
        verifyIdentityPaginationUrl,
        verifyIdentityRequestList,
        setVerifyIdentityStatus,
        verifyIdentityStatus,
        verifyIdentitySearchValue,
        setVerifyIdentitySearchValue
    } = useVerifyIdentityStore();


    const [searchValue] = useDebounce(verifyIdentitySearchValue, 300);

    useEffect(() => {
        if (searchValue) {
            searchVerifyIdentityRequest(verifyIdentitySearchValue, verifyIdentityPaginationUrl, verifyIdentityDropDownValue.value);
            // console.log("searchValue", searchValue);
        } else {
            if (verifyIdentityRequestList.current_page) {
                let urlToLoad = verifyIdentityRequestList.path + "?page=" + verifyIdentityRequestList.current_page;
                // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
                setVerifyIdentitySearchValue('')
                verifyIdentityRequestIndex(verifyIdentityPaginationUrl, verifyIdentityDropDownValue.value);
            } else {
                verifyIdentityRequestIndex(verifyIdentityPaginationUrl, verifyIdentityDropDownValue.value);
                setVerifyIdentitySearchValue('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, verifyIdentityStatus]);


    const headers = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Customer name") },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Verification Method") },
        { index: 5, name: t("order date") },

    ];

    useEffect(() => {
        setBarTitle(t("Verify Identity"));
        PageTitle(t("Verify Identity"));
    }, []);

    return (
        <>
            <CommonTable
                titleComponent={
                    <div className="pb-0">
                        <CommonDropDown
                            toggleTitle={verifyIdentityDropDownValue.value ? verifyIdentityDropDownValue.value : "all"}
                            data={[
                                {
                                    value: "all",
                                    name: "All",
                                },
                                {
                                    value: "mncard",
                                    name: "My Number Card",
                                },
                                {
                                    value: "driving",
                                    name: "Driving License",
                                },
                                {
                                    value: "rcard",
                                    name: "Residence Card",
                                },
                                {
                                    value: "passport",
                                    name: 'Passport'
                                }
                            ]}
                            itemOnClick={(item) => {
                                setVerifyIdentityDropDownValue("value", item)
                                verifyIdentityRequestIndex("", item);
                                setVerifyIdentitySearchValue('')
                            }}
                        />
                    </div>
                }
                showChip={true}
                chipArray={[
                    {
                        title: "Pending",
                        selected: verifyIdentityStatus === 'pending' ? true : false,
                        action: () => {
                            console.log("pending")
                            setVerifyIdentityStatus('pending')
                            setVerifyIdentityPaginationUrl('')
                            setVerifyIdentitySearchValue('')
                        },
                    },
                    {
                        title: "History",
                        selected: verifyIdentityStatus === 'history' ? true : false,
                        action: () => {
                            console.log("history");
                            setVerifyIdentityStatus('history')
                            setVerifyIdentityPaginationUrl('')
                            setVerifyIdentitySearchValue('')
                        },
                    },
                ]}
                headers={headers}
                shoSearchBox={true}
                pagination={true}
                paginationObject={verifyIdentityRequestList}
                showingFrom={verifyIdentityRequestList?.from}
                showingTo={verifyIdentityRequestList?.to}
                totalResult={verifyIdentityRequestList?.total}

                currentPageNumber={verifyIdentityRequestList.current_page}
                prevPageUrl={verifyIdentityRequestList.prev_page_url}
                nextPageUrl={verifyIdentityRequestList.next_page_url}
                showPagination={verifyIdentityRequestList?.last_page !== 1 ? true : false}

                searchValue={verifyIdentitySearchValue}
                searchOnChange={(e) => {
                    setVerifyIdentitySearchValue(e.target.value)
                }
                }
                paginationOnClick={(url) => {
                    setVerifyIdentityPaginationUrl(url)
                    verifyIdentityRequestIndex(url,verifyIdentityDropDownValue.value);
                }}


                items={
                    <>
                        {verifyIdentityRequestList?.data?.map((item, index) => (
                            <VerifyIdentityTable data={item} index={index} key={index} />
                        ))}
                    </>
                }
            />
        </>
    )
}

export default VerifyIdentity
