import axios from "axios";
import create from "zustand";
import { getContactIndexUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useContactStore = create((set) => ({
  messageData: [
    {
      name: "Odin",
      email: "something@somewhere.com",
      userType: "admin",
      message:
        "one two one three four five one three six nine five seven one two",
    },
    {
      name: "Porthos",
      email: "something@somewhere.com",
      userType: "user",
      message:
        "one two one three six nine five seven one two three four five one three six nine three six nine five seven one two five seven one two",
    },
    {
      name: "Aramis",
      email: "something@somewhere.com",
      userType: "admin",
      message:
        "one two one three four five one three six nine five seven one two three six nine five seven one two three three six nine five seven one two six nine five seven one two",
    },
    {
      name: "Athos",
      email: "something@somewhere.com",
      userType: "customer",
      message:
        "one two one three four three six nine five seven one two five one three six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two three three six nine five seven one two  six nine five seven one two",
    },
    {
      name: "Anne",
      email: "something@somewhere.com",
      userType: "admin",
      message:
        "one two one three four five one three six nine five seven one two",
    },
  ],

  showContactMessageDetails: false,
  setShowContactMessageDetails: (value) => set({ showContactMessageDetails: value }),

  contactTakeAmount: 10,
  setContactTakeAmount: (value) => set({ contactTakeAmount: value }),

  contactIndex: {},
  setContactIndex: (value) => set({ contactIndex: value }),

}));

export default useContactStore;


// get contact index    
export const getContactIndex = async (paginationUrl = "") => {
  const { setContactIndex, contactTakeAmount } = useContactStore.getState();

  try {
    setLoading(true);
    let targetUrl = paginationUrl === "" ? (getContactIndexUrl + "?take=" + contactTakeAmount) : paginationUrl + "&take=" + contactTakeAmount;
    const res = await axios.get(targetUrl);

    console.log("getContactIndex res.data:::: ", res.data.data);
    // return

    if (res.data.success) {
      setContactIndex(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getContactIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};