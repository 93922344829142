import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore, {
  getAllCategory,
} from "../../../App/Stores/CategoryStore";
import { updateRecommendedCategory } from "../../../App/Stores/SettingsStore";

export default function RecommendedCategories() {
  const { t } = useTranslation();

  const { categoryListAll } = useCategoryStore();

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    console.log("categoryListAll::::", categoryListAll);
  }, [categoryListAll]);

  return (
    <div className="w-full">
      <div className="text-fs20 font-fw600 pb-5">
        {t("Select Recommended Categories")}
      </div>

      {/* list of categories */}
      <div className="flex flex-wrap items-center">
        {categoryListAll?.length > 0
          ? categoryListAll.map((item, index) => (
            <div className="pr-s15 mb-s15" key={index}>
              <CategoryChip chipTitle={item.name} isSelected={item.is_recommended === 1 ? true : false} catID={item.id} />
            </div>
          ))
          : ""}
      </div>
    </div>
  );
}

const CategoryChip = ({ chipTitle, isSelected = false, catID }) => {
  return (
    <label
      htmlFor={chipTitle}
      className={`${isSelected === true
        ? "bg-cBrand text-white"
        : "bg-white ring-2 ring-cNmSelect text-cMainBlack"
        } w-fit py-s10 px-s15 rounded-full text-fs16 flex items-center cursor-pointer select-none`}
    >
      <input
        onClick={() => {
          console.log("OK", catID);
          updateRecommendedCategory(catID);
        }}
        id={chipTitle}
        type="radio"
        className="scale-150 accent-white cursor-pointer"
        checked={isSelected}
      />

      <div className="pl-s10 font-fw600">{chipTitle}</div>
    </label>
  );
};
