/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDashboardStore, {
  getDashboard,
} from "../../App/Stores/DashboardStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import DashboardCard from "../../Components/Card/DashboardCard";
import NewChart from "../../Components/Chart/NewChart";
import RecentOrderTable from "./DataTable/RecentOrderTable";
import TopSellingTable from "./DataTable/TopSellingTable";

const Dashboard = () => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { totalCard, totalShop, totalCategory, totalCustomer, recentOrders, analytics } =
    useDashboardStore();

  const navigateTo = useNavigate();

  useEffect(() => {
    setBarTitle(t("Dashboard"));
    PageTitle(t("Dashboard"));

    getDashboard();
  }, []);

  const recentOrderHeaders = [
    { index: 1, name: t("Ordered Product") },
    { index: 2, name: t("Qty") },
    { index: 3, name: t("Customer Name") },
    { index: 4, name: t("Payment Method") },
    { index: 5, name: t("Phone No.") },
    { index: 6, name: t("Address") },
    { index: 7, name: t("Price") },
  ];

  const topSellingHeaders = [
    { index: 1, name: "#" },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Price") },
    { index: 4, name: t("Total Order") },
    { index: 5, name: t("Total Sales") },
  ];

  return (
    <>
      {/* blue: card box section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-s25 rounded-br20 w-full">
        <DashboardCard
          onClick={() => navigateTo("/customers")}
          number={totalCustomer ? totalCustomer : 0}
          text={t("Customers")}
        />

        <DashboardCard
          onClick={() => navigateTo("/shops")}
          number={totalShop ? totalShop : 0}
          text={t("Shops")}
        />
        <DashboardCard
          onClick={() => navigateTo("/category")}
          number={totalCategory ? totalCategory : 0}
          text={t("Categories")}
        />
        <DashboardCard
          onClick={() => navigateTo("/gift-cards")}
          number={totalCard ? totalCard : 0}
          text={t("Cards")}
        />
      </div>

      {/* green: recent orders */}
      <div className="mt-s20">
        <RecentOrderTable
          title={`${t("Recent Order")} - (${recentOrders?.length ?? 0})`}
          headers={recentOrderHeaders}
        />
      </div>

      {/* green: top selling table */}
      <div className="md:flex w-full md:space-x-3 mt-s20">
        <div className="w-full">
          <TopSellingTable
            title={t("Top Selling Card Of The Month")}
            headers={topSellingHeaders}
          />
        </div>

        {/* //! analysis has a  issue. need to fix asap */}
        {/* pink: new Chart js */}
        {/* <div className="p-5 w-s400 h-[440px] rounded-br10 shadow-sm bg-white relative">
          <span className="text-cMainBlack font-bold text-fs20">{t("Analytics")}</span>
          {analytics?.total_sale_order &&
            analytics?.total_pending_order &&
            analytics?.total_cancel_order ? <NewChart
            totalOrder={Math.round(analytics?.total_sale_order)}
            pendingOrder={Math.round(analytics?.total_pending_order)}
            cancelOrder={Math.round(analytics?.total_cancel_order)}
          /> : ""}

          {!analytics?.total_sale_order &&
            !analytics?.total_pending_order &&
            !analytics?.total_cancel_order ? <div className="absolute top-1/2 lef-1/2 translate-x-1/2 -translate-y-1/2 text-center">
            {t("No data Available !")}
          </div> : ""}
        </div> */}
      </div>
    </>
  );
};

export default Dashboard;
