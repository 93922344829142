import React from "react";
import { MuiCustomSwitch } from "../../App/Stores/UtilityStore";
import useVoucherStore, {
  getVoucherDetails,
  toggleVoucher,
} from "../../App/Stores/VoucherStore";
import { formatDate } from "../../App/Utility/UtilityFunctions";

export default function VoucherTableItem({ data, dataIndex, end }) {
  const {
    setShowDeactivateVoucher,
    setVoucherDeactivateID,
    setShowDetailsVoucher,
    voucherListPagination,
    setShowVoucherLimit
  } = useVoucherStore();
  return (
    <>


      <tr className="border-b hover:bg-cBandHover">
        <td
          onClick={async () => {
            await getVoucherDetails(data.id);
            setShowDetailsVoucher(true);
          }}
          className="border-r text-left capitalize cursor-pointer max-w-[200px] min-w-[250px] truncate pl-5"
        >
          {data?.title ? data?.title : "NA"}
        </td>

        <td
          onClick={async () => {
            await getVoucherDetails(data.id);
            setShowDetailsVoucher(true);
          }}
          className="border-r text-center capitalize cursor-pointer min-w-[150px]"
        >
          {data?.type ? data?.type : "NA"}
        </td>

        <td
          onClick={async () => {
            await getVoucherDetails(data.id);
            setShowDetailsVoucher(true);
          }}
          className="border-r text-center capitalize cursor-pointer max-w-[100px] truncate px-s10 min-w-[150px]"
        >
          {data?.voucher_code ? data?.voucher_code : "NA"}
        </td>
        <td
          onClick={async () => {
            // await getVoucherDetails(data.id);
            // setShowDetailsVoucher(true);
            setShowVoucherLimit(true);
          }}
          className="border-r text-center capitalize cursor-pointer min-w-[200px]"
        >
          {data?.limit ? (data?.amount+" out of " + data?.limit) : "0"}
        </td>
        <td
          onClick={async () => {
            await getVoucherDetails(data.id);
            setShowDetailsVoucher(true);
          }}
          className="border-r text-center capitalize cursor-pointer min-w-[150px]"
        >
          {data?.end_date ? formatDate(data?.end_date) : "NA"}
        </td>
        <td
          onClick={async () => {
            await getVoucherDetails(data.id);
            setShowDetailsVoucher(true);
          }}
          className="border-r text-center capitalize cursor-pointer min-w-[150px]"
        >
          {data?.amount ? data?.amount : "0"}
        </td>
        <td className="px-6 py-s10 min-w-[100px]">
          <tr className="flex space-x-3 items-center justify-center ">
            <td className="cursor-pointer">
              <MuiCustomSwitch
                color="secondary"
                size="small"
                checked={data?.is_active === 1 ? true : false}
                onClick={() => {
                  if (data?.is_active) {
                    setShowDeactivateVoucher(true);
                    setVoucherDeactivateID({
                      id: data.id,
                      is_active: 0,
                    });
                  } else {
                    setVoucherDeactivateID({
                      id: data.id,
                      is_active: 1,
                    });

                    let loadUrl =
                      voucherListPagination.path +
                      "?page=" +
                      voucherListPagination.current_page;

                    toggleVoucher(loadUrl);
                  }
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </td>
          </tr>
        </td>
      </tr>
    </>
  );
}
