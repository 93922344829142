import React from "react";
import { useTranslation } from "react-i18next";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useSettingsStore from "../../App/Stores/SettingsStore";
import UseTestStore from "../../App/Stores/TestStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import { BaseUrlSrc } from "../../App/Utility/Url";
import ProfileImage from "../../Images/dummy-img/alpaca.jpg";
import EditAccount from './Popup/EditAccount';

export default function AccountSettingsDropDown() {
  const { t } = useTranslation();

  const { loggedUser } = useUtilityStore();
  const { setShowTestModal, setShowEditPassModal } = UseTestStore();
  const { setShowLogoutModal } = useLayoutStore();
  const { setUserData, userData } = useSettingsStore();

  const fillAuthUserData = () => {

    let tempName = {
      target: {
        name: "name",
        value: loggedUser?.name,
      }
    }
    setUserData(tempName)

    let tempEmail = {
      target: {
        name: "email",
        value: loggedUser?.email,
      }
    }
    setUserData(tempEmail)

    let tempPhone = {
      target: {
        name: "phone",
        value: loggedUser?.phone,
      }
    }
    setUserData(tempPhone)

    let tempImage = {
      target: {
        name: "image_url",
        value: loggedUser?.image_url,
      }
    }
    setUserData(tempImage)

    console.log(userData);
  }

  console.log(loggedUser);
  return (
    <>
      <EditAccount />
      <div className="w-full h-full bg-white text-fs16 font-fw500 rounded-br5">
        <div className="flex items-center cursor-pointer p-s16">
          <img
            src={loggedUser?.image_url ? BaseUrlSrc + "/" + loggedUser?.image_url : ProfileImage}
            alt=""
            className="w-s35 h-s35 rounded-full object-cover"
          />

          <div className="flex flex-col justify-center pl-s10">
            <div>{loggedUser.name ? loggedUser.name : ""}</div>
            <div>
              {loggedUser.email ? loggedUser.email : ""}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-cPlaceholder"></div>
        <div className="pl-5 py-s16 cursor-pointer" onClick={() => { fillAuthUserData(); setShowTestModal(true); }} > {t("Edit Profile")} </div>
        <div className="w-full h-[1px] bg-cPlaceholder"></div>
        <div className="pl-5 py-s16 cursor-pointer" onClick={() => setShowEditPassModal(true)} >{t("Change Password")}</div>        
        <div className="w-full h-[1px] bg-cPlaceholder"></div>
        <div onClick={() => setShowLogoutModal(true)} className="pl-5 py-s16 cursor-pointer">{t("Logout")}</div>
      </div>
    </>

  );
}
