import React from 'react'
import { ImageUpload } from '../Utility/ImageImports'

export default function UploadBtn({
    btnLabel = "Trek",
    isDisabled = false,
    CustomStyles,
    onClick,
    type = "button"
}) {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={isDisabled}
            className={`
                ${CustomStyles}
                bg-white hover:bg-cBrand duration-300 ring-[1px] ring-cBrand rounded-br5 px-s10 py-s5 text-fs16 text text-cBrand hover:text-white font-fw500 capitalize flex items-center
            `}
        >
            <span>
                {btnLabel}
            </span>
            <span>
                <img src={ImageUpload} alt='' className='ml-s10 h-s16 w-s16' />
            </span>
        </button>
    )
}
