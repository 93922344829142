import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { getCardOrderList } from "../../App/Stores/GiftCardStore";
import useOrderStore, { updateOrderAction } from "../../App/Stores/OrderStore";
import { formatDate, Toastr } from "../../App/Utility/UtilityFunctions";
import GroupAccordion from "../../Components/Accordion/GroupAccordion";
import CommonButton from "../../Components/Button/CommonButton";
import DropDownBtn from "../../Components/Button/DropDownBtn";
import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import Image from "../../Components/Image/Image";
import CommonModal from "../../Components/Modal/CommonModal";
import { CopyToClipboard, DownloadIcon, NoImageLogo, PrintIcon } from "../../Components/Utility/ImageImports";
import InvoiceReceipt from "./InvoiceReceipt";

// payment_due ? don't show anything
// pending => (processing, cancelled, rejected)
// process => (completed, cancelled, rejected)
// cancelled => No action
// rejected => No action
// completed => No action

const OrderDetails = () => {
  const { t } = useTranslation();

  const { showOrderDetails, setShowOrderDetails, orderDetails, orderListPagination, setOrderStatusTable, setShowOrderDownloadUI } = useOrderStore();
  const [orderStatus, setOrderStatus] = useState("");

  let pendingArray = [
    "processing",
    "canceled",
    "rejected",
  ];

  let processingArray = [
    "completed",
    "canceled",
    "rejected",
  ];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `@media print {
      @page {
        size: size: A4 portrait;
        margin: 0;
      }
    }`,
  });

  useEffect(() => {
    if (orderDetails?.status) {
      setOrderStatus(orderDetails?.status)
    }
  }, [orderDetails, orderListPagination]);
  return (
    <>
      <CommonModal
        showModal={showOrderDetails}
        setShowModal={setShowOrderDetails}
        modalTitle={
          <div className="flex justify-between items-center w-full">
            <div className="flex space-x-s10">
              <img
                className="cursor-pointer"
                onClick={() => {
                  handlePrint();
                }}
                src={PrintIcon}
                alt=""
              />
              <img className="cursor-pointer" onClick={() => { setShowOrderDownloadUI(true) }} src={DownloadIcon} alt="" />
            </div>
            <div>{orderDetails?.code}  {t("Details")}</div>
            <div></div>
          </div>

        }
        // widthClass="w-[80vw] sm:w-[70vw] md:w-[60vw] lg:w-[55vw] xl:w-[50vw] 2xl:w-[45vw]"
        mainContent={
          <div
            // ref={componentRef}
            onClick={() => console.log("orderDetails:::", orderDetails)}
            className="pt-5">

            <div className="hidden">
              <InvoiceReceipt
                refToPrint={componentRef}

                invoice_id={orderDetails?.code ?? "NA"}
                order_id={orderDetails?.code ?? "NA"}
                purchase_id={orderDetails?.code ?? "NA"}

                date={orderDetails?.order_date ? formatDate(orderDetails?.order_date) : "NA"}

                shop_image={orderDetails?.shop_image_base64 ?? "NA"}
                shop_name={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                phone={orderDetails?.post_card_with_shop?.shop?.phone ?? "NA"}
                address={orderDetails?.post_card_with_shop?.shop?.address ?? "NA"}

                from_name={orderDetails?.customer?.name ?? "NA"}
                from_email={orderDetails?.customer?.email ?? "NA"}
                from_address={orderDetails?.customer?.address ?? "NA"}
                from_phone={orderDetails?.customer?.phone ?? "NA"}

                to_name={orderDetails?.ship_name ?? "NA"}
                to_email={orderDetails?.ship_address_type ?? "NA"}
                to_address={orderDetails?.ship_address ?? "NA"}
                to_phone={orderDetails?.ship_phone ?? "NA"}


                order_item_name={orderDetails?.post_card_with_shop?.name ?? "NA"}
                order_shop={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                order_qty={orderDetails?.quantity ?? "0"}
                order_price={orderDetails?.price ?? "0"}
                order_price_total={
                  orderDetails?.quantity * orderDetails?.price > 0 ? orderDetails?.quantity * orderDetails?.price : "0"
                }

                order_price_auth_fee={orderDetails?.authority_fee ?? "0"}
                order_price_discount={orderDetails?.discount ?? "0"}
                order_price_shipping={orderDetails?.shipping_fee ?? "0"}
                order_grand_total={orderDetails?.order_total ?? "0"}
              />
            </div>

            <GroupAccordion
              isInitOpen={true}
              header="Image"
              body={
                <Image
                  src={orderDetails?.text_image}
                  className="h-[350px] my-s10 rounded-br10 mx-auto"
                  dummyImage={NoImageLogo}
                />
              }
            />

            <div className="pt-5"></div>

            <GroupAccordion
              header="Additional Texts"
              body={
                <div className="relative">
                  {
                    orderDetails?.message ? <div className="mr-s25 py-s10 min-h-[100px]">{orderDetails?.message}</div>
                      : <div className="font-semibold text-cInputBorder py-s10">No data available</div>
                  }
                  {orderDetails?.message ?
                    <div
                      onClick={async () => {
                        await navigator.clipboard.writeText(orderDetails?.message);
                        Toastr({ message: "Text copied to clipboard !", type: "success" });
                      }}
                      className="absolute bg-white rounded-full shadow-lg cursor-pointer -right-s10 top-s10 p-s10"
                    >
                      <img src={CopyToClipboard} alt="" />
                    </div> : ""}
                </div>
              }
            />

            <div className="pt-5"></div>


            <DeliveryDetails
              orderDetails={orderDetails}
              setOrderStatus={setOrderStatus}
              orderStatus={orderStatus}
              processingArray={processingArray}
              pendingArray={pendingArray}
            />

            <ShopDetails
              orderDetails={orderDetails}
            />

            <SummeryOrder
              orderDetails={orderDetails}
            />

            {orderDetails?.status === "pending" || orderDetails?.status === "processing" ?
              <div className="flex justify-center pt-5 mt-s5">
                <CommonButton btnLabel={t("Confirm")} onClick={async () => {
                  let updateSuccess = await updateOrderAction(orderDetails?.id, orderStatus, orderListPagination.path + "?page=" + orderListPagination.current_page);
                  // console.log("SUCCESS !!!", updateSuccess);
                  if (updateSuccess === true) {
                    setShowOrderDetails(false)
                    setOrderStatusTable(orderStatus);
                    if (window.location.pathname === "/gift-card-details") {
                      getCardOrderList("all orders", "", "");
                    }
                  }
                }} />
              </div> : ""
            }
          </div>
        }
      />
    </>
  );
};

export default OrderDetails;

const DeliveryDetails = ({ orderDetails, setOrderStatus, orderStatus, pendingArray, processingArray }) => {
  const { t } = useTranslation();

  return (
    <div >
      <GroupAccordion
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Delivery Details")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Receiver Name"), orderDetails?.customer?.name)}
            {dataRow(t("Phone"), orderDetails?.customer?.phone ?? "NA")}
            {dataRow(t("Address"), orderDetails?.customer?.address ?? "NA")}
            {dataRow(t("Post Code"), orderDetails?.customer?.post_code ?? "NA")}
            {dataRow(t("City"), orderDetails?.customer?.city ?? "NA")}
            <div className="pb-s10"></div>
          </>
        }
        footer={
          <>
            <div className="px-5">

              {orderDetails?.status === "pending" || orderDetails?.status === "processing" ?
                <>
                  {dataRow(t("Delivery Status"), <CommonDropDown
                    toggleButton={
                      <>
                        <DropDownBtn
                          fullRounded={true}
                          basicColor={false}
                          successColor={orderStatus === "completed" ? true : false}
                          dangerColor={orderStatus === "canceled" || orderStatus === "rejected" ? true : false}
                          pendingColor={orderStatus === "pending" || orderStatus === "payment_due" || orderStatus === "processing" ? true : false}
                          btnLabel={orderStatus ? orderStatus === "payment_due" ? "payment due" : orderStatus : orderDetails?.status === "payment_due" ? "payment due" : orderDetails?.status}
                        />
                      </>
                    }
                    data={orderDetails?.status === "pending" ? pendingArray : orderDetails?.status === "processing" ? processingArray : []}
                    type={2}
                    itemOnClick={(e) => {
                      if (e === "payment due") {
                        setOrderStatus("payment_due");
                      } else {
                        setOrderStatus(e);
                      }
                    }}
                  />)}
                </>
                :
                <>
                  {dataRow(t("Delivery Status"), orderDetails?.status === "payment_due" ? "payment due" : orderDetails?.status)}
                </>
              }

            </div>
            <div className="pb-s10"></div>
          </>
        }
      />
    </div>
  );

  function dataRow(dataName, dataDetails) {
    return (
      <div className="flex justify-between items-center capitalize pt-s10">
        <div className="font-medium">{dataName}</div>
        <div>{dataDetails}</div>
      </div>
    );
  }
};

const ShopDetails = ({ orderDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-5">
      <GroupAccordion
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Shop Details")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Shop Name"), orderDetails?.post_card_with_shop?.shop?.name ?? "NA")}
            {dataRow(t("Phone"), orderDetails?.post_card_with_shop?.shop?.phone ?? "NA")}
            {dataRow(t("Address"), orderDetails?.post_card_with_shop?.shop?.address ?? "NA")}
            {dataRow(t("Post Code"), orderDetails?.post_card_with_shop?.shop?.post_code ?? "NA")}
            {dataRow(t("City"), orderDetails?.post_card_with_shop?.shop?.city ?? "NA")}
            <div className="pb-s10"></div>
          </>
        }
      />
    </div>
  );

  function dataRow(dataName, dataDetails) {
    return (
      <div className="flex justify-between items-center capitalize pt-s15">
        <div className="font-medium">{dataName}</div>
        <div>{dataDetails}</div>
      </div>
    );
  }
};

const SummeryOrder = ({ orderDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-5">

      <GroupAccordion
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Summery")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Quantity"), orderDetails?.quantity, false)}
            {dataRow(t("Sub Total"), orderDetails?.price)}
            {dataRow(t("Authority fee"), orderDetails?.authority_fee)}
            {dataRow(t("Shipping fee"), orderDetails?.shipping_fee)}
            <div className="py-s10"></div>
          </>
        }
        footer={
          <>
            <div className="px-5">
              {dataRow(t("Total"), orderDetails?.order_total)}
            </div>
            <div className="pb-s10"></div>
          </>
        }
      />
    </div>
  );

  function dataRow(dataName, dataDetails, showPriceIcon = true) {
    return (
      <div className="flex justify-between items-center capitalize pt-s10">
        <div className="font-medium">{dataName}</div>
        {dataDetails ? <div>{showPriceIcon ? "¥" : ""} {dataDetails}</div> : <div>Free</div>}
      </div>
    );
  }
};
