import create from "zustand";

const useRoleStore = create((set) => ({
  RoleDummyData: [
    {
      name: "Fabio Wibmer",
      access: "customers, shops, dashboard",
    },
    {
      name: "Adam W",
      access: "customers, shops, dashboard",
    },
    {
      name: "Macanzy Foe",
      access: "customers, shops, dashboard",
    },
    {
      name: "Peter Walls",
      access: "customers, shops, dashboard",
    },
    {
      name: "George Floyed",
      access: "customers, shops, dashboard",
    },
  ],

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  showAddRole: false,
  setShowAddRole: (value) => set({ showAddRole: value }),

  showAddAdmin: false,
  setShowAddAdmin: (value) => set({ showAddAdmin: value }),

  showDeleteRole: false,
  setShowDeleteRole: (value) => set({ showDeleteRole: value }),

  // searchKey: "",
  // setSearchKey: (value) => set({ searchKey: value }),
}));

export default useRoleStore;
