/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import { Outlet } from "react-router-dom";
import { getAllCategory } from "../../App/Stores/CategoryStore";
import { useTranslation } from "react-i18next";

const Category = () => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    setBarTitle(t("Category"));
    PageTitle(t("Category"));
  }, []);

  useEffect(() => {
    getAllCategory()
  }, []);

  return (
    <div className="overflow-y-auto">
      {/* green: outputs from nested route*/}
      <Outlet />
    </div>
  );
};

export default Category;
