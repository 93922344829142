import React from "react";

export default function DashboardCard({ number, text, onClick, className }) {
  return (
    <>
      <div
        className={`dashboard-box-shadow text-cBrand rounded-br15 w-full h-w135 hover:bg-cBrand hover:text-cWhite transition duration-700 ease-in-out cursor-pointer ${className}`}
        onClick={onClick}
      >
        <div className="flex items-center justify-center h-full p-s30">
          <div className="text-center">
            <h1 className="text-fs48 font-fw600">{number}</h1>
            <p className="text-fs24 font-fw600">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
}
